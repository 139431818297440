<template>
  <div class="feedback-etrance" @click="feedback">
    <span>{{ title }}</span>
  </div>
</template>
<script>
import {
  combineUrl,
  getSearch
} from '@/utils/index'
import { feedbackUrl } from '@/config'
export default {
  props: {
    sceneId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      default: '问题反馈'
    }
  },
  methods: {
    feedback () {
      this.$log.click({
        module_part: `106_tf_H5_GJ_006_${getSearch('id')}`,
        module_ori: '落地页工具落地页首页',
        alternate: getSearch()
      })
      const url = new URL(feedbackUrl)
      const target = window.location.href
      location.href = combineUrl(url.toString(), target)
    }
  }
}
</script>
<style lang="scss" scoped>
.feedback-etrance {
  width: 44px;
  height: 128px;
  background: #000;
  border-radius: 20px 0 0 20px;
  backdrop-filter: blur(10px);
  color: #fff;
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    word-wrap: break-word;
    font-size: 20px;
    width: 20px;
    display: block;
    line-height: 24px;
  }

  opacity: 0.4;
}
</style>
