var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VanDialog',{staticClass:"detentionDialog",style:({
      backgroundImage:'url(' + _vm.comDetentionBg + ')',
      backgroundSize: 'contain',
      height: _vm.detent_bg.height / 75 + 'rem',
      width: _vm.detent_bg.width / 75 + 'rem',
      backgroundColor: _vm.detent_bg.bg_color,
      borderTopLeftRadius: _vm.detent_bg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: _vm.detent_bg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: _vm.detent_bg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: _vm.detent_bg.radius_visibility.bottom_right / 75 + 'rem'
    }),attrs:{"show-confirm-button":false},model:{value:(_vm.value.showDialog),callback:function ($$v) {_vm.$set(_vm.value, "showDialog", $$v)},expression:"value.showDialog"}},[_c('div',{staticClass:"detentionDialog-title",style:({
        height: _vm.title.height / 75 + 'rem',
        textAlign: _vm.title.text_align,
        top: _vm.title.top / 75 + 'rem',
        color: _vm.title.color,
        fontSize: _vm.title.font_size / 75 + 'rem',
        fontWeight: _vm.comTitleBold,
        fontStyle: _vm.comTitleItalic,
        textDecoration: _vm.comTitleDecoration,
      })},[_vm._v(_vm._s(_vm.detention_copy))]),(_vm.centerCouponInfo && _vm.centerCouponInfo.rule)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.centerCouponInfo.rule.discount)+" "),_c('div',{staticClass:"yuan"})]):_vm._e(),_c('div',{staticClass:"detentionDialog-btns",style:({
        top: _vm.abandon.top / 75 + 'rem',
        height: _vm.confirm.height / 75 + 'rem'
      })},[_c('div',{staticClass:"detentionDialog-btns-cancel",style:({
          height: _vm.abandon.height / 75 + 'rem',
          width: _vm.abandon.width / 75 + 'rem',
          lineHeight: _vm.abandon.height / 75 + 'rem',
          backgroundColor: _vm.abandon.bg_color,
          color: _vm.abandon.input_text.color,
          fontSize: _vm.abandon.input_text.font_size / 75 + 'rem',
          borderTopLeftRadius: _vm.abandon.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.abandon.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.abandon.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.abandon.radius_visibility.bottom_right / 75 + 'rem',
          backgroundImage:'url(' + _vm.comAbandonUrl + ')',
          left: _vm.comAnandonLeft + 'rem',
          fontWeight: _vm.comAbandonBold,
          fontStyle: _vm.comAbandonItalic,
          textDecoration: _vm.comAbandonDecoration,
        }),on:{"click":_vm.cancelDialog}},[_vm._v(_vm._s(_vm.abandon_btn_copy))]),_c('div',{staticClass:"detentionDialog-btns-confirm",style:({
          height: _vm.confirm.height / 75 + 'rem',
          width: _vm.confirm.width / 75 + 'rem',
          lineHeight: _vm.abandon.height / 75 + 'rem',
          backgroundColor: _vm.confirm.bg_color,
          color: _vm.confirm.input_text.color,
          fontSize: _vm.confirm.input_text.font_size / 75 + 'rem',
          borderTopLeftRadius: _vm.confirm.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.confirm.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.confirm.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.confirm.radius_visibility.bottom_right / 75 + 'rem',
          backgroundImage:'url(' + _vm.comConfirmUrl + ')',
          fontWeight: _vm.comConfirmBold,
          fontStyle: _vm.comConfirmItalic,
          textDecoration: _vm.comConfirmDecoration,
          left: _vm.comConfirmLeft + 'rem'
        }),on:{"click":_vm.detentionPay}},[_vm._v(_vm._s(_vm.pay_btn_copy))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }