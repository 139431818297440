import axios from 'axios'
import { Toast } from 'vant'
import { isWeiXin, getQuery } from '@/utils'
import { SUCCESS_CODE, UNAUTHORIZED_CODE } from './code'

const service = axios.create({
  baseURL: '/api',
  timeout: 10000,
  withCredentials: true
})

isWeiXin() && service.interceptors.request.use(config => {
  config.headers.Authorization = localStorage.getItem('Authorization')
  config.headers.xkToken = localStorage.getItem('Authorization')?.substring(7)
  config.headers.fissionAppId = getQuery('appid')
  return config
})

service.interceptors.response.use(response => {
  const { data: { code, data, msg } } = response
  // 成功
  if (SUCCESS_CODE.includes(code)) {
    return data
  }

  if (UNAUTHORIZED_CODE.includes(response.data.code)) {
    // 未授权、跳转登录
    const { url } = data
    // 记录之前获取用户信息跳转过
    localStorage.setItem('authing', true)
    window.location.href = url + encodeURIComponent(location.href)
  }
  if (code === 30006 && data.group) {
    // 微信外已经报名  存储分流信息 guide_phone
    const phone = localStorage.getItem('phone')
    localStorage.setItem('guide_phone', phone)
    localStorage.setItem('pageId', data.group)
    localStorage.setItem('uniqFlag', data.uniqFlag)
  }
  if ([33001, -1, 36003, 36004].includes(code) && msg !== 'miss appid or path') Toast(msg)
  return Promise.reject(response)
}, error => {
  console.log(error)
  // Toast('请求失败', error)
  return Promise.reject(error)
})

export default service
