const WeiMiaoJSBridge = {
  events: {},
  emit (name = '', params = {}) {
    if (window.WeiMiao) {
      window.WeiMiao.postMessage(JSON.stringify({
        name: name,
        params: params
      }))
    }
  },
  on (event, handler) {
    this.events[event] = handler
  },
  callEvent (event, params) {
    this.events[event] && this.events[event](params)
  }
}

window.WeiMiaoJSBridge = WeiMiaoJSBridge
export default WeiMiaoJSBridge
