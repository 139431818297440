<template>
  <div
    class="easy-im"
    v-if="is_show"
    :style="{
      width: pay_img_btn.width / 75 + 'rem',
      height: pay_img_btn.height / 75 + 'rem'
    }">
    <div
      id="easyImBtn"
      class="easy-im-paybtn"
      :style="{
        borderTopLeftRadius: pay_img_btn.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: pay_img_btn.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: pay_img_btn.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: pay_img_btn.radius_visibility.bottom_right / 75 + 'rem',
        backgroundImage:'url(' + comBottomBtnUrl + ')',
        left: comSubmitLeft + 'rem',
        top: pay_img_btn.top / 75 + 'rem',
        right: 0 - pay_img_btn.x / 75 + 'rem',
        backgroundColor: pay_img_btn.bg_color,
        height: pay_img_btn.height / 75 + 'rem',
        lineHeight: pay_img_btn.height / 75+ 'rem',
        width: pay_img_btn.width / 75 + 'rem',
        fontSize: pay_img_btn.input_text.font_size / 75 + 'rem',
        color: pay_img_btn.input_text.color,
        fontWeight: comBottomSubmitBold,
        fontStyle: comBottomSubmitItalic,
        textDecoration: comBottomSubmitDecoration,
      }"
      v-debounce="1500"
      onclick="report">
      {{ pay_img_btn.input_text.text }}
    </div>
  </div>
</template>
<script>
import { getSearch } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'EasyIm',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      is_show: false,
      loaded: false,
      clickFn: null,
      ...(this.value.easy_im || {})
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    // 计算抢购按钮距左侧距离
    comSubmitLeft () {
      switch (this.pay_img_btn.text_align) {
        case 'left':
          return 0
        case 'center':
          return (750 - this.pay_img_btn.width) / 2 / 75
        case 'right':
          return (750 - this.pay_img_btn.width) / 75
        default:
          return this.pay_img_btn.x / 75
      }
    },
    // 计算提交按钮下划线
    comBottomSubmitDecoration () {
      if (this.pay_img_btn.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.pay_img_btn.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算底部提交按钮斜体
    comBottomSubmitItalic () {
      return this.pay_img_btn.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算底部提交按钮加粗
    comBottomSubmitBold () {
      return this.pay_img_btn.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算底部固定区域btn-url
    comBottomBtnUrl () {
      const imgList = this.pay_img_btn.img_list
      return imgList.length ? imgList[0].url : ''
    },
    // 计算底部固定区域url
    comBottomBgUrl () {
      return this.img_bg.length ? this.img_bg[0].url : ''
    }
  },
  mounted () {
    if (this.code?.bodyCode) {
      this.insertSdk()
    }
    if (this.code?.clickCode) {
      this.insertATag()
    }
  },
  methods: {
    // 插入易聊 sdk
    insertSdk () {
      const sdkStr = decodeURIComponent(this.code.bodyCode)
      const regex = /<script[^>]*src=['"]([^'"]+)['"][^>]*>/gi
      const matches = sdkStr.matchAll(regex)
      for (const match of matches) {
        const srcValue = match[1]
        const head = document.querySelector('head')
        const script = document.createElement('script')
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('charset', 'UTF-8')
        script.setAttribute('src', srcValue)

        script.onload = () => {
          this.loaded = true
        }

        head.appendChild(script)
      }
    },
    // 插入易聊打开聊天框事件
    insertATag () {
      const htmlString = this.code.clickCode
      const parentId = 'easyImBtn'

      // 创建一个临时容器元素
      const tempContainer = document.createElement('div')
      tempContainer.innerHTML = htmlString

      // 获取临时容器中的 <a> 元素
      const linkElement = tempContainer.querySelector('a')

      // 给 a 标签添加 click 事件
      linkElement.addEventListener('click', event => {
        this.report()
        event.preventDefault() // 阻止默认的链接跳转行为
      })

      // 添加样式
      const styles = {
        position: 'absolute',
        zIndex: 10,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
      // 应用样式
      Object.assign(linkElement.style, styles)

      // 插入到指定节点下
      const parentElement = document.getElementById(parentId)
      parentElement && parentElement.appendChild(linkElement)
    },
    report () {
      this.$log.click({
        current_page: location.href,
        module_ori: '点击【跳转易聊】按钮',
        module_part: `106_tf_h5_00250_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.easy-im {
  position: fixed;
  bottom: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: top;
  z-index: 99;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  box-sizing: content-box;

  &-paybtn {
    position: absolute;
    top: 0;
    width: 435px;
    height: 166px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: content-box;
  }

  &-a {
    position: absolute;
    z-index: 10;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

</style>
