// iphone 手机分辨率
export const IPHONE_DEVICES = [
  ['640x960', ['iPhone 4/4s']],
  ['640x1136', ['iPhone 5/5c/5s/SE']],
  ['750x1334', ['iPhone 6/7/8']],
  ['1242x2208', ['iPhone 6/7/8 Plus']],
  ['1125x2436', ['iPhone X/XS/11 Pro']],
  ['828x1792', ['iPhone Xr/11']],
  ['1242x2688', ['iPhone XS/11 Pro Max']],
  ['1080x2340', ['iPhone 12/13 mini']],
  ['1170x2532', ['iPhone 12/13/14']],
  ['1284x2778', ['iPhone 12/13 Pro Max']],
  ['1179x2556', ['iPhone 14/15 Pro']],
  ['1290x2796', ['iPhone 14/15 Pro Max']]
]
