// libs/log.js
import WMStatistics from '@wm/wm-burying-point'

const options = {
  env: process.env.VUE_ENV === 'prod' ? 'production' : 'development',
  mode: 'img'
}
const logs = new WMStatistics('toufang', options)

export const registerLog = (Vue, Store) => {
  // Vue.prototype.$log = logs
  Vue.use(logs) // v1.4.3版本后推荐使用，会自动注册上报自定义指令
  Store.$log = logs
}

export default logs
