const commonComs = [
  {
    component_mark: 'CountDown',
    component_name: 'CountDown',
    name: '倒计时'
  },
  {
    component_mark: 'RecentBuy',
    component_name: 'RecentBuy',
    name: '最近购买'
  },
  {
    component_mark: 'BottomFixed',
    component_name: 'BottomFixed',
    name: '底部悬浮栏'
  },
  {
    component_mark: 'DetentionDialog',
    component_name: 'DetentionDialog',
    name: '挽留弹窗'
  },
  {
    component_mark: 'ConcealGuardDialog',
    component_name: 'ConcealGuardDialog',
    name: '隐私协议'
  },
  {
    component_mark: 'JumptoMiniapp',
    component_name: 'JumptoMiniapp',
    name: '跳转小程序'
  },
  {
    component_mark: 'EasyIm',
    component_name: 'EasyIm',
    name: '跳转易聊'
  }
]

// 路径配置
// path.path_name： 路径名称
// path.components index页面组件列表
const path = [
  {
    path_name: '路径一',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径二',
    components: [
      ...commonComs,
      {
        component_mark: 'FormPop',
        component_name: 'FormPop',
        name: '表单弹窗'
      }
    ]
  },
  {
    path_name: '路径三',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径四',
    components: [
      ...commonComs,
      {
        component_mark: 'FormPop',
        component_name: 'FormPop',
        name: '表单弹窗'
      }
    ]
  },
  {},
  {
    path_name: '路径六',
    components: [
      ...commonComs,
      {
        component_mark: 'DetentionDialogAgain',
        component_name: 'DetentionDialogAgain',
        name: '二次挽留弹窗'
      },
      {
        component_mark: 'FormPopNew',
        component_name: 'FormPopNew',
        name: '新表单弹窗'
      },
      {
        component_mark: 'RedEnvelope',
        component_name: 'RedEnvelope',
        name: '开屏红包'
      }
    ]
  },
  {
    path_name: '路径七',
    components: [
      ...commonComs
    ]
  },
  {},
  {},
  {},
  {},
  {
    path_name: '路径十二',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径十三',
    components: [
      ...commonComs,
      {
        component_mark: 'Address',
        component_name: 'Address',
        name: '收货地址'
      }
    ]
  },
  {
    path_name: '路径十四',
    components: [
      ...commonComs,
      {
        component_mark: 'Address',
        component_name: 'Address',
        name: '收货地址'
      }
    ]
  },
  {
    path_name: '路径十五',
    components: [
      ...commonComs,
      {
        component_mark: 'Address',
        component_name: 'Address',
        name: '收货地址'
      }
    ]
  },
  {
    path_name: '路径十六',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径十七',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径十八',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径十九',
    components: [
      ...commonComs
    ]
  },
  {
    path_name: '路径二十',
    components: [
      ...commonComs
    ]
  }
]
export { path }
