var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VanPopup',{staticClass:"form-pop",style:({
    width: _vm.setFormBg.width / 75 + 'rem',
    backgroundImage: 'url(' + _vm.comFormBgUrl + ')',
    height: _vm.setFormBg.height / 75 + 'rem',
    backgroundColor: _vm.setFormBg.bg_color,
    borderTopLeftRadius: _vm.setFormBg.radius_visibility.top_left / 75 + 'rem',
    borderTopRightRadius: _vm.setFormBg.radius_visibility.top_right / 75 + 'rem',
    borderBottomLeftRadius: _vm.setFormBg.radius_visibility.bottom_left / 75 + 'rem',
    borderBottomRightRadius: _vm.setFormBg.radius_visibility.bottom_right / 75 + 'rem',
    backgroundRepeat: 'repeat',
    backgroundSize: 'contain',
    backgroundColor: '#FFFFFF',
    overflow: 'hidden'
  }),attrs:{"position":"bottom","closeable":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"address-box"},[_c('div',{staticClass:"title"},[_vm._v("收货地址")]),_c('div',{staticClass:"address-list"},[_c('div',{staticClass:"list-name",style:({
          lineHeight: _vm.userInput.height / 75 + 'rem',
          fontSize: _vm.userInput.input_text.font_size / 75 + 'rem',
        })},[_vm._v("收货人")]),_c('div',{staticClass:"list-inp",style:({
          width: _vm.userInput.width / 75 + 'rem',
          height: _vm.userInput.height / 75 + 'rem',
          lineHeight: _vm.userInput.height / 75 + 'rem',
          backgroundColor: _vm.userInput.bg_color,
          fontSize: _vm.userInput.input_text.font_size / 75 + 'rem',
          color: _vm.userInput.input_text.color,
          left: _vm.comFormFLeft('user_input') + 'rem',
          top: _vm.userInput.top / 75 + 'rem',
          backgroundImage:'url(' + _vm.userInputBgUrl + ')',
          borderTopLeftRadius: _vm.userInput.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.userInput.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.userInput.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.userInput.radius_visibility.bottom_right / 75 + 'rem'
        })},[_c('VanField',{style:({
            fontSize: _vm.userInput.input_text.font_size / 75 + 'rem',
            color: _vm.userInput.input_text.color,
          }),attrs:{"maxlength":"20","placeholder":_vm.userInput.input_text.text},model:{value:(_vm.address_info.user_name),callback:function ($$v) {_vm.$set(_vm.address_info, "user_name", $$v)},expression:"address_info.user_name"}})],1)]),_c('div',{staticClass:"address-list"},[_c('div',{staticClass:"list-name",style:({
          lineHeight: _vm.phoneInput.height / 75 + 'rem',
          fontSize: _vm.phoneInput.input_text.font_size / 75 + 'rem',
        })},[_vm._v("手机号码")]),_c('div',{staticClass:"list-inp",style:({
          width: _vm.phoneInput.width / 75 + 'rem',
          height: _vm.phoneInput.height / 75 + 'rem',
          lineHeight: _vm.phoneInput.height / 75 + 'rem',
          backgroundColor: _vm.phoneInput.bg_color,
          fontSize: _vm.phoneInput.input_text.font_size / 75 + 'rem',
          color: _vm.phoneInput.input_text.color,
          left: _vm.comFormFLeft('phone_input') + 'rem',
          top: _vm.phoneInput.top / 75 + 'rem',
          backgroundImage:'url(' + _vm.phoneInputBgUrl + ')',
          borderTopLeftRadius: _vm.phoneInput.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.phoneInput.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.phoneInput.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.phoneInput.radius_visibility.bottom_right / 75 + 'rem'
        })},[_c('VanField',{style:({
            fontSize: _vm.phoneInput.input_text.font_size / 75 + 'rem',
            color: _vm.phoneInput.input_text.color,
          }),attrs:{"maxlength":"11","placeholder":_vm.phoneInput.input_text.text,"type":"tel"},model:{value:(_vm.address_info.mobile),callback:function ($$v) {_vm.$set(_vm.address_info, "mobile", $$v)},expression:"address_info.mobile"}})],1)]),_c('div',{staticClass:"address-list"},[_c('div',{staticClass:"list-name",style:({
          lineHeight: _vm.areaSelect.height / 75 + 'rem',
          fontSize: _vm.areaSelect.input_text.font_size / 75 + 'rem',
        })},[_vm._v("所在地区")]),_c('div',{staticClass:"list-inp sel",style:({
          width: _vm.areaSelect.width / 75 + 'rem',
          height: _vm.areaSelect.height / 75 + 'rem',
          lineHeight: _vm.areaSelect.height / 75 + 'rem',
          backgroundColor: _vm.areaSelect.bg_color,
          fontSize: _vm.areaSelect.input_text.font_size / 75 + 'rem',
          color: _vm.areaSelect.input_text.color,
          left: _vm.comFormFLeft('area_select') + 'rem',
          top: _vm.areaSelect.top / 75 + 'rem',
          backgroundImage:'url(' + _vm.areaSelectBgUrl + ')',
          borderTopLeftRadius: _vm.areaSelect.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.areaSelect.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.areaSelect.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.areaSelect.radius_visibility.bottom_right / 75 + 'rem'
        }),on:{"click":_vm.handelSelArea}},[(_vm.selAreaValue)?[_c('span',{staticClass:"sel-active",style:({
              fontSize: _vm.areaSelect.input_text.font_size / 75 + 'rem',
              color: _vm.areaSelect.input_text.color,
            })},[_vm._v(_vm._s(_vm.selAreaValue))])]:[_c('span',{staticClass:"sel-place"},[_vm._v(_vm._s(_vm.areaSelect.input_text.text))]),_c('span',{staticClass:"icon"},[_c('VanIcon',{attrs:{"name":"arrow"}})],1)]],2)]),_c('div',{staticClass:"address-list"},[_c('div',{staticClass:"list-name",style:({
          lineHeight: _vm.phoneInput.height / 75 + 'rem',
          fontSize: _vm.addressInput.input_text.font_size / 75 + 'rem',
        })},[_vm._v("详细地址")]),_c('div',{staticClass:"list-inp",style:({
          width: _vm.addressInput.width / 75 + 'rem',
          height: _vm.addressInput.height / 75 + 'rem',
          lineHeight: _vm.addressInput.height / 75 + 'rem',
          backgroundColor: _vm.addressInput.bg_color,
          fontSize: _vm.addressInput.input_text.font_size / 75 + 'rem',
          color: _vm.addressInput.input_text.color,
          left: _vm.comFormFLeft('address_input') + 'rem',
          top: _vm.addressInput.top / 75 + 'rem',
          backgroundImage:'url(' + _vm.addressInputBgUrl + ')',
          borderTopLeftRadius: _vm.addressInput.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.addressInput.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.addressInput.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.addressInput.radius_visibility.bottom_right / 75 + 'rem'
        })},[_c('VanField',{style:({
            fontSize: _vm.addressInput.input_text.font_size / 75 + 'rem',
            color: _vm.addressInput.input_text.color,
          }),attrs:{"maxlength":"100","rows":"1","type":"textarea","placeholder":_vm.addressInput.input_text.text},model:{value:(_vm.address_info.address),callback:function ($$v) {_vm.$set(_vm.address_info, "address", $$v)},expression:"address_info.address"}})],1),_c('div',{style:({
          width: _vm.addressInput.width / 75 + 'rem',
          height: _vm.addressInput.height / 75 + 'rem',
        })})]),_c('div',{staticClass:"shop-data"},[_c('div',{staticClass:"shop-list"},[_c('div',{staticClass:"shop-name"},[_vm._v("商品金额")]),_c('div',{staticClass:"shop-text"},[_vm._v("￥"+_vm._s(_vm.shopPrice))])]),_c('div',{staticClass:"shop-list"},[_c('div',{staticClass:"shop-name"},[_vm._v("运费")]),_c('div',{staticClass:"shop-text"},[_vm._v("免运费")])]),_c('div',{staticClass:"shop-list"},[_c('div',{staticClass:"shop-name"},[_vm._v("应付金额")]),_c('div',{staticClass:"shop-text"},[_vm._v("￥"+_vm._s(_vm.shopPrice))])])]),_c('div',{staticClass:"btn-box"},[_c('div',{staticClass:"btn",style:({
          left: _vm.comFormFLeft('submit') + 'rem',
          top: _vm.submit.top / 75 + 'rem',
          width: _vm.submit.width / 75 + 'rem',
          height: _vm.submit.height / 75 + 'rem',
          backgroundImage:'url(' + _vm.submitBgUrl + ')',
          backgroundColor: _vm.submit.bg_color,
          lineHeight: _vm.submit.height / 75 + 'rem',
          color: _vm.submit.input_text.color,
          fontSize: _vm.submit.input_text.font_size / 75 + 'rem',
          borderTopLeftRadius: _vm.submit.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.submit.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.submit.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.submit.radius_visibility.bottom_right / 75 + 'rem',
          fontWeight: _vm.submitBold,
          fontStyle: _vm.submitItalic,
          textDecoration: _vm.submitDecoration
        }),on:{"click":_vm.handleSaveCheck}},[_vm._v(_vm._s(_vm.submit.input_text.text))])])]),_c('AreaSelect',{ref:"areaRef",attrs:{"title":_vm.areaSelect.input_text.text},on:{"confirm":_vm.confirm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }