<template>
  <VanPopup
    v-model="isShow"
    v-bind="$attrs"
    v-on="$listeners"
    round
    :close-on-popstate="closeOnPopstate"
    :close-on-click-overlay="closeOnclickOverlay"
    :safe-area-inset-bottom="safeAreaInsetBottom"
    :position="position"
    @close="$emit('modalClose')">
    <slot></slot>
  </VanPopup>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    // 是否显示弹框
    show: {
      type: Boolean,
      default: false
    },
    // 弹框内是否可滚动，解决底层背景滑动问题
    isScroll: {
      type: Boolean,
      default: false
    },
    // 是否在页面回退时自动关闭
    closeOnPopstate: {
      type: Boolean,
      default: true
    },
    // 是否在点击遮罩层后关闭
    closeOnclickOverlay: {
      type: Boolean,
      default: false
    },
    // 是否开启底部安全区适配
    safeAreaInsetBottom: {
      type: Boolean,
      default: true
    },
    // 是否开启底部安全区适配
    position: {
      type: String,
      default: 'center'
    }
  },
  data () {
    return {
      isShow: false // 避免关闭时  直接修改警告
    }
  },
  watch: {
    /**
     * @description:禁止背景滑动
     */
    show (val) {
      this.isShow = val
      if (this.isScroll) return
      if (val) {
        this.removeTouch()
      } else {
        this.addTouch()
      }
    }
  },
  created () {
    this.isShow = this.show
  },
  beforeDestroy () {
    this.addTouch()
  },
  methods: {
    stopTouch (e) {
      e.preventDefault()
    },
    removeTouch () {
      document.body.addEventListener('touchmove', this.stopTouch, { passive: false, capture: true })
      document.body.style.userSelect = 'none'
    },
    addTouch () {
      document.body.removeEventListener('touchmove', this.stopTouch, { capture: true })
      document.body.style.userSelect = 'text'
    }
  }
}
</script>

<style lang="scss" scoped>

.van-popup {
  overflow: visible;
  background-color: transparent;
  user-select: text !important;
}
</style>
