var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.is_show)?_c('div',{staticClass:"landpage-bottom",style:({
    backgroundImage:'url(' + _vm.comBottomBgUrl + ')',
    borderTopLeftRadius: _vm.bottom_bg.radius_visibility.top_left / 75 + 'rem',
    borderTopRightRadius: _vm.bottom_bg.radius_visibility.top_right / 75 + 'rem',
    borderBottomLeftRadius: _vm.bottom_bg.radius_visibility.bottom_left / 75 + 'rem',
    borderBottomRightRadius: _vm.bottom_bg.radius_visibility.bottom_right / 75 + 'rem',
    height: _vm.bottom_bg.height / 75 + 'rem',
    width: _vm.bottom_bg.width / 75 + 'rem',
    backgroundColor: _vm.bottom_bg.bg_color
  })},[_c('div',{staticClass:"left",style:({
      fontSize: _vm.bottom_bg.input_text.font_size / 75 + 'rem',
      lineHeight: _vm.bottom_bg.height / 75 + 'rem',
      color: _vm.bottom_bg.input_text.color,
      fontWeight: _vm.comBottomBgBold,
      fontStyle: _vm.comBottomBgItalic,
      textDecoration: _vm.comBottomBgDecoration
    })},[_vm._v(_vm._s(_vm.bottom_bg.input_text.text))]),_c('div',{directives:[{name:"debounce",rawName:"v-debounce",value:(1500),expression:"1500"}],staticClass:"landpage-bottom-paybtn",style:({
      borderTopLeftRadius: _vm.pay_img_btn.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: _vm.pay_img_btn.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: _vm.pay_img_btn.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: _vm.pay_img_btn.radius_visibility.bottom_right / 75 + 'rem',
      backgroundImage:'url(' + _vm.comBottomBtnUrl + ')',
      left: _vm.comSubmitLeft + 'rem',
      top: _vm.pay_img_btn.top / 75 + 'rem',
      right: 0 - _vm.pay_img_btn.x / 75 + 'rem',
      backgroundColor: _vm.pay_img_btn.bg_color,
      height: _vm.pay_img_btn.height / 75 + 'rem',
      lineHeight: _vm.pay_img_btn.height / 75+ 'rem',
      width: _vm.pay_img_btn.width / 75 + 'rem',
      fontSize: _vm.pay_img_btn.input_text.font_size / 75 + 'rem',
      color: _vm.pay_img_btn.input_text.color,
      fontWeight: _vm.comBottomSubmitBold,
      fontStyle: _vm.comBottomSubmitItalic,
      textDecoration: _vm.comBottomSubmitDecoration,
    }),on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.pay_img_btn.input_text.text))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }