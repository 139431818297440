
import { isInViewPort, getSearch } from '@/utils'
let MAX_COUNT = 60
let timer
const LANDING_PAGE_ID_LIST = [
  '697',
  '1305',
  '1603',
  '1596',
  '1574',
  '1573',
  '1316'
]
// 视口高度
const CLIENHEIGHT = document.documentElement.clientHeight
// 素材占有屏幕高度的比例
// function visibleHeightPercent (item, scrollTop) {
//   // 1. 判断是否在可视区域
//   // 2. item.top < scrooltop  bottom - scrolltop
//   // 3. item.top >= scrooltop  ( bottom - (scrolltop + CLIENHEIGHT)) > 0 'CLIENHEIGHT-(item.top-scrolltop)' : '100%'
//   if (isInViewPort(item.el)) {
//     if (item.top <= 0) {
//       return (item.bottom - scrollTop) / CLIENHEIGHT
//     } else {
//       return item.bottom - (scrollTop + CLIENHEIGHT) >= 0
//         ? (CLIENHEIGHT - (item.top - scrollTop)) / CLIENHEIGHT
//         : item.height / CLIENHEIGHT
//     }
//   }
//   return 0
// }

function visibleHeightPercentTwo ({ el, top, height, bottom }, scrollTop) {
  // 1. 判断是否在可视区域
  // 2. item.top < scrooltop  bottom - scrolltop
  // 3. item.top >= scrooltop  ( bottom - (scrolltop + CLIENHEIGHT)) > 0 'CLIENHEIGHT-(item.top-scrolltop)' : '100%'
  if (isInViewPort(el)) {
    // 完全在可视区域
    if (top >= 0 && bottom < CLIENHEIGHT) {
      return height / CLIENHEIGHT
    }
    // 上半部分在可视区域
    if (top < CLIENHEIGHT && bottom > CLIENHEIGHT) {
      // return height / CLIENHEIGHT
      return (height - (bottom - CLIENHEIGHT)) / CLIENHEIGHT
    }
    // 下半部分在可视区域
    if (top < 0 && top < height) {
      return bottom / CLIENHEIGHT
    }
  }
  return 0
}

function getAllImgDataList (selector, scrollTop) {
  // ID代表容器获取某个ID下面的所有图片地址
  const parentNode = document.querySelector(selector)
  const imgList = Array.from(parentNode.querySelectorAll('img')).map(
    (item, index) => {
      const itemRect = item.getBoundingClientRect()
      const top = itemRect.top
      const height = itemRect.height
      const bottom = itemRect.bottom // 这个值是底部距离顶部的高度
      return {
        index,
        top,
        scrollTop,
        bottom,
        height,
        el: item,
        src: item.src,
        percent: visibleHeightPercentTwo({ top, bottom, height, el: item }, scrollTop).toFixed(4)
      }
    }
  )
  return imgList
}
function getTab (selector) {
  const el = document.querySelector(selector)
  if (!el) return {}
  const { height } = el.getBoundingClientRect()
  return {
    percent: height / CLIENHEIGHT
  }
}
// getTab()
export function uploadImagePercent (parentNode, tabSelecter) {
  const tabData = getTab(tabSelecter)
  timer = setInterval(() => {
  // 部分页面进行数据上报,如果当前ID不在预设列则清空队列
    if (!LANDING_PAGE_ID_LIST.includes(getSearch('id'))) {
      clearInterval(timer)
      return
    }
    // 判断当前组件是否在可视区域
    MAX_COUNT--
    if (MAX_COUNT <= 0) clearInterval(timer)
    var scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    const data = getAllImgDataList(parentNode, scrollTop)
    console.log('getAllImgDataList', data)
    const imgData = data.filter((item) => item.percent > 0)
    if (imgData.length) {
      this.$log.log({
        event: 'active_heartbeat',
        module_ori: '进入落地页首页',
        module_part: `106_tf_h5_00222_${getSearch('id')}`,
        alternate: { imgData, tabData }
      })
    }
  }, 3000)

  this.$once('hook:beforeDestroy', () => {
    clearInterval(timer)
    timer = null
  })
}
