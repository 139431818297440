import Store from '@/store'
export default async () => {
  if (localStorage.getItem('authing')) {
    await Store.dispatch('user/getLogin')
  }
  // 获取用户信息
  if (!Store.state.user.userInfo) {
    await Store.dispatch('user/getUserInfo')
  }
  // 不存在微信授权信息，获取授权信息
  if (!Store.state.user.wxConfig && localStorage.getItem('Authorization')) {
    await Store.dispatch('user/getWxConfig')
  }
}
