<template>
  <div
    :class="`landpage-countdown ${direction === COUNT_DOWN_STYLE.V ? 'vertical' : 'horizontal'}`"
    v-if="is_show"
    :style="{
      top: (460 - y) / 75 + 'rem',
      left: (540 + x) / 75 + 'rem',
      color: font_color
    }">
    <span
      v-if="is_show_title"
      class="title"
      :style="{
        fontSize: titleStyle.font_size/75 + 'rem'
      }">{{ title }}</span>
    <VanCountDown
      :style="{
        fontSize: font_size / 75 + 'rem'
      }"
      :time="comTime"
      :millisecond="hasMs"
      :format="type" />
  </div>
</template>
<script>
import { COUNT_DOWN_STYLE } from '@/constant'

/* eslint-disable camelcase */
export default {
  name: 'CountDown',
  props: {
    // 倒计时区域的配置信息
    // countDown.is_show: [true|false] 是否渲染
    // countDown.font_color: "#f40101" 倒计时文字颜色
    // countDown.font_size: 40 倒计时文字大小
    // countDown.time: {hour: 0, min: 0, sec: 0, ss: 0}倒计时时间
    // countDown.type: 'HH:mm:ss' 倒计时类型
    // countDown.x: 12 相对默认位置x轴偏移量
    // countDown.y: 12 相对默认位置y轴偏移量
    countDown: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      COUNT_DOWN_STYLE,
      ...this.countDown
    }
  },
  computed: {
    hasMs () {
      return this.type === 'mm:ss:SS' || this.type === 'HH:mm:ss:SS'
    },
    comTime () {
      const { min, sec, hour, ss } = this.time
      return this.type === 'mm:ss:SS' ? min * 60 * 1000 + sec * 1000 + ss : hour * 60 * 60 * 1000 + min * 60 * 1000 + sec * 1000
    }
  },
  created () {
    console.log(this.countDown, 'countDown')
  }
}
</script>
<style lang="scss" scoped>
</style>
