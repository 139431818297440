<template>
  <div
    class="buying"
    v-if="value.group_buy && value.group_buy.is_show">
    <div
      class="groups"
      v-for="(item, i) in value.group_buy.groups"
      :key="i"
      :style="{
        borderTopLeftRadius: item.bg_style.radius_visibility.top_left / 2 + 'px',
        borderTopRightRadius: item.bg_style.radius_visibility.top_right / 2 + 'px',
        borderBottomLeftRadius: item.bg_style.radius_visibility.bottom_left / 2 + 'px',
        borderBottomRightRadius: item.bg_style.radius_visibility.bottom_right / 2 + 'px',
        backgroundColor: item.bg_style.bg_color,
        backgroundImage:`url(${(item.bg_img && item.bg_img.length) ? item.bg_img[0].url : '' })`
      }">
      <div
        class="tit van-hairline--bottom van-ellipsis"
        :style="{
          fontSize: item.title.font_size / 75 + 'rem',
          color: item.title.font_color,
          textAlign: item.title.text_align
        }"
        v-if="item.title.is_show">
        <span>{{ item.title.content1 }}</span>
        <span :style="{color: 'red'}">{{ item.title.content2 }}</span>
        <span>{{ item.title.content3 }}</span></div>
      <div
        class="content">
        <div
          class="portrait"
          :style="{
            backgroundImage:'url(' + item.portrait + ')'
          }">
        </div>
        <div
          class="uname"
          :style="{
            color: item.name_color
          }">{{ item.name }}</div>
        <div class="rest">
          <p class="rest-chance">还差<span class="heightLight1">1人</span>拼成</p>
          <div
            class="rest-time"
            v-show="item.count_down.is_show">
            <span
              :style="{
                color: item.count_down.title_color
              }">{{ item.count_down.title }}</span>
            <GroupCountDown :count-down="item.count_down"></GroupCountDown>
          </div>
        </div>
        <div
          class="goBug flexCenter"
          v-show="item.group_btn.is_show"
          :style="{
            borderTopLeftRadius: item.group_btn.radius_visibility.top_left / 2 + 'px',
            borderTopRightRadius: item.group_btn.radius_visibility.top_right / 2 + 'px',
            borderBottomLeftRadius: item.group_btn.radius_visibility.bottom_left / 2 + 'px',
            borderBottomRightRadius: item.group_btn.radius_visibility.bottom_right / 2 + 'px',
            backgroundImage:`url(${(item.group_btn.img.length) ? item.group_btn.img[0].url : '' })`,
            top: item.group_btn.top + 'px',
            backgroundColor: item.group_btn.bg_color,
            height: item.group_btn.height / 2 + 'px',
            lineHeight: item.group_btn.height / 2 + 'px',
            width: item.group_btn.width / 2 + 'px',
            fontSize: item.group_btn.input_text.font_size / 2 + 'px',
            color: item.group_btn.input_text.color,
            fontStyle: item.group_btn.input_text.style.includes('italic') ? 'italic' : 'normal',
            fontWeight: item.group_btn.input_text.style.includes('bold') ? 700 : 400,
            textDecoration: item.group_btn.input_text.style.includes('underline') ? 'underline': item.group_btn.input_text.style.includes('lineThrough') ? 'line-through': ''
          }"
          @click="handleGroupBug">
          <span v-if="!item.group_btn.img[0]">{{ item.group_btn.input_text.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { getSearch } from '@/utils/index'
import GroupCountDown from './GroupCountDown.vue'

export default {
  name: 'GroupBuy',
  components: {
    GroupCountDown
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    handleGroupBug () {
      this.$log.click({
        current_page: window.location.href,
        module_ori: '去拼团',
        module_part: `106_luodiyehoutai_lcl_0012_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.$emit('formsubmit')
    }
  }
}
</script>
<style lang="scss" scoped>
.buying {
  // padding-top: 30px;
  font-size: 28px;
}
.groups {
  width: 750px;
  margin: auto;
  background-color: #E5E8EF;
  background-repeat: no-repeat;
  background-size: cover;
  .tit {
    box-sizing: border-box;
    padding: 20px;
    width: 750px;
    padding-right: 20px;
  }
  .content {
    @include verCenter;
    justify-content: space-between;
    padding: 0 20px;
    height: 120px;
    .portrait {
      width: 56px;
      height: 56px;
      border-radius: 50px;
      background-color: #EFF1F6;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .uname {
      width: 60px;
      font-size: 32px;
      color: #333333;
      white-space: nowrap;
      font-weight: bold;
    }
    .heightLight1 {
      color: red;
    }
    .rest {
      padding-left: 100px;
      padding-right: 20px;
      &-chance {
        font-size: 26px;
        color: #333333;
        line-height: 37px;
        vertical-align: middle;
      }
      .rest-time {
        @include verCenter;
        font-size: 20px;
        white-space: nowrap;
        color: #333333;
        /deep/ .van-count-down {
          width: 40px;
          font-size: 20px;
          color: #333333;
          line-height: 28px;
          vertical-align: middle;
        }
      }
    }
    .goBug {
      @include horVerCenter;
      width: 136px;
      height: 60px;
      background-color: #C7D6FC;
      border-radius: 30px;
      color: #FFFFFF;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
</style>
