<template>
  <div
    class="ICPRecord"
    v-if="value.ICP_record.is_show"
    :style="{
      backgroundImage:'url(' + comICPBgUrl + ')',
      height: value.ICP_record.ICP_bg.height / 75 + 'rem',
      width: value.ICP_record.ICP_bg.width / 75 + 'rem',
      backgroundColor: value.ICP_record.ICP_bg.bg_color,
      borderTopLeftRadius: value.ICP_record.ICP_bg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: value.ICP_record.ICP_bg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: value.ICP_record.ICP_bg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: value.ICP_record.ICP_bg.radius_visibility.bottom_right / 75 + 'rem'
    }">
    <div
      class="ICPRecord-company cont"
      :style="{
        ...comComponyPosi,
        ...comComponyStyle,
        lineHeight: value.ICP_record.company.input_text.font_size / 75 + 'rem',
        marginTop: value.ICP_record.company.top / 75 + 'rem',
        color: value.ICP_record.company.input_text.color,
        fontSize: value.ICP_record.company.input_text.font_size / 75 + 'rem'
      }">{{ value.ICP_record.company.input_text.text }}</div>
    <div
      class="ICPRecord-ICPNumber cont"
      :style="{
        ...comICPNumberPosi,
        ...comICPNumberStyle,
        lineHeight: value.ICP_record.ICP_number.input_text.font_size / 75 + 'rem',
        marginTop: value.ICP_record.ICP_number.top / 75 + 'rem',
        color: value.ICP_record.ICP_number.input_text.color,
        fontSize: value.ICP_record.ICP_number.input_text.font_size / 75 + 'rem'
      }">{{ value.ICP_record.ICP_number.input_text.text }}</div>
  </div>
</template>
<script>
export default {
  name: 'FormPay',
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {

    // 计算隐私协议等的font样式
    comComponyStyle () {
      const styleObj = {}
      if (this.value.ICP_record.company.input_text.style.includes('underline')) {
        styleObj.textDecoration = 'underline'
      } else if (this.value.ICP_record.company.input_text.style.includes('lineThrough')) {
        styleObj.textDecoration = 'line-through'
      }
      styleObj.fontStyle = this.value.ICP_record.company.input_text.style.includes('italic') ? 'italic' : 'normal'
      styleObj.fontWeight = this.value.ICP_record.company.input_text.style.includes('bold') ? 700 : 400
      return styleObj
    },

    // 计算隐私按钮位置
    comComponyPosi () {
      if (this.value.ICP_record.company.text_align === 'left') {
        return { left: 0 }
      } else if (this.value.ICP_record.company.text_align === 'center') {
        return { left: '50%', transform: 'translateX(-50%)' }
      } else if (this.value.ICP_record.company.text_align === 'right') {
        return { left: '100%', transform: 'translateX(-100%)' }
      } else {
        return { left: '50%', transform: 'translateX(-50%)' }
      }
    },

    // 计算隐私协议等的font样式
    comICPNumberStyle () {
      const styleObj = {}
      if (this.value.ICP_record.ICP_number.input_text.style.includes('underline')) {
        styleObj.textDecoration = 'underline'
      } else if (this.value.ICP_record.ICP_number.input_text.style.includes('lineThrough')) {
        styleObj.textDecoration = 'line-through'
      }
      styleObj.fontStyle = this.value.ICP_record.ICP_number.input_text.style.includes('italic') ? 'italic' : 'normal'
      styleObj.fontWeight = this.value.ICP_record.ICP_number.input_text.style.includes('bold') ? 700 : 400
      return styleObj
    },

    // 计算隐私按钮位置
    comICPNumberPosi () {
      if (this.value.ICP_record.ICP_number.text_align === 'left') {
        return { left: 0 }
      } else if (this.value.ICP_record.ICP_number.text_align === 'center') {
        return { left: '50%', transform: 'translateX(-50%)' }
      } else if (this.value.ICP_record.ICP_number.text_align === 'right') {
        return { left: '100%', transform: 'translateX(-100%)' }
      } else {
        return { left: '50%', transform: 'translateX(-50%)' }
      }
    },
    // 计算备案背景
    comICPBgUrl () {
      return this.value.ICP_record.ICP_img_bg.length ? this.value.ICP_record.ICP_img_bg[0].url : ''
    }
  }

}
</script>
<style lang="scss" scoped>
// 事实展示区域（center）
.ICPRecord {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;

  .cont {
    position: relative;
    width: max-content;
  }
}
</style>
