/*
 * @Author: MiaoshunLee
 * @Date: 2023-06-14 16:00:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-07-06 16:30:15
 * @Description: API
 */

import service from './service'
import { getSearch } from '@/utils/index'

export default {
  user: {
    // 获取用户信息(埋点)
    getUserInfo: (params) => {
      params = { ...params, ...getSearch() }
      return service.get('/throw/m/wxauth/user', { params })
    },
    getWxConfig: params => {
      const query = getSearch()
      if (query.appid === 'changqing-shoushen') {
        params.doman = 'cqslim'
      }
      return service.get('/throw/wxauth/package', { params })
    },
    // 用授权中心的token更换token
    getToken: params => service.get('throw/wxauth/login', { params }),
    // AB测试
    getGroup: params => service.post('/throw/v3/abtest/getGroup', params),
    // 分班
    fenban: params => service.post('/throw/m/miniProgram/fenban', params),
    // 新分班
    newFenban: params => service.post('/throw/m/miniProgram/cqFenBan', params),
    // 是否已购买长轻课程
    cqCheckBought: data => service.post('/cq_api/live/fenban/check_bought', data),
    // 自动退费
    autoRefund: data => service.post('/throw/m/physicalOrder/autoRefund', data)
  },
  landPage: {
    // 生成wmclick
    newClickId: (data) => service.get('/throw/common/newClickId', data),
    // 立即报名(支付)
    postPay: data => service.post('/throw/v3/m/wmpay/pay', data),
    // 获取用户编辑详情
    getPageConfig: params => service.get('throw/landing/page', { params }),
    //  手机验证码-发送[带站点]
    getcodeWithSite: data => service.post('throw/common/codeWithSite', data),
    //  手机验证码-发送
    getCode: data => service.post('throw/common/code', data),
    //  手机验证码-获取手机国别号
    getphoneAreaCode: params => service.get('/throw/phoneAreaCode', { params }),
    // 判断是否购买完成
    getCheckBought: params => service.get('throw/v3/m/common/checkBought', { params }),
    // 获取分班二维码
    getQrcode: params => service.get('throw/m/infoflow/getClassQr', { params }),
    // 绑定手机号
    bindPhone: params => service.post('/throw/m/common/bindUserIdMobile', params),
    // 获取最近购买用户
    getLatestUser: params => service.get('/throw/infoflow/latestPayUser', { params }),
    // 获取隐私协议内容
    getConcealGuardCont: (params) => service.get('/throw/landing/treaty', { params }),
    // 长按二维码回传 !tips 需要回传后端
    pressClassQr: params => service.get('/throw/m/infoflow/pressClassQr', { params }),
    // 提前校验验证码,是否购买
    postPayBeforeCheck: data => service.post('throw/v3/wmpay/payBeforeCheck', data),
    // 微信外支付
    postPayOut: data => service.post('/throw/v3/wmpay/pay', data),
    // 微信外检查订单
    checkBought: params => service.get('throw/v3/common/checkBought', { params }),
    // 一键复制回传
    getQuickCopy: params => service.post('/throw/infoflow/quickCopy', params),
    // 获取小程序链接
    getMiniHref: data => service.post('/throw/miniProgram/getMiniProgramUrl', data),
    // 获取分组测试信息 V3
    // 获取AB测试分组（新版）
    getGroup: params => service.post('/throw/v3/abtest/getGroup', params),
    // 订单详情-订单编号
    getOneByOrderNumWx: params => service.get('throw/m/infoflow/getOneByOrderNum', { params }),
    getOneByOrderNum: params => service.get('throw/infoflow/getOneByOrderNum', { params }),
    // 获取未支付订单
    getPreNoPayOrder: params => service.get('throw/getPreNoPayOrder', { params }),
    // 查询投放渠道
    getChannelId: params => service.get('/frontend/applets/experience/getMediaBySiteId', { params }),
    // 查询付款金额
    getLatestPaidOrder: params => service.get('throw/m/common/latestPaidOrder', { params }),
    // 省市区
    getAreasList: params => service.get('throw/tool/areas/list', { params }),
    // 跳过问卷
    getIsSkip: params => service.get('throw/question/isSkip', { params }),
    // 获客链接（分班）
    getHkLink: params => service.get('/throw/getHkLink', { params }),
    // 用户进班
    joinClass: params => service.get('/throw/m/infoflow/joinClass', { params })
  },
  fission: {
    // （弹幕）获取被邀请人支付记录
    getInviteeFinanceList: params => service.get('frontend/applets/fission/getInviteeFinanceList', { params }),
    // 邀请关系绑定
    fissionInviteBind: data => service.post('/frontend/applets/fission/fissionInviteBind', data),
    // 落地页页面配置
    getFissionConfig: params => service.get('/frontend/applets/fission/getFissionConfig', { params }),
    // 获取邀请人信息
    getUserBaseInfo: params => service.get('frontend/applets/fission/userBaseInfo', { params }),
    // 裂变1.0，获取老师二维码
    getTeacherQr: params => service.get('frontend/official_xk/newFission/signUpResult', { params })
  },
  baiduPcPayOrder: {
    // 获取分班二维码
    getClassQr: params => service.get('/throw/m/infoflow/getClassQr', { params }),
    // 添加问卷延时分班
    delayedDivision: params => service.get('/throw/m/infoflow/delayedDivision', { params })
  },
  questionnaire: {
    getGroup: data => service.post('/throw/v3/abtest/getGroup', data), // 获取AB测试分组（新版）
    getQuestionV2: data => service.post('/throw/m/questionnaire/question', data), // 长轻问卷获取题目
    savaAnswer: data => service.post('/throw/m/questionnaire/answer', data), // 问卷回复
    getResourceTypeBySite: params => service.get('/throw/m/infoflow/getResourceTypeBySite', { params })
  },
  common: {
    deviceReport: data => service.post('/frontend/official_xk/device/report', data)
  },
  // 图书订单
  bookOrder: {
    detail: params => service.get('/throw/physicalOrder/detail', { params }),
    pay: data => service.post('/throw/physicalOrder/pay', data),
    cancelOrder: data => service.post('/throw/physicalOrder/cancelOrder', data),
    refund: data => service.post('/throw/physicalOrder/refund', data),
    logistics: params => service.get('/throw/m/physicalOrder/detail ', { params })
  },
  // 抖音
  tiktok: {
    couponRedemption: data => service.post('/throw/infoflow/couponRedemption', data) // 卡券订单核销
  }
}
