<template>
  <div
    ref="imgBox"
    class="landpage-detail">
    <div
      v-if="value.richText"
      class="richtext-box"
      v-html="realHtml"
      style="font-size: 14px;line-height: 1.5;"></div>
    <div
      v-else-if="
        isDetailPageEmpty">
      <img
        class="editpage-imgheader"
        :src="`${baseUrl}${cutImgs.detail_img}`"
        alt="">
    </div>
    <div
      v-else
      class="editpage-detailpage">
      <div v-if="!detailConfig.is_slide">
        <img
          class="editpage-imgheader"
          v-for="(url, k) in detailConfig.img_list"
          :key="k"
          :src="url.url"
          alt="">
      </div>
      <VanSwipe
        v-else
        class="landpage-detail-swiper"
        :autoplay="3000"
        indicator-color="white">
        <VanSwipeItem
          v-for="(url, k) in detailConfig.img_list"
          :key="k">
          <img
            loading="lazy"
            class="editpage-imgheader"
            :src="url.url"
            alt="">
        </VanSwipeItem>
      </VanSwipe>
    </div>
  </div>
</template>
<script>
// import { htmlForRender } from '../config/richtext'

export default {
  name: 'DetailImg',
  props: {
    mark: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      cutImgs: {
        // 详情图背景
        detail_img: 'defaultSlice/detailImg.png'
      }
    }
  },
  computed: {
    detailConfig () {
      const index = this.mark.split('DetailImg')[1]
      return this.value.detail_img[index]
    },
    // 详情多页判空
    isDetailPageEmpty () {
      return this.detailConfig.img_list.length === 0
    },
    realHtml () {
      // TODO 视频需要把宽度大于375的都改成100%
      // return htmlForRender(this.value.detail_richtext)
      return this.value.detail_richtext ? decodeURIComponent(this.value.detail_richtext) : ''
    }
  },
  mounted () {},
  beforeDestroy () {
    // clearInterval(timer)
    // timer = null
  }
}
</script>

<style scoped>
.editpage-imgheader {
  display: block;
}
</style>
