export const codeMap = {
  30005: {
    msg: '您已学习过此课程', // 弹窗3-1
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0026'
    }
  },
  30006: {
    msg: '您已学习过此课程', // 弹窗3-1
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0026'
    }
  },
  30007: {
    msg: '您已购买过此课程', // 弹窗3-2
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0027'
    }
  },
  30004: {
    msg: '您已购买过同类课程', // 弹窗2-4
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0028'
    }
  },
  30002: {
    msg: '您已购买过同类课程', // 弹窗3-3
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0028'
    }
  },
  33002: {
    msg: '验证码有误,请重填', // 弹窗1-4
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0018'
    }
  },
  30008: {
    msg: '该手机号已和另外一微信绑定', // 弹窗4-1确定(v3)
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0029'
    }
  },
  30009: {
    msg: '您已学习过此课程', // 弹窗3-1查看老师(v3)
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0026'
    }
  },
  30010: {
    msg: '您已购买过此课程', // 弹窗3-2确定(v3)
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0027'
    }
  },
  30011: {
    msg: '您已购买过同类课程', // 弹窗3-3确定(v3)
    report: {
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: '106_luodiyehoutai_lcl_0028'
    }
  },
  30012: {
    msg: '您已是微淼实践课学员，暂不能购买', // 大课保护期
    report: {

    }
  },
  30013: {
    msg: '', // 大课上课期间
    report: {

    }
  },
  30018: {
    msg: '', // 黑名单
    report: {

    }
  },
  33004: {
    msg: '抱歉~该链接已失效', // 购买限制
    report: {
      current_page: location.href,
      module_ori: '立即支付时，【抱歉~该链接已失效】提示',
      module_part: '106_tf_h5_00145'
    }
  },
  30023: {
    msg: '', // 买过复训
    report: {
    }
  },
  30024: {
    msg: '', // 白名单用户购买
    report: {
    }
  },
  30025: {
    msg: '', // 黑名单
    report: {
    }
  }
}
