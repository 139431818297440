<template>
  <div>
    <VanPopup v-model="showPicker" position="bottom">
      <VanPicker
        title="地区选择"
        show-toolbar
        :default-index="currentIndex"
        value-key="country_name"
        :columns="codeArr"
        @confirm="onConfirm"
        @cancel="showPicker = false" />
    </VanPopup>
  </div>
</template>

<script>
import { Picker } from 'vant'
import { getSearch } from '@/utils'

export default {
  components: {
    VanPicker: Picker
  },
  props: {
    defaultCityCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showPicker: false,
      codeArr: [],
      currentIndex: 0
    }
  },
  async created () {
    await this.getPhoneAreaCode()
  },
  methods: {
    onConfirm (value) {
      this.$emit('getCityCode', value)
      this.showPicker = false
    },
    async getPhoneAreaCode () {
      try {
        const res = await this.$apis.landPage.getphoneAreaCode()
        this.codeArr = res
        // 回显
        const areaCode = getSearch('area_code') || this.defaultCityCode || localStorage.getItem('area_code')
        if (areaCode) {
          const currentArea = this.codeArr.find(v => v.area_code === areaCode)
          this.currentIndex = this.codeArr.findIndex(v => v.area_code === areaCode)
          this.$emit('getCityCode', currentArea)
          return
        }
        // 默认展示第一项
        this.$emit('getCityCode', this.codeArr[0])
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
