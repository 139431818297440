
import { getSearch } from '@/utils/index'
export const payType = {
  methods: {
    // 选择支付方式埋点
    select (orderNum) {
      this.$log.click({
        current_page: window.location.href,
        module_ori: '支付方式选择',
        module_part: `106_tf_xinzeng_0011_${getSearch('id')}`,
        alternate: JSON.stringify({
          ...JSON.parse(getSearch('alternate')),
          order_num: orderNum
        })
      })
    },
    // 选择微信支付埋点
    selectWX (orderNum) {
      this.select(orderNum)
      this.$log.click({
        current_page: window.location.href,
        module_ori: '支付方式选择',
        module_part: `106_tf_xinzeng_0012_${getSearch('id')}`,
        alternate: JSON.stringify({
          ...JSON.parse(getSearch('alternate')),
          order_num: orderNum
        })
      })
    },
    // 选择支付宝支付埋点
    selectAli (orderNum) {
      this.select(orderNum)
      this.$log.click({
        current_page: window.location.href,
        module_ori: '支付方式选择',
        module_part: `106_tf_xinzeng_0013_${getSearch('id')}`,
        alternate: JSON.stringify({
          ...JSON.parse(getSearch('alternate')),
          order_num: orderNum
        })
      })
    }
  }
}
