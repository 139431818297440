import config from '@/config'
import Store from '@/store'
import { Toast } from 'vant'
import wxAuth from './wxAuth'

/**
 * 获取当前环境下px对应的rem值
 * @param {*} pxNum
 * @returns rem
 */
export function px2rem (pxNum) {
  return `${pxNum / 75}rem`
}

/**
 * 是否为微信环境
 * @returns
 */
export function isWeiXin () {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

/**
 * 获取url query
 * @export
 * @param {*} getQuery
 * @returns
 */
export function getQuery (key) {
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  return r ? unescape(r[2]) : null
}
/**
 * @param {*} key
 * @param {*} acAction 活动类型，不必须
 */
export function getSearch (key, acAction) {
  const { protocol, host, hash, search, pathname } = location
  const inWx = isWeiXin()
  const queryStr = search.substr(1) + '&' + (hash.split('?')[1] || '')
  const query = {}
  queryStr && queryStr.split('&').forEach(item => {
    if (!item) return
    const tmp = item.split('=')
    query[tmp[0]] = tmp[1]
  })
  // message_touch必须为1代表活动，并且链接必须拼site_id, acAction需要传入
  if (query.message_touch - 1 === 0 && query.site_id && acAction) {
    query.ac_action = acAction
  }
  query.alternate = JSON.stringify({ ...query, ...JSON.parse(localStorage.getItem('ab_result')), inWx })
  if (key) return query[key]
  // 后端回传需要特殊增加参数
  return { protocol, host, link: pathname + search + hash.split('&wm_click_id')[0], ...query }
}
/**
 * obj转 url query
 * @param {*} Object
 */
export function obj2str (object) {
  return '?' + Object.keys(object)
    .map(key => `${key}=${object[key]}`)
    .join('&')
}

// 设置导航栏标题
export function setDocumentTitle (title) {
  try {
    const document = window.document
    document.title = title
    if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
      const i = document.createElement('iframe')
      i.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
      i.style.display = 'none'
      i.onload = () => {
        setTimeout(() => {
          i.remove()
        }, 9)
      }
      document.body.appendChild(i)
    }
  } catch (err) {}
}

// 判断当前为是否IOS
export function isIOS () {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  return isiOS
}

// 判断当前是否为安卓
export function isAndroid () {
  const u = navigator.userAgent
  return u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
}

// 判断当前是否为pc端微信
export function isPcWechat () {
  const u = navigator.userAgent
  return u.includes('Wechat')
}

// 获取当前操作系统
export function getOS () {
  var os
  if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1) {
    os = 'Android'
  } else if (navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('iPad') > -1) {
    os = 'iOS'
  } else if (navigator.userAgent.indexOf('Windows Phone') > -1) {
    os = 'WP'
  } else {
    os = 'Others'
  }
  window.localStorage.setItem('os', os)
  return os
}

/**
 * 节流函数
 * parmas {function} fn 回调函数
 * parmas {Number} wait 节流函数时间间隔
 */
export const throttleTimerOut = function (fn, wait = 1000) {
  let Timer
  const throttle = function () {
    return new Promise((resolve, reject) => {
      if (!Timer) {
        Timer = setTimeout(() => {
          clearTimeout(Timer)
          Timer = null
          resolve(fn.call(this, ...arguments))
        }, wait)
      }
    })
  }
  return throttle
}

/**
 * 防抖函数
 * parmas {function} fn 回调函数
 * parmas {Number} wait 防抖函数时间间隔
 * parmas {Number} immediate 是否立即执行
 */
export const debounce = function (fn, wait = 500, immediate = false) {
  let Timer
  const debounced = function () {
    return new Promise((resolve, reject) => {
      if (Timer) clearTimeout(Timer)
      if (immediate && !Timer) {
        resolve(fn.call(this, ...arguments))
      }
      Timer = setTimeout(() => {
        resolve(fn.call(this, ...arguments))
      }, wait)
    })
  }
  debounced.cancel = function () {
    clearTimeout(Timer)
    Timer = null
  }
  return debounced
}
/**
 * 判断滚动到底部 scrollBottom
 * parmas {number} top 是需要滚动的高度
 */
export const scrollBottom = throttleTimerOut((fn, top = 320) => {
  const scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight)
  // 滚动条滚动距离
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  // 窗口可视范围高度
  const clientHeight = window.innerHeight || Math.min(document.documentElement.clientHeight, document.body.clientHeight)
  if (clientHeight + scrollTop >= scrollHeight - top) {
    // eslint-disable-next-line no-undef
    fn.call(this, ...arguments)
  }
})
// 获取openid
export async function getOpenid (scope = 'snsapi_userinfo') {
  const VUE_APP_BROWER = process.env.VUE_APP_BROWER
  if (VUE_APP_BROWER && VUE_APP_BROWER === 'chrome') {
    return process.env.VUE_APP_OPENID
  }

  const code = getQuery('code')
  // 不存在code，去微信获取code
  if (!code) {
    const url = encodeURIComponent(location.href)
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config[process.env.VUE_ENV].wxConfig.appid}&redirect_uri=${url}&response_type=code&scope=${scope}&state=123#wechat_redirect`
    return
  }

  // 存在code,更新用户信息
  const { openid } = await Store.$apis.user.updateWxUser({ code, scope })
  return openid
}
/**
 * @description 复制文本
 * @param {String} value 需要复制的文本
 */
export function copyText (value, text = '复制成功,去微信搜索吧！') {
  const iptEl = document.createElement('input')
  iptEl.setAttribute('readonly', 'readonly')
  iptEl.value = value
  document.body.appendChild(iptEl)
  try {
    iptEl.select()
    document.execCommand('copy')
    Toast.success(text)
  } catch (error) {
    Toast.fail('复制失败，请重试')
  }
  document.body.removeChild(iptEl)
}
// 校验手机号格式与验证码填写
export function vertifyPhoneCode ({ form, justifyCode = true, dom, that, cityCodePreg = new RegExp(/^1[3456789]\d{9}$/) }) {
  if (!form.phone) {
    Toast('请填写手机号')
    if (that) scrollView(that, '.landpage-formpay')
    else if (dom) dom.phone.focus()
    Store.$log.click({
      current_page: window.location.href,
      module_ori: '表单错误模块',
      module_part: `106_luodiyehoutai_lcl_0015_${getSearch('id')}`,
      alternate: getSearch('alternate')
    })
    return false
  }
  // 手机号验证
  if (!cityCodePreg.test(form.phone)) {
    Toast('手机号有误，请重填')
    if (that) scrollView(that, '.landpage-formpay')
    else if (dom) dom.phone.focus()
    Store.$log.click({
      current_page: window.location.href,
      module_ori: '表单错误模块',
      module_part: `106_luodiyehoutai_lcl_0016_${getSearch('id')}`,
      alternate: getSearch('alternate')
    })
    return false
  }
  if (!form.testCode && justifyCode) {
    Toast('请填写验证码')
    if (that) scrollView(that, '.landpage-formpay')
    else if (dom) dom.phone.focus()
    Store.$log.click({
      current_page: location.href,
      module_ori: '表单错误模块',
      module_part: `106_luodiyehoutai_lcl_0017_${getSearch('id')}`,
      alternate: getSearch('alternate')
    })
    return false
  }
  return true
}

export function scrollView (that, selector) {
  const dom = that.$el.querySelector(selector)
  if (dom) {
    dom.scrollIntoView({
      behavior: 'smooth', // 平滑过渡
      block: 'start' // 上边框与视窗顶部平齐
    })
  }
}

export function loadJs (jscode) {
  const scriptExp = /<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/ig
  const matches = scriptExp.exec(jscode)
  let code = jscode
  if (matches[1]) {
    code = matches[1]
  }
  // 插入标签
  var oHead = document.getElementsByTagName('head')[0] // 在head标签中创建创建script
  var oScript = document.createElement('script')
  oHead.appendChild(oScript)
  oScript.innerHTML = code
  oHead.appendChild(oScript)
}

export function evil (fn) {
  const Fn = Function
  return new Fn('return ' + fn)()
}

export const auth = wxAuth

// 判断dom是否在视口内
export function isInViewPort (element) {
  // const viewWidth = window.innerWidth || document.documentElement.clientWidth
  const viewHeight = window.innerHeight || document.documentElement.clientHeight
  // const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  // console.log('isInViewPort', viewWidth, viewHeight, scrollTop)
  const {
    top,
    // right,
    bottom
    // left
  } = element.getBoundingClientRect()
  // console.log('111---', top >= 0, bottom <= scrollTop)
  if (top >= 0) { return top <= viewHeight }
  if (top < 0 && bottom > 0) { return true }
  return false
}

export function combineUrl (url, target) {
  url = new URL(url)
  url.searchParams.set('targeturl', target)
  return url.toString()
}

/**
* @description:获取时间
* @param date： 时间对象
* @param cell： 单位
* eg:  2021年11月07日   2021/11/07
*/
export function getFormatDate (date, cell = ['年', '月', '日']) {
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  var currentdate = `${year}${cell[0] || ''}${month}${cell[1] || ''}${strDate}${cell[2] || ''}`
  return currentdate
}

/**
 * @Date: 2021-01-08 19:22:25
 * @author: zw
 * @description: 手机号验证
 */
export function isPhone (phone, reg = '') {
  const regex = reg || /^[1]([3-9])[0-9]{9}$/
  return regex.test(phone)
}

/**
 * @description: 补0
 */
export function addZero (value) {
  if (!value || value > 9) return value
  return `0${value}`
}
