export function debounce (fn, wait) {
  wait = wait || 100
  var timer = null
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)// 保证this的指向 和 参数的传递
    }, wait)
  }
}
export default {
  install (Vue) {
    // 防止重复点击
    Vue.directive('debounce', {
      inserted (el, binding) {
        const fn = el.click
        el.click = debounce(fn.bind(el), binding.value || 3000)
      }
    })
  }
}
