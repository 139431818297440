<template>
  <div>
    <!--  -->
    <VanDialog
      class="detentionDialog"
      v-model="visible"
      :show-confirm-button="false"
      close-on-click-overlay
      :style="{
        backgroundImage:'url(' + comDetentionBg + ')',
        backgroundSize: 'contain',
        height: detent_bg.height / 75 + 'rem',
        width: detent_bg.width / 75 + 'rem',
        backgroundColor: detent_bg.bg_color,
        borderTopLeftRadius: detent_bg.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: detent_bg.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: detent_bg.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: detent_bg.radius_visibility.bottom_right / 75 + 'rem'
      }">
      <img
        src="@/assets/icons/close-btn-circle.png"
        class="close-btn"
        @click="close">
    </VanDialog>
  </div>
</template>
<script>
// import { getSearch } from '@/utils/index'
/* eslint-disable camelcase */
export default {
  name: 'DetentionDialog',
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    centerCouponInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      ...this.value.detention_dialog_again,
      visible: false
    }
  },
  computed: {
    // 计算挽留弹窗背景
    comDetentionBg () {
      return this.detention_bg.length ? this.detention_bg[0].url : ''
    },
    // 计算确认按钮加粗
    comConfirmBold () {
      return this.confirm.input_text.style.includes('bold') ? 700 : 400
    }
  },
  watch: {
    'value.showDialogAgain': {
      handler (newVal) {
        if (newVal) {
          if (this.is_show) {
            this.visible = true
          }
        } else {
          this.visible = false
        }
      }
    }
  },
  methods: {
    close () {
      this.visible = false
    }
    // detentionPay () {
    //   this.$log.click({
    //     current_page: window.location.href,
    //     module_ori: '挽留弹窗',
    //     module_part: `106_luodiyehoutai_lcl_0009_${getSearch('id')}`,
    //     alternate: getSearch('alternate')
    //   })

    //   this.$emit('detentionpay', 'again')
    // },
    // cancelDialog () {
    //   // 取消支付，支付类型
    //   this.$log.click({
    //     current_page: window.location.href,
    //     module_ori: '挽留弹窗',
    //     module_part: `106_luodiyehoutai_lcl_0008_${getSearch('id')}`,
    //     alternate: getSearch('alternate')
    //   })

    //   this.$emit('cancelpayAgain')
    // }
  }
}
</script>
<style lang="scss" scoped>
.detentionDialog {
  /deep/ {
    background-color: rgba(0,0,0,0);
  }
}

.close-btn {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 16px;
  right: 16px;
}
</style>
