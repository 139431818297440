<template>
  <div
    @click="jumptoPage"
    class="jumpto-box"
    v-if="jumptoMiniappShow"
    :style="{
      top: jumptoMiniapp.company.top + '%',
      right:jumptoMiniapp.company.right / 75 + 'rem',
      marginTop: -(jumptoBtn.height / 2) / 75 + 'rem',
      textAlign: jumptoMiniapp.company.text_align,
      width: jumptoBtn.width / 75 + 'rem',
      height: jumptoBtn.height / 75 + 'rem',
      backgroundColor: jumptoBtn.bg_color,
      borderTopLeftRadius: jumptoBtn.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: jumptoBtn.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: jumptoBtn.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: jumptoBtn.radius_visibility.bottom_right / 75 + 'rem'
    }">
    <img :src="jumptoBtn.jumpto_btn_img[0].url" alt="">
  </div>
</template>

<script>
import { getSearch } from '@/utils/index'
import { miniEnv } from '@/config'

export default {
  name: 'JumptoMiniapp',
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    jumptoMiniapp () {
      return this.value.jumpto_miniapp
    },
    jumptoBtn () {
      return this.jumptoMiniapp.jumpto_btn
    },
    jumptoMiniappShow () {
      return this.jumptoMiniapp?.is_show
    }
  },
  methods: {
    async jumptoPage () { // 跳转小程序
      this.$log.click({
        current_page: location.href,
        module_ori: '点击【邀好友享健康】',
        module_part: `106_tf_h5_00249_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      const { url } = await this.$apis.landPage.getMiniHref({
        path: this.jumptoMiniapp.path,
        appid: this.jumptoMiniapp.appid,
        // appid_group: 3,
        env_version: miniEnv,
        query_with_appid: 1,
        query: {
          site_id: getSearch('site_id') || ''
        }
      })
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
.jumpto-box {
  z-index: 99;
  position: fixed;
  top: 50%;
  right: 0;
  width: 120px;
  height: 120px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
