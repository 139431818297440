import wxPromisify from './wxPromisify'

const promiseWx = {
  mount (params) {
    const config = {
      ...params
    }
    return new Promise((resolve, reject) => {
      wxPromisify
        .config(config)
        .then(resolve)
        .catch(reject)
    })
  },
  install (VueModule) {
    VueModule.prototype.$wx = wxPromisify
  }
}

export default promiseWx
