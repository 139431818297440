<template>
  <div class="landpage-header" v-if="visible">
    <template v-if="value.richText">
      <div
        v-html="realHtml"
        class="richtext-box"
        style="font-size: 14px;line-height: 1.5;"></div>
    </template>
    <template v-else-if="type==='img'">
      <img
        loading="lazy"
        v-if="!is_slide"
        class="landpage-header-imgheader"
        :src="comHeaderUrl"
        alt="">
      <VanSwipe
        v-else
        class="landpage-header-swiper"
        :autoplay="3000"
        indicator-color="white">
        <VanSwipeItem
          v-for="(item, k) in comHeaderUrls"
          :key="k">
          <img
            loading="lazy"
            :src="item.url"
            alt="">
        </VanSwipeItem>
      </VanSwipe>
    </template>
    <template v-else>
      <video
        v-if="video_list.length"
        width="100%"
        :src="video_list[0].url.split('?')[0]"
        controls="controls"
        webkit-playsinline=""
        playsinline=""
        x5-playsinline=""
        x5-video-player-fullscreen=""
        x5-video-orientation="portraint"
        x5-video-player-type="h5"
        x-webkit-airplay="allow"
        preload="auto"
        :poster="video_list[0].url">
        您的手机不支持该视频文件
      </video>
      <img
        v-else
        loading="lazy"
        :src="`${this.baseUrl}${cutImgs.header_video}`"
        alt="">
    </template>
  </div>
</template>
<script>
import { NO_HEAD_PAGE_ID } from '@/constant'
import { getSearch } from '@/utils'

// import { htmlForRender } from '../config/richtext'
/* eslint-disable camelcase */
export default {
  name: 'HeaderImg',
  props: {
    headerImg: {
      type: Object,
      default: () => {
        return {}
      }
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      ...this.headerImg,
      cutImgs: {
        // 头图背景
        header_img: 'defaultSlice/headerImg.png',
        // 头图背景(视频)
        header_video: 'defaultSlice/headerVideo.png'
      },
      visible: true
    }
  },
  computed: {
    // 计算头图
    comHeaderUrl () {
      return this.img_list[0] ? this.img_list[0].url : `${this.baseUrl}${this.cutImgs.header_img}`
    },
    // 计算头图轮图片
    comHeaderUrls () {
      return this.img_list.length ? this.img_list : [{ url: `${this.baseUrl}${this.cutImgs.header_img}` }]
    },
    realHtml () {
      // TODO 视频需要把宽度大于375的都改成100%
      // return htmlForRender(this.value.head_richtext)
      return this.value.head_richtext ? decodeURIComponent(this.value.head_richtext) : ''
    }
  },
  created () {
    const id = getSearch('id')
    if (NO_HEAD_PAGE_ID.includes(id)) {
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.landpage {
  position: relative;
  min-height: 100vh;
  // header区域
  &-header {
    position: relative;
  }
}
</style>
