<template>
  <VanPopup
    class="area-pop"
    v-model="visible"
    position="bottom">
    <div class="area-box">
      <VanArea
        @confirm="confirm"
        @cancel="cancel"
        :title="title"
        :area-list="areaList"
        :value="selValue" />
    </div>
  </VanPopup>
</template>

<script>
import Vue from 'vue'
import { Area } from 'vant'

Vue.use(Area)

export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      selValue: '',
      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      }
    }
  },
  created () {
    this.getAreasList()
  },
  methods: {
    confirm (val) {
      this.selValue = val?.map(item => item.code).join(',')
      this.$emit('confirm', val)
      this.visible = !this.visible
    },
    cancel () {
      this.visible = !this.visible
    },
    async getAreasList () {
      try {
        const { province_list: provinceList, city_list: cityList, area_list: areaList } = await this.$apis.landPage.getAreasList()
        this.areaList = {
          province_list: provinceList,
          city_list: cityList,
          county_list: areaList
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
