<template>
  <div class="groups" v-if="value.group_buy_new && value.group_buy_new.is_show">
    <div
      class="group-item"
      v-for="(item, i) in value.group_buy_new.groups"
      :key="i"
      :style="{
        backgroundImage:`url(${item.bg_img[0] ? item.bg_img[0].url : ''})`,
        width: item.width / 75 + 'rem',
        height: item.height / 75 + 'rem'
      }"
      @click="handleGroupBug">
    </div>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { getSearch } from '@/utils/index'

export default {
  name: 'GroupBuy',
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    handleGroupBug () {
      this.$log.click({
        current_page: window.location.href,
        module_ori: '去拼团',
        module_part: `106_luodiyehoutai_lcl_0012_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.$emit('formsubmit')
    }
  }
}
</script>
<style lang="scss" scoped>
.group-item {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
}
</style>
