import { getQuery } from '@/utils'

async function getVconsole () {
  const VConsole = await import(/* webpackChunkName: "vconsole" */ 'vconsole/dist/vconsole.min.js')
  // eslint-disable-next-line
  new VConsole.default()
}
if (getQuery('vconsole') === 'wm') {
  getVconsole()
}
