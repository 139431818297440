<template>
  <!-- 弹窗 -->
  <VanPopup
    class="login-wrap"
    v-model="show"
    position="center">
    <div class="login-wrap__content">
      <div class="login-wrap__text">
        您好，授权您的头像和昵称后，才可以开展下一步。
      </div>
      <div class="login-wrap__btn" @click="login">点击授权</div>
    </div>
  </VanPopup>
</template>
<script>
import { getSearch } from '@/utils'
export default {
  name: 'LoginWrap',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    login () {
      this.$log.click({
        module_ori: '按钮点击',
        module_part: `106_tf_H5_GJ_002_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.$emit('login')
    }
  }
}
</script>
<style lang="scss">
.login-wrap {
    width: 580px;
    height: 312px;
    background: #FFFFFF;
    border-radius: 20px;
  .login-wrap__content{
    width: 580px;
    padding:48px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-wrap__text{
    color: #333333;
    font-size: 28px;
    margin-bottom:32px;
    text-align: center;
    line-height: 48px;
    padding:0 54px;
  }
  .login-wrap__btn{
    width: 360px;
    height: 88px;
    background: #07C160;
    border-radius: 44px;
    font-size: 32px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
