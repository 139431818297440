<template>
  <div v-if="visible">
    <VanPopup
      class="form-pop"
      v-model="visible"
      :close-on-click-overlay="false"
      position="bottom">
      <div class="form-wrap">
        <img
          class="close-btn"
          src="@/assets/icons/close-btn.png"
          @click="closePopup" />
        <div class="title">支付剩余时间</div>
        <div class="count-down-wrap">
          <VanCountDown
            ref="countDown"
            :time="timeStamp"
            format="mm:ss:SS"
            millisecond
            @finish="finish">
            <template #default="timeData">
              <span class="box">{{ format(timeData.minutes) }}</span>
              <span class="colon">:</span>
              <span class="box">{{ format(timeData.seconds) }}</span>
              <span class="colon">:</span>
              <span class="box">{{ format(timeData.milliseconds) }}</span>
            </template>
          </VanCountDown>
        </div>
        <VanField
          class="form-field"
          :class="{ fail: isClick && !validatePhone }"
          center
          v-model="form.phone"
          label="手机号"
          type="tel"
          clearable
          placeholder="请填写手机号"
          maxlength="11"
          @input="changePhone"
          @blur="blurPhone">
        </VanField>
        <VanField
          class="form-field testCode"
          :class="{'fail':isClick&&!validateCode}"
          ref="testCode"
          v-model="form.testCode"
          label="验证码"
          center
          clearable
          type="digit"
          maxlength="4"
          :placeholder="value.form_pay.test_code.placeholders.text"
          @input="changeTestCode"
          @blur="blurTestCode">
          <template #button>
            <div class="code-btn">
              <div
                v-if="showGetCodeBtn"
                class="landpage-formpay-getcode"
                @click="getCode">
                {{ getCodeBtnTxt }}
              </div>

              <VanCountDown
                v-if="!showGetCodeBtn"
                ref="countDownGetCode"
                :auto-start="false"
                :time="codeTime"
                format="sss后获取"
                @finish="showGetCodeBtn = true" />
            </div>
          </template>
        </VanField>

        <div class="pay-type" v-if="!isWx">
          <div class="pay-title">选择支付方式</div>
          <div class="pay-type-group">
            <div :class="`item wx-pay ${activeType === PAY_TYPE_MAP.WX ? 'active' : ''}`" @click="selectPayType(PAY_TYPE_MAP.WX)">
              <img src="@/assets/icons/wx-logo.png" class="wx-logo" />
              微信支付
            </div>
            <div :class="`item zfb-pay ${activeType === PAY_TYPE_MAP.ZFB ? 'active' : ''}`" @click="selectPayType(PAY_TYPE_MAP.ZFB)">
              <img src="@/assets/icons/zfb-logo.png" class="zfb-logo" />
              支付宝支付
            </div>
          </div>
        </div>
        <div class="agreement-wrap" v-if="concealGuard.is_show">
          <img
            :src="selectIcon"
            class="select-icon"
            v-if="concealGuard.needSelect"
            @click="selectedAgreement" />
          <span
            class="agreement"
            @click="$emit('clickConcealGuard')">{{ concealGuard.text || '《个人信息授权和保护声明》' }}</span>
        </div>
        <div
          :style="{
            position: 'relative',height: pay_img_btn.height / 75 + 'rem'
          }">
          <div
            class="landpage-bottom-paybtn"
            :style="{
              borderTopLeftRadius: pay_img_btn.radius_visibility.top_left / 75 + 'rem',
              borderTopRightRadius: pay_img_btn.radius_visibility.top_right / 75 + 'rem',
              borderBottomLeftRadius: pay_img_btn.radius_visibility.bottom_left / 75 + 'rem',
              borderBottomRightRadius: pay_img_btn.radius_visibility.bottom_right / 75 + 'rem',
              backgroundImage:'url(' + comBottomBtnUrl + ')',
              left: comSubmitLeft,
              top: pay_img_btn.top / 75 + 'rem',
              right: 0 - pay_img_btn.x / 75 + 'rem',
              backgroundColor: pay_img_btn.bg_color,
              height: pay_img_btn.height / 75 + 'rem',
              lineHeight: pay_img_btn.height / 75+ 'rem',
              width: pay_img_btn.width / 85 + 'rem',
              fontSize: pay_img_btn.input_text.font_size / 75 + 'rem',
              color: pay_img_btn.input_text.color,
              fontWeight: comBottomSubmitBold,
              fontStyle: comBottomSubmitItalic,
              textDecoration: comBottomSubmitDecoration,
              transform: transStyle
            }"
            v-debounce="1500"
            @click="submitClick">
            {{ pay_img_btn.input_text.text }}
          </div>
        </div>
      </div>
    </VanPopup>
  </div>
</template>
<script>
/* eslint-disable camelcase */
import { getSearch, vertifyPhoneCode, isWeiXin } from '@/utils/index'
import notSelectedIcon from '@/assets/icons/not-selected.png'
import selectedIcon from '@/assets/icons/selected.png'
import { BTN_STYLE, CQ_CODE_TIME, PAY_TYPE_MAP } from '@/constant'
import { payType } from '@/mixins/payType'
import { blur } from '@/mixins/blur'
export default {
  name: 'FormPop',
  components: {},
  mixins: [payType, blur],
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    payTypeConfig: {
      type: Object,
      default: () => ({})
    },
    productPrice: {
      type: Number,
      default: 0
    },
    isCq: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      BTN_STYLE,
      PAY_TYPE_MAP,
      ...this.value.new_form_pop,
      showGetCodeBtn: true, // 显示获取验证码code
      getCodeBtnTxt:
        this.value.form_pay.get_code.placeholders.text || '获取验证码', // 获取验证码文案：[获取验证码|重新获取]
      isClick: false, // 是否提交
      isValidateCode: true,
      visible: false,
      activeType: 0,
      isWx: false
    }
  },
  computed: {
    codeTime () {
      return this.isCq ? CQ_CODE_TIME * 1000 : 60 * 1000
    },
    // 校验手机号
    validatePhone () {
      return this.form.phone && /^1[3456789]\d{9}$/.test(this.form.phone)
    },
    // 校验验证码
    validateCode () {
      return this.form.testCode && this.isValidateCode
    },
    selectIcon () {
      return this.form.checked ? selectedIcon : notSelectedIcon
    },
    timeStamp () {
      return this.countDown.min * 60 * 1000 + this.countDown.sec * 1000 + this.countDown.ms
    },
    // 计算抢购按钮距左侧距离
    comSubmitLeft () {
      switch (this.pay_img_btn.text_align) {
        case 'left':
          return 0
        case 'center':
          return '50%'
        case 'right':
          return (750 - this.pay_img_btn.width) / 75 + 'rem'
        default:
          return this.pay_img_btn.x / 75 + 'rem'
      }
    },
    transStyle () {
      if (this.pay_img_btn.text_align === 'center') {
        return 'translateX(-50%)'
      } else {
        return ''
      }
    },
    // 计算提交按钮下划线
    comBottomSubmitDecoration () {
      if (this.pay_img_btn.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.pay_img_btn.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算底部提交按钮斜体
    comBottomSubmitItalic () {
      return this.pay_img_btn.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算底部提交按钮加粗
    comBottomSubmitBold () {
      return this.pay_img_btn.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算底部背景文字下划线
    comBottomBgDecoration () {
      if (this.bottom_bg.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.bottom_bg.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算底部左侧文字斜体
    comBottomBgItalic () {
      return this.bottom_bg.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算底部左侧文字加粗
    comBottomBgBold () {
      return this.bottom_bg.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算底部固定区域btn-url
    comBottomBtnUrl () {
      const imgList = this.pay_img_btn.img_list
      return imgList.length ? imgList[0].url : ''
    },
    // 计算底部固定区域url
    comBottomBgUrl () {
      return this.img_bg.length ? this.img_bg[0].url : ''
    },
    // 当前地区手机号验证正则
    cityCodePreg () {
      const regex = /^1[3456789]\d{9}$/
      return new RegExp(regex)
    }
  },
  watch: {
    'value.showPopNew': {
      handler (newVal) {
        this.visible = newVal
        if (!newVal) {
          this.showGetCodeBtn = true
        } else {
          this.$log.show({
            current_page: location.href,
            module_ori: '支付方式选择',
            module_part: `106_tf_xinzeng_0010_${getSearch('id')}`,
            alternate: getSearch('alternate')
          })
        }
      }
    }
  },
  mounted () {
    this.isWx = isWeiXin()
    if (this.isWx) {
      this.$emit('update:payTypeConfig', { show: false, payType: PAY_TYPE_MAP.WX })
    }
  },
  methods: {
    format (value) {
      if (value > 100) return Math.floor(value / 10)
      return value < 10 ? '0' + value : value
    },
    closePopup () {
      this.$emit('closePopup')
    },
    selectedAgreement () {
      this.form.checked = !this.form.checked
      this.checkCompact(!this.form.checked)
    },
    selectPayType (type) {
      this.activeType = type
      this.$emit('update:payTypeConfig', { show: false, payType: type })
    },
    finish () {
      this.$refs.countDown.reset()
    },
    // 获取验证码
    async getCode () {
      // 手机号格式有误
      if (!vertifyPhoneCode({ form: this.form, justifyCode: false, cityCodePreg: this.cityCodePreg })) return
      this.$log.click({
        current_page: window.location.href,
        module_ori: '表单弹窗',
        module_part: `106_luodiyehoutai_lcl_0001_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.$apis.landPage
        .getcodeWithSite({
          ...getSearch(),
          phone: this.form.phone,
          product_sn: this.value?.product_sn_key
        })
        .then((res) => {
          this.$toast({
            message: '验证码发送成功'
          })
          this.$log.click({
            current_page: location.href,
            module_ori: '手机号、验证码表单',
            module_part: `106_tf_xinzeng_0008_${getSearch('id')}`,
            alternate: JSON.stringify({
              ...JSON.parse(getSearch('alternate')),
              send_id: res.sid,
              page_id: getSearch('id')
            })
          })
          this.showGetCodeBtn = false
          setTimeout(() => {
            this.getCodeBtnTxt = '重新获取'
            this.$refs.countDownGetCode.start()
          }, 0)
        })
    },
    submitClick () {
      this.validateForm()
      this.$log.click({
        current_page: window.location.href,
        module_ori: '表单弹窗',
        module_part: `106_luodiyehoutai_lcl_0014_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.$emit('formsubmit')
    },
    // 验证码校验不通过
    noValidateCode (val) {
      this.isValidateCode = val
    },
    // 提交
    validateForm () {
      this.isClick = true
    },
    // 监听手机号输入
    changePhone (e) {
      if (this.validatePhone) {
        this.getCode()
      }
    },
    // 监听验证码输入
    changeTestCode (e) {
      if (e && e.length === 4) {
        this.submitClick()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.form-pop {
  background-color: transparent;
  box-sizing: border-box;

  .form-wrap {
    width: 100%;
    position: relative;
    background-color: #ffffff;
    border-radius: 24px 24px 0 0;
    padding: 40px;

    .close-btn {
      position: absolute;
      width: 34px;
      height: 34px;
      top: 40px;
      right: 40px;
    }

    .title {
      font-size: 48px;
      font-weight: bold;
      color: #333333;
      line-height: 67px;
      margin: 16px 0;
      text-align: center;
    }

    .count-down-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 32px;

      .box {
        width: 56px;
        height: 56px;
        background: rgba(253, 108, 108, 0.1);
        border-radius: 10px;
        color: #fd6c6c;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-size: 36px;
        font-weight: bold;
      }

      .colon {
        margin: 0 8px;
        color: #fd6c6c;
      }
    }

    .form-field {
      padding: 35px 0;
      position: relative;

      /deep/ .van-field__control,
      /deep/ .van-field__label {
        font-size: 36px;
      }

      // 输入框自动填充 背景色问题
      /deep/ .van-field__control:-webkit-autofill,
      /deep/ .van-field__control:-webkit-autofill:hover,
      /deep/ .van-field__control:-webkit-autofill:focus,
      /deep/ .van-field__control:-webkit-autofill:active {
        -webkit-transition-delay: 9999s;
        -webkit-transition: color 9999s ease-out,
        background-color 9999s ease-out;
      }

      &::after {
        left: 0;
        right: 0;
      }

      &.testCode {
        margin-bottom: 48px;
      }

    }

    /deep/ .van-field__label {
      font-size: 36px;
      color: #000000;
      width: 156px;
      margin-right: 0;
    }

    .verification-code {
      position: relative;
      margin-bottom: 48px;
    }

    .code-btn {
      width: 200px;
      height: 76px;
      background: #EBF8F5;
      border-radius: 40px;
      position: relative;
      top: -2px;
      right: 0;
      font-size: 28px;
      font-weight: bold;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .landpage-formpay-getcode {
        color: #00A37C;
      }

      .van-count-down {
        color: #00A37C;
      }
    }

    .pay-type {
      margin-bottom: 56px;

      .pay-title {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 50px;
        margin-bottom: 32px;
      }

      .pay-type-group {
        display: flex;
        justify-content: space-around;

        .item {
          width: 324px;
          height: 120px;
          background: #F8F8F8;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          box-sizing: border-box;

          img {
            width: 56px;
            height: 56px;
            margin-right: 16px;
          }
        }

        .wx-pay.active {
          background: rgba(7,193,96,0.08);
          border: 2px solid #07C160;
          font-weight: bold;
        }

        .zfb-pay.active {
          background: rgba(0,170,238,0.08);
          border: 2px solid #00AAEE;
          font-weight: bold;
        }
      }
    }

    .agreement-wrap {
      width: 100%;
      font-size: 30px;
      line-height: 42px;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 32px 0;

      .select-icon {
        width: 40px;
        height: 40px;
      }
    }

    .form-pop-btn {
      width: 100%;
      height: 100px;
      background: #FD6C6C;
      border-radius: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: normal;
      margin: 32px 0;
    }

    .form-pop-btn-wrap {
      display: flex;
      align-items: center;
      height: 100px;
      width: 100%;
      margin: 32px 0;

      .symbol {
        font-size: 32px;
        font-weight: bold;
        color: #FD6C6C;
        line-height: 45px;
      }

      .price {
        font-size: 64px;
        font-weight: bold;
        color: #FD6C6C;
        line-height: 90px;
      }

      .price-btn {
        width: 500px;
        margin-left: auto;
      }
    }
  }
}
.landpage {
  position: relative;
  min-height: 100vh;
  // 底部固定栏
  &-bottom {
    position: fixed;
    bottom: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: top;
    z-index: 99;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    box-sizing: content-box;
    .left {
      color: #2B2D3B;
      padding-left: 32px;
      box-sizing: content-box;
    }
    &-paybtn {
      // position: absolute;
      // top: 0;
      width: 435px;
      height: 166px;
      text-align: center;
      background-repeat: no-repeat;
      background-size: contain;
      padding-bottom: env(safe-area-inset-bottom);
      box-sizing: content-box;
    }
  }
}
.landpage-bottom-paybtn {
  position: absolute;
}
</style>
