<template>
  <!-- 弹窗 -->
  <VanPopup
    class="payTypeDialog"
    v-model="config.show"
    round
    position="bottom"
    closeable
    close-icon="close"
    :style="{ height:'30%' }">
    <div class="title">请选择支付方式</div>
    <div class="icon-box">
      <img
        loading="lazy"
        @click="payTypeClick(item.key)"
        :src="item.img"
        :class="['img-box', { 'ml__130': index }]"
        v-for="(item, index) in iconList"
        :key="index" />
    </div>
  </VanPopup>
</template>
<script>
import { getSearch } from '@/utils/index'
export default {
  name: 'PayTypeDialog',
  props: {
    config: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      iconList: [
        // 图标列表
        {
          img: require('@/assets/aliPay.png'),
          key: '1'
        },
        {
          img: require('@/assets/wxPay.png'),
          key: '2'
        }
      ]
    }
  },
  watch: {
    'config.show': {
      handler (newVal) {
        if (newVal) {
          this.$log.show({
            current_page: location.href,
            module_ori: '支付方式选择',
            module_part: `106_tf_xinzeng_0010_${getSearch('id')}`,
            alternate: getSearch('alternate')
          })
        }
      }
    }
  },
  methods: {
    payTypeClick (key) {
      this.$emit('change', key)
      this.$emit('update:config', { show: false, payType: key })
    }
  }
}
</script>
<style lang="scss">
.payTypeDialog {
  .title {
    margin-top: 48px;
    font-size: 38px;
    font-weight: 600;
    color: #333333;
    text-align: center;
  }
  .icon-box {
    margin-top: 36px;
    text-align: center;
  }

  img {
    width: 200px;
    height: 200px;
  }
  .ml__130 {
    margin-left: 130px;
  }
}
</style>
