<template>
  <div
    class="landpage-formpay"
    v-if="formVisible"
    :style="{
      backgroundImage:'url(' + comFormBgUrl + ')',
      height: value.form_pay.form_bg.height / 75 + 'rem',
      width: value.form_pay.form_bg.width / 75 + 'rem',
      backgroundColor: value.form_pay.form_bg.bg_color,
      borderTopLeftRadius: value.form_pay.form_bg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: value.form_pay.form_bg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: value.form_pay.form_bg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: value.form_pay.form_bg.radius_visibility.bottom_right / 75 + 'rem'
    }">
    <VanField
      class="phone"
      :class="{'fail':isClick&&!validatePhone}"
      center
      ref="phone"
      :style="{
        width: value.form_pay.phone.width / 75 + 'rem',
        height: value.form_pay.phone.height / 75 + 'rem',
        backgroundImage:'url(' + comFormPhoneBg + ')',
        backgroundColor: value.form_pay.phone.bg_color,
        left: comPhoneLeft + 'rem',
        top: value.form_pay.phone.top / 75 + 'rem',
        fontWeight: comPhoneInputBold,
        borderTopLeftRadius: value.form_pay.phone.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: value.form_pay.phone.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: value.form_pay.phone.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: value.form_pay.phone.radius_visibility.bottom_right / 75 + 'rem'
      }"
      v-model="form.phone"
      type="tel"
      maxlength="11"
      :placeholder="value.form_pay.phone.placeholders.text"
      @blur="blurPhone">
      <template #label>
        <div class="left city-code" @click="selCityCode">
          <span>+{{ cityCode }}</span>
          <VanIcon name="arrow-down" v-if="value.form_pay.is_city_code" />
        </div>
      </template>
    </VanField>
    <VanField
      class="testCode"
      :class="{'fail':isClick&&!validateCode}"
      ref="testCode"
      v-model="form.testCode"
      center
      clearable
      type="digit"
      maxlength="4"
      :style="{
        left: comCodeLeft + 'rem',
        top: value.form_pay.test_code.top / 75 + 'rem',
        fontWeight: comTestCodeInputBold,
        borderTopLeftRadius: value.form_pay.test_code.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: value.form_pay.test_code.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: value.form_pay.test_code.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: value.form_pay.test_code.radius_visibility.bottom_right / 75 + 'rem',
        backgroundImage:'url(' + comFormTestCodeUrl + ')',
        backgroundColor: value.form_pay.test_code.bg_color,
        width: (value.form_pay.test_code.width + value.form_pay.get_code.width) / 75 + 'rem'
      }"
      :placeholder="value.form_pay.test_code.placeholders.text"
      @input="changeTestCode"
      @blur="blurTestCode">
      <template #button>
        <div
          class="landpage-formpay-getcode"
          :style="{
            backgroundColor: value.form_pay.get_code.bg_color,
            color: value.form_pay.get_code.placeholders.color,
            fontSize: value.form_pay.get_code.placeholders.font_size / 75 + 'rem',
            width: value.form_pay.get_code.width / 75 + 'rem',
            height: value.form_pay.get_code.height / 75 + 'rem',
            lineHeight: value.form_pay.get_code.height / 75 + 'rem',
            borderTopLeftRadius: value.form_pay.get_code.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: value.form_pay.get_code.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: value.form_pay.get_code.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: value.form_pay.get_code.radius_visibility.bottom_right / 75 + 'rem',
            fontWeight: comGetCodePlaceholderBold,
            fontStyle: comGetCodePlaceholderItalic,
            textDecoration: comGetCodePlaceholderDecoration
          }"
          @click="getCode">
          <span v-if="showGetCodeBtn">{{ getCodeBtnTxt }}</span>
          <!-- 验证码有效时间倒计时60s -->
          <VanCountDown
            v-else
            :style="{
              backgroundColor: value.form_pay.get_code.bg_color,
              color: value.form_pay.get_code.placeholders.color,
              fontSize: value.form_pay.get_code.placeholders.font_size / 75 + 'rem',
              width: value.form_pay.get_code.width / 75 + 'rem',
              height: value.form_pay.get_code.height / 75 + 'rem',
              lineHeight: value.form_pay.get_code.height / 75 + 'rem',
              borderTopLeftRadius: value.form_pay.get_code.radius_visibility.top_left / 75 + 'rem',
              borderTopRightRadius: value.form_pay.get_code.radius_visibility.top_right / 75 + 'rem',
              borderBottomLeftRadius: value.form_pay.get_code.radius_visibility.bottom_left / 75 + 'rem',
              borderBottomRightRadius: value.form_pay.get_code.radius_visibility.bottom_right / 75 + 'rem',
              fontWeight: comGetCodePlaceholderBold,
              fontStyle: comGetCodePlaceholderItalic,
              textDecoration: comGetCodePlaceholderDecoration,
              textAlign: 'center'
            }"
            ref="countDownGetCode"
            :auto-start="false"
            :time="codeTime"
            format="sss后获取"
            @finish="showGetCodeBtn=true" />
        </div>
      </template>
    </VanField>
    <div
      v-if="(value.form_pay.conceal_guard || {}).is_show"
      class="landpage-formpay-conceal-guard"
      :style="{
        ...comConcealGuardStyle,
        ...comConcealGuardPosi,
        top: value.form_pay.conceal_guard.top / 75 + 'rem',
        lineHeight: (value.form_pay.conceal_guard.input_text.font_size + 4) / 75 + 'rem',
        color: value.form_pay.conceal_guard.input_text.color,
        fontSize: value.form_pay.conceal_guard.input_text.font_size / 75 + 'rem'
      }">
      <VanCheckbox
        icon-size="16px"
        v-if="(value.form_pay.conceal_guard || {}).needSelect"
        v-model="form.checked"
        @change="checkCompact">已阅读并同意</VanCheckbox>
      <span @click="$emit('clickConcealGuard')">{{ value.form_pay.conceal_guard.input_text.text }}</span>
    </div>
    <div
      class="landpage-formpay-submit"
      :style="{
        backgroundImage:'url(' + comFormBtnUrl + ')',
        left: comSubmitLeft + 'rem',
        top: value.form_pay.submit.top / 75 + 'rem',
        width: value.form_pay.submit.width / 75 + 'rem',
        height: value.form_pay.submit.height / 75 + 'rem',
        backgroundColor: value.form_pay.submit.bg_color,
        lineHeight: value.form_pay.submit.height / 75 + 'rem',
        color: value.form_pay.submit.input_text.color,
        fontSize: value.form_pay.submit.input_text.font_size / 75 + 'rem',
        borderTopLeftRadius: value.form_pay.submit.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: value.form_pay.submit.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: value.form_pay.submit.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: value.form_pay.submit.radius_visibility.bottom_right / 75 + 'rem',
        fontWeight: comSubmitBold,
        fontStyle: comSubmitItalic,
        textDecoration: comSubmitDecoration
      }"
      v-debounce="1500"
      @click="submitClick">{{ value.form_pay.submit.input_text.text }}</div>
    <!-- 地区选择 -->
    <CityCode
      v-if="value.form_pay.is_city_code"
      ref="refCityCode"
      @getCityCode="getCityCode" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
/* eslint-disable camelcase */
import { getSearch, vertifyPhoneCode, isWeiXin } from '@/utils/index'
import { blur } from '@/mixins/blur'
import { CQ_CODE_TIME, PUBLIC_KEY } from '@/constant'
import CityCode from './CityCode.vue'
import JSEncrypt from 'jsencrypt'

export default {
  components: {
    CityCode
  },
  mixins: [blur],
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isCq: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isInWX: isWeiXin(),
      showGetCodeBtn: true, // 显示获取验证码code
      ...this.value.form_pay,
      getCodeBtnTxt: this.value.form_pay.get_code.placeholders.text || '获取验证码', // 获取验证码文案：[获取验证码|重新获取]
      isClick: false, // 是否提交
      isValidateCode: true,
      currentCityCode: {} // 当前区信息
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    codeTime () {
      return this.isCq ? CQ_CODE_TIME * 1000 : 60 * 1000
    },
    formVisible () {
      // 设置了隐藏表单
      if (this.value.form_pay.is_hide_form) {
        return false
      }
      return !this.isInWX || (this.value.form_pay.is_show && !this.value.form_pay.is_hidden_phone)
    },
    // 计算验证码表单提示文字下划线与删除线
    comGetCodePlaceholderDecoration () {
      if (this.value.form_pay.get_code.placeholders.style.includes('underline')) {
        return 'underline'
      } else if (this.value.form_pay.get_code.placeholders.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算验证码获取框斜体
    comGetCodePlaceholderItalic () {
      return this.value.form_pay.get_code.placeholders.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算验证码获取按钮框加粗
    comGetCodePlaceholderBold () {
      return this.value.form_pay.get_code.placeholders.style.includes('bold') ? 700 : 400
    },
    // 计算提交按钮文字下划线与删除线
    comSubmitDecoration () {
      if (this.value.form_pay.submit.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.value.form_pay.submit.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算提交按钮文字的倾斜程度
    comSubmitItalic () {
      return this.value.form_pay.submit.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算提交按钮的加粗程度
    comSubmitBold () {
      return this.value.form_pay.submit.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算获取验证码距左
    comCodeLeft () {
      switch (this.value.form_pay.test_code.text_align) {
        case 'left':
          return 0
        case 'center':
          return (750 - this.value.form_pay.test_code.width - this.value.form_pay.get_code.width) / 2 / 75
        case 'right':
          return (750 - this.value.form_pay.test_code.width - this.value.form_pay.get_code.width) / 75
        default:
          return this.value.form_pay.test_code.left / 75
      }
    },
    // 计算隐私协议等的font样式
    comConcealGuardStyle () {
      const styleObj = {}
      if (this.value.form_pay.conceal_guard.input_text.style.includes('underline')) {
        styleObj.textDecoration = 'underline'
      } else if (this.value.form_pay.conceal_guard.input_text.style.includes('lineThrough')) {
        styleObj.textDecoration = 'line-through'
      }
      styleObj.fontStyle = this.value.form_pay.conceal_guard.input_text.style.includes('italic') ? 'italic' : 'normal'
      styleObj.fontWeight = this.value.form_pay.conceal_guard.input_text.style.includes('bold') ? 700 : 400
      return styleObj
    },

    // 计算隐私按钮位置
    comConcealGuardPosi () {
      if (this.value.form_pay.conceal_guard.text_align === 'left') {
        return { left: 0 }
      } else if (this.value.form_pay.conceal_guard.text_align === 'center') {
        return { justifyContent: 'center', width: '100%' }
      } else if (this.value.form_pay.conceal_guard.text_align === 'right') {
        return { right: 0 }
      } else {
        return { right: this.value.form_pay.conceal_guard.right / 75 + 'rem' }
      }
    },
    // 计算提交按钮距左
    comSubmitLeft () {
      switch (this.value.form_pay.submit.text_align) {
        case 'left':
          return 0
        case 'center':
          return (750 - this.value.form_pay.submit.width) / 2 / 75
        case 'right':
          return (750 - this.value.form_pay.submit.width) / 75
        default:
          return this.value.form_pay.submit.left / 75
      }
    },
    // 计算验证码输入框
    comFormTestCodeUrl () {
      if (this.value.form_pay.test_code.url.length) {
        return this.value.form_pay.test_code.url[0].url
      } else {
        return ''
      }
    },

    // 计算表单input文字下划线与删除线
    comPhoneInputDecoration () {
      if (this.value.form_pay.phone.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.value.form_pay.phone.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算表单验证码输入文本下划线与删除线
    comTestCodeInputDecoration () {
      if (this.value.form_pay.test_code.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.value.form_pay.test_code.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算表单input文字斜体
    comPhoneInputItalic () {
      return this.value.form_pay.phone.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算验证码输入文本的样式
    comTestCodeInputItalic () {
      return this.value.form_pay.test_code.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算表单input文字加测
    comPhoneInputBold () {
      return this.value.form_pay.phone.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算表单验证码输入文本加粗
    comTestCodeInputBold () {
      return this.value.form_pay.test_code.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算表单手机号input框背景
    comFormPhoneBg () {
      if (this.value.form_pay.phone.url.length) {
        return this.value.form_pay.phone.url[0].url
      } else {
        return ''
      }
    },
    // 计算phone框的距中方式
    comPhoneLeft () {
      switch (this.value.form_pay.phone.text_align) {
        case 'left':
          return 0
        case 'center':
          return (750 - this.value.form_pay.phone.width) / 2 / 75
        case 'right':
          return (750 - this.value.form_pay.phone.width) / 75
        default:
          return this.value.form_pay.phone.left / 75
      }
    },
    // 计算表单背景
    comFormBgUrl () {
      if (this.value.form_pay.form_img_bg.length) {
        return this.value.form_pay.form_img_bg[0].url
      } else {
        return ''
      }
    },
    // 计算支付表单（抢购按钮图片）
    comFormBtnUrl () {
      if (this.value.form_pay.pay_img_btn.length) {
        return this.value.form_pay.pay_img_btn[0].url
      } else {
        return ''
      }
    },
    // 校验手机号
    validatePhone () {
      return this.form.phone && this.cityCodePreg.test(this.form.phone)
    },
    // 校验验证码
    validateCode () {
      return this.form.testCode && this.isValidateCode
    },
    // 当前选择手机号地区code
    cityCode () {
      return this.currentCityCode?.area_code || 86
    },
    // 当前地区手机号验证正则
    cityCodePreg () {
      const regexString = this.currentCityCode?.preg ? this.currentCityCode?.preg.slice(1, -1) : '' // 去除前后斜杠
      const regex = regexString || /^1[3456789]\d{9}$/
      return new RegExp(regex)
    }
  },
  mounted () {
    if (this.is_hide_form) {
      return
    }
    const dom = document.querySelector('.phone .van-field__control')
    dom.style.color = this.phone.input_text.color
    dom.style.fontSize = this.phone.input_text.font_size / 75 + 'rem'
    dom.style.textDecoration = this.comPhoneInputDecoration
    dom.style.fontStyle = this.comPhoneInputItalic
    const domCode = document.querySelector('.testCode .van-field__control')
    domCode.style.color = this.test_code.input_text.color
    domCode.style.fontSize = this.test_code.input_text.font_size / 75 + 'rem'
    domCode.style.textDecoration = this.comTestCodeInputDecoration
    domCode.style.fontStyle = this.comTestCodeInputItalic
  },
  methods: {
    // 获取验证码
    async getCode () {
      // 倒计时中｜手机号格式错误无法获取
      if (!this.showGetCodeBtn || !vertifyPhoneCode({ form: this.form, justifyCode: false, cityCodePreg: this.cityCodePreg })) return
      this.$log.click({
        current_page: location.href,
        module_ori: '表单提交模块',
        module_part: `106_luodiyehoutai_lcl_0001_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      await this.$apis.landPage.getcodeWithSite({
        ...getSearch(),
        phone: this.encryptPhone(this.form.phone),
        product_sn: this.value?.product_sn_key,
        area_code: this.cityCode
      }).then((res) => {
        this.$toast({
          message: '验证码发送成功'
        })
        this.$log.click({
          current_page: location.href,
          module_ori: '手机号、验证码表单',
          module_part: `106_tf_xinzeng_0008_${getSearch('id')}`,
          alternate: JSON.stringify({
            ...JSON.parse(getSearch('alternate')),
            send_id: res.sid,
            page_id: getSearch('id')
          })
        })
        this.showGetCodeBtn = false
        setTimeout(() => {
          this.getCodeBtnTxt = '重新获取'
          this.$refs.countDownGetCode.start()
        }, 0)
      })
    },
    submitClick () {
      this.$log.update({ mid: this.userInfo?.mid || 0 })
      this.validateForm()
      this.$log.click({
        current_page: window.location.href,
        module_ori: '表单提交模块',
        module_part: `106_luodiyehoutai_lcl_0002_${getSearch('id')}`,
        alternate: {
          ...JSON.parse(getSearch('alternate', 4)),
          tourist_id: window.tourist_id ? window.tourist_id : ''
        }
      })
      this.$emit('formsubmit')
    },
    // 验证码校验不通过
    noValidateCode (val) {
      this.isValidateCode = val
    },
    // 提交
    validateForm () {
      this.isClick = true
    },
    // 监听验证码输入
    changeTestCode (e) {
      if (e && e.length === 4) {
        this.$refs.testCode.blur()
        this.submitClick()
      }
    },
    // 区号选择
    selCityCode () {
      if (!this.value.form_pay.is_city_code) return
      this.$refs.refCityCode.showPicker = true
    },
    // 获取区号
    getCityCode (val) {
      this.currentCityCode = val
    },
    // 手机号加密
    encryptPhone (phone) {
      if (!phone) return ''
      // 实例化 JSEncrypt 对象
      const encryptor = new JSEncrypt()
      // 设置公钥
      encryptor.setPublicKey(PUBLIC_KEY)
      // 使用公钥对数据进行加密
      return encryptor.encrypt(phone)
    }
  }
}
</script>
<style lang="scss" scoped>
.landpage {
  // form区域
  &-formpay {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    /deep/ .van-field {
      padding: 0;
      padding-left: 40px;
    }
    /deep/ .van-field::after {
      border-bottom: 0 none;
    }
    /deep/ .van-field__label {
      color: #636363;
      width: 80px;
    }
    .phone {
      position: absolute;
      .left {
        font-size: 32px;
        font-weight: 500;
        color: #636363;
      }
      /deep/ .van-field__control::-webkit-input-placeholder {
        font-size: 32px;
        font-weight: 400;
        color: #9d9d9d;
      }
      &.fail {
        border: 2px solid #f00;
      }
      /deep/ .van-field__control {
        padding-left: 10px;
      }
      .city-code {
        display: flex;
        align-items: center;

        .van-icon {
          margin-left: 2px;
        }
      }
    }
    .testCode {
      position: absolute;
      /deep/ .van-field__control::-webkit-input-placeholder {
        font-size: 32px;
        font-weight: 400;
        color: #9d9d9d;
      }
      &.fail {
        border: 2px solid #f00;
      }
    }
    &-getcode {
      text-align: center;
      border: 0 none;
      color: #9d9d9d;
    }
    &-conceal-guard {
      position: absolute;
      display: flex;
      align-items: center;
    }
    &-submit {
      @include horVerCenter;
      position: absolute;
      background-size: contain;
    }
  }
}
</style>
