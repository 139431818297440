<template>
  <VanPopup
    class="form-pop"
    v-model="visible"
    position="bottom"
    closeable
    :style="{
      width: setFormBg.width / 75 + 'rem',
      backgroundImage: 'url(' + comFormBgUrl + ')',
      height: setFormBg.height / 75 + 'rem',
      backgroundColor: setFormBg.bg_color,
      borderTopLeftRadius: setFormBg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: setFormBg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: setFormBg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: setFormBg.radius_visibility.bottom_right / 75 + 'rem',
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden'
    }">
    <div class="address-box">
      <div class="title">收货地址</div>
      <div class="address-list">
        <div
          class="list-name"
          :style="{
            lineHeight: userInput.height / 75 + 'rem',
            fontSize: userInput.input_text.font_size / 75 + 'rem',
          }">收货人</div>
        <div
          class="list-inp"
          :style="{
            width: userInput.width / 75 + 'rem',
            height: userInput.height / 75 + 'rem',
            lineHeight: userInput.height / 75 + 'rem',
            backgroundColor: userInput.bg_color,
            fontSize: userInput.input_text.font_size / 75 + 'rem',
            color: userInput.input_text.color,
            left: comFormFLeft('user_input') + 'rem',
            top: userInput.top / 75 + 'rem',
            backgroundImage:'url(' + userInputBgUrl + ')',
            borderTopLeftRadius: userInput.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: userInput.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: userInput.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: userInput.radius_visibility.bottom_right / 75 + 'rem'
          }">
          <VanField
            maxlength="20"
            v-model="address_info.user_name"
            :placeholder="userInput.input_text.text"
            :style="{
              fontSize: userInput.input_text.font_size / 75 + 'rem',
              color: userInput.input_text.color,
            }" />
        </div>
      </div>
      <div class="address-list">
        <div
          class="list-name"
          :style="{
            lineHeight: phoneInput.height / 75 + 'rem',
            fontSize: phoneInput.input_text.font_size / 75 + 'rem',
          }">手机号码</div>
        <div
          class="list-inp"
          :style="{
            width: phoneInput.width / 75 + 'rem',
            height: phoneInput.height / 75 + 'rem',
            lineHeight: phoneInput.height / 75 + 'rem',
            backgroundColor: phoneInput.bg_color,
            fontSize: phoneInput.input_text.font_size / 75 + 'rem',
            color: phoneInput.input_text.color,
            left: comFormFLeft('phone_input') + 'rem',
            top: phoneInput.top / 75 + 'rem',
            backgroundImage:'url(' + phoneInputBgUrl + ')',
            borderTopLeftRadius: phoneInput.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: phoneInput.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: phoneInput.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: phoneInput.radius_visibility.bottom_right / 75 + 'rem'
          }">
          <VanField
            maxlength="11"
            v-model="address_info.mobile"
            :placeholder="phoneInput.input_text.text"
            type="tel"
            :style="{
              fontSize: phoneInput.input_text.font_size / 75 + 'rem',
              color: phoneInput.input_text.color,
            }" />
        </div>
      </div>
      <div class="address-list">
        <div
          class="list-name"
          :style="{
            lineHeight: areaSelect.height / 75 + 'rem',
            fontSize: areaSelect.input_text.font_size / 75 + 'rem',
          }">所在地区</div>
        <div
          @click="handelSelArea"
          class="list-inp sel"
          :style="{
            width: areaSelect.width / 75 + 'rem',
            height: areaSelect.height / 75 + 'rem',
            lineHeight: areaSelect.height / 75 + 'rem',
            backgroundColor: areaSelect.bg_color,
            fontSize: areaSelect.input_text.font_size / 75 + 'rem',
            color: areaSelect.input_text.color,
            left: comFormFLeft('area_select') + 'rem',
            top: areaSelect.top / 75 + 'rem',
            backgroundImage:'url(' + areaSelectBgUrl + ')',
            borderTopLeftRadius: areaSelect.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: areaSelect.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: areaSelect.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: areaSelect.radius_visibility.bottom_right / 75 + 'rem'
          }">
          <template v-if="selAreaValue">
            <span
              class="sel-active"
              :style="{
                fontSize: areaSelect.input_text.font_size / 75 + 'rem',
                color: areaSelect.input_text.color,
              }">{{ selAreaValue }}</span>
          </template>
          <template v-else>
            <span class="sel-place">{{ areaSelect.input_text.text }}</span>
            <span class="icon"><VanIcon name="arrow" /></span>
          </template>
        </div>
      </div>
      <div class="address-list">
        <div
          class="list-name"
          :style="{
            lineHeight: phoneInput.height / 75 + 'rem',
            fontSize: addressInput.input_text.font_size / 75 + 'rem',
          }">详细地址</div>
        <div
          class="list-inp"
          :style="{
            width: addressInput.width / 75 + 'rem',
            height: addressInput.height / 75 + 'rem',
            lineHeight: addressInput.height / 75 + 'rem',
            backgroundColor: addressInput.bg_color,
            fontSize: addressInput.input_text.font_size / 75 + 'rem',
            color: addressInput.input_text.color,
            left: comFormFLeft('address_input') + 'rem',
            top: addressInput.top / 75 + 'rem',
            backgroundImage:'url(' + addressInputBgUrl + ')',
            borderTopLeftRadius: addressInput.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: addressInput.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: addressInput.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: addressInput.radius_visibility.bottom_right / 75 + 'rem'
          }">
          <VanField
            maxlength="100"
            v-model="address_info.address"
            rows="1"
            type="textarea"
            :placeholder="addressInput.input_text.text"
            :style="{
              fontSize: addressInput.input_text.font_size / 75 + 'rem',
              color: addressInput.input_text.color,
            }" />
        </div>
        <div
          :style="{
            width: addressInput.width / 75 + 'rem',
            height: addressInput.height / 75 + 'rem',
          }"></div>
      </div>
      <!-- 商品信息 -->
      <div class="shop-data">
        <div class="shop-list">
          <div class="shop-name">商品金额</div>
          <div class="shop-text">￥{{ shopPrice }}</div>
        </div>
        <div class="shop-list">
          <div class="shop-name">运费</div>
          <div class="shop-text">免运费</div>
        </div>
        <div class="shop-list">
          <div class="shop-name">应付金额</div>
          <div class="shop-text">￥{{ shopPrice }}</div>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btn-box">
        <div
          class="btn"
          @click="handleSaveCheck"
          :style="{
            left: comFormFLeft('submit') + 'rem',
            top: submit.top / 75 + 'rem',
            width: submit.width / 75 + 'rem',
            height: submit.height / 75 + 'rem',
            backgroundImage:'url(' + submitBgUrl + ')',
            backgroundColor: submit.bg_color,
            lineHeight: submit.height / 75 + 'rem',
            color: submit.input_text.color,
            fontSize: submit.input_text.font_size / 75 + 'rem',
            borderTopLeftRadius: submit.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: submit.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: submit.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: submit.radius_visibility.bottom_right / 75 + 'rem',
            fontWeight: submitBold,
            fontStyle: submitItalic,
            textDecoration: submitDecoration
          }">{{ submit.input_text.text }}</div>
      </div>
    </div>
    <AreaSelect
      :title="areaSelect.input_text.text"
      @confirm="confirm"
      ref="areaRef" />
  </VanPopup>
</template>

<script>
import Vue from 'vue'
import { Icon, Toast } from 'vant'
import AreaSelect from '@/components/AreaSelect.vue'

Vue.use(Icon)

export default {
  name: 'Address',
  components: {
    AreaSelect
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      visible: false,
      address_info: {
        user_name: '',
        mobile: '',
        province_name: '',
        city_name: '',
        county_name: '',
        address: ''
      }
    }
  },
  computed: {
    selAreaValue () {
      if (this.address_info.province_name && this.address_info.city_name && this.address_info.county_name) {
        return this.address_info.province_name + ',' + this.address_info.city_name + ',' + this.address_info.county_name
      }
      return ''
    },
    address () {
      return this.value.address
    },
    // 背景
    setFormBg () {
      return this.address.form_bg
    },
    comFormBgUrl () {
      return this.setFormBg.img_list.length ? this.setFormBg.img_list[0].url : ''
    },
    // 用户
    userInput () {
      return this.address.user_input
    },
    userInputBgUrl () {
      return this.userInput.img_list.length ? this.userInput.img_list[0].url : ''
    },
    // 手机号
    phoneInput () {
      return this.address.phone_input
    },
    phoneInputBgUrl () {
      return this.phoneInput.img_list.length ? this.phoneInput.img_list[0].url : ''
    },
    // 省市区
    areaSelect () {
      return this.address.area_select
    },
    areaSelectBgUrl () {
      return this.areaSelect.img_list.length ? this.areaSelect.img_list[0].url : ''
    },
    // 详细地址
    addressInput () {
      return this.address.address_input
    },
    addressInputBgUrl () {
      return this.addressInput.img_list.length ? this.addressInput.img_list[0].url : ''
    },
    // 提交按钮
    submit () {
      return this.address.submit
    },
    submitBgUrl () {
      return this.submit.img_list.length ? this.submit.img_list[0].url : ''
    },
    // 计算提交按钮的加粗程度
    submitBold () {
      return this.submit.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算提交按钮文字的倾斜程度
    submitItalic () {
      return this.submit.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算提交按钮文字下划线与删除线
    submitDecoration () {
      if (this.submit.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.submit.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算输入框的距中方式
    comFormFLeft () {
      return (type) => {
        switch (this.address[type].text_align) {
          case 'left':
            return 0
          case 'center':
            return (750 - 64 - this.address[type].width) / 2 / 75
          case 'right':
            return (750 - 64 - this.address[type].width) / 75
          default:
            return this.address[type].x / 75
        }
      }
    },
    // 商品金额
    shopPrice () {
      return this.value.goods_price
    }
  },
  methods: {
    handelSelArea () {
      this.$refs.areaRef.visible = true
    },
    confirm (val) {
      this.address_info.province_name = val[0]?.name || ''
      this.address_info.city_name = val[1]?.name || ''
      this.address_info.county_name = val[2]?.name || ''
    },
    handleSaveCheck () {
      const REG_MAP = {
        user_name: {
          reg: /\S/,
          message: '请输入收货人姓名'
        },
        mobile: {
          reg: /^1[3456789]\d{9}$/,
          message: '请输入手机号码'
        },
        province_name: {
          reg: /\S/,
          message: '请选择所在地区'
        },
        city_name: {
          reg: /\S/,
          message: '请选择所在地区'
        },
        county_name: {
          reg: /\S/,
          message: '请选择所在地区'
        },
        address: {
          reg: /\S/,
          message: '请输入详细地址'
        }
      }
      let allChecked = true
      Object.keys(this.address_info).forEach(key => {
        // console.log(REG_MAP[key])
        // console.log(REG_MAP[key].reg.test(this.address_info[key]))
        if (!REG_MAP[key].reg.test(this.address_info[key])) {
          Toast(REG_MAP[key].message)
          allChecked = false
          throw new Error(REG_MAP[key].message)
        }
      })
      // 如果全部通过，调用接口
      if (allChecked) {
        console.log('全部通过', allChecked)
        this.$emit('saveAddressInfo', this.address_info)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-pop {
  position: fixed;
  bottom: 0;
}

.address-box {
  position: relative;
  padding: 0 32px;

  .title {
    font-size: 48px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    line-height: 67px;
    padding: 40px 0;
  }

  .address-list {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;

    .list-name {
      width: 130px;
      font-size: 16px;
      color: #000;
      margin-right: 10px;
      white-space: nowrap;
    }

    .list-inp {
      position: absolute;
      box-sizing: border-box;

      .van-cell {
        width: 100%;
        height: 100%;
        background: none;
      }

      /deep/ .van-field__body {
        height: 100% !important;

        /deep/ input {
          height: 100%;
        }
      }

      /deep/ .van-field__control,
      /deep/ .van-cell__value {
        color: inherit;
      }
    }

    .sel {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .sel-place,
      .sel-active {
        padding: 0 32px;
      }

      .sel-place {
        color: #c8c9cc;
      }

      .icon {
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);
        color: #cccccc;
      }
    }

    .area {
      height: 100px;
      padding-top: 12px;
    }
  }

  .shop-data {
    margin-top: 16px;

    .shop-list {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .shop-name {
        width: 144px;
        line-height: 80px;
        font-size: 32px;
        color: #000;
        margin-right: 20px;
        white-space: nowrap;
      }

      .shop-text {
        font-size: 32px;
        font-weight: 600;
        text-align: right;
      }
    }
  }

  .btn-box {
    width: 100%;
    position: relative;
    margin-top: 80px;

    .btn {
      position: absolute;
      text-align: center;
    }
  }
}
</style>
