export default {
  namespaced: true,
  state: {
    introduction: {}, // 问卷引言
    questionList: [], // 题目
    saveList: [] // 答案
  },
  mutations: {
    SET_QUESTION_LIST (state, payload) {
      state.questionList = payload
    },
    SET_SAVE_LIST (state, payload) {
      state.saveList = payload
    },
    set_INTRODUCTION (state, payload) {
      state.introduction = payload
    }
  },
  actions: {
    /**
     * @description 获取题目
     */
    async getAnswerList ({ commit }, qyery) {
      try {
        const { introduction, question, answer } = await this.$apis.questionnaire.getQuestionV2(qyery)
        commit('set_INTRODUCTION', introduction)
        commit('SET_QUESTION_LIST', question)
        commit('SET_SAVE_LIST', answer.answer)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     *  @description 更新题目答案
     */
    async changeSaveList ({ commit, rootState }, query) {
      try {
        const { title_id: titleId, content, type } = query
        const { saveList } = rootState.questionnaire
        let _saveList = saveList
        const changeSavePam = {
          title_id: titleId,
          type,
          content
        }
        if (saveList[titleId - 1]) {
          _saveList[titleId - 1] = changeSavePam
        } else {
          _saveList = [..._saveList, changeSavePam]
        }
        commit('SET_SAVE_LIST', _saveList)
      } catch (error) {
        console.log(error, '修改答案失败err')
      }
    },
    /**
     *  @description 更新题目列表  添加子问题
     */
    async changeQuestionList ({ commit, rootState }, query) {
      try {
        const { currentNum, question } = query
        const { questionList } = rootState.questionnaire
        const _questionList = questionList
        _questionList.splice(currentNum, 0, ...question)
        commit('SET_QUESTION_LIST', _questionList)
      } catch (error) {
        console.log(error, '更新题目列表失败err')
      }
    }
  }
}
