import { UAParser } from 'ua-parser-js'
import { IPHONE_DEVICES } from '@/constant/device'

class Device {
  static ua = null
  static uap = null
  static iphoneModel = null

  constructor () {
    const ua = window.navigator.userAgent
    this.ua = ua
    this.uap = UAParser(ua)
  }

  /**
   * 获取手机像素点
   * @returns 手机像素点
   */
  _getResolution () {
    const ratio = window.devicePixelRatio || 1
    return (Math.min(screen.width, screen.height) * ratio) +
        'x' + (Math.max(screen.width, screen.height) * ratio)
  }

  /**
   * 获取 iphone 手机型号
   * @returns 返回 iphone 手机型号，不完全准确
   */
  _iphoneModel () {
    if (!this.iphoneModel) {
      const res = this._getResolution()

      this.iphoneModel = [this.uap.device.model]

      for (let i = 0; i < IPHONE_DEVICES.length; i++) {
        const device = IPHONE_DEVICES[i]
        if (res === device[0]) {
          this.iphoneModel = device[1]
          break
        }
      }
    }

    return this.iphoneModel[0]
  }

  /**
   * 判断主流手机品牌，包括：苹果、华为、荣耀、三星、oppo、vivo、小米、红米
   * @returns 主流手机品牌 or 空字符串
   */
  // _judgeBrand () {
  //   const _ua = this.ua.toLowerCase()
  //   const isIphone = _ua.match(/iphone/i)?.includes('iphone')
  //   const isHuawei = _ua.match(/huawei/i)?.includes('huawei')
  //   const isHonor = _ua.match(/honor/i)?.includes('honor')
  //   const isOppo = _ua.match(/oppo/i)?.includes('oppo')
  //   const isOppoR15 = _ua.match(/pacm00/i)?.includes('pacm00')
  //   const isVivo = _ua.match(/vivo/i)?.includes('vivo')
  //   const isXiaomi = _ua.match(/mi\s/i)?.includes('mi ')
  //   const isXiaomi2s = _ua.match(/mix\s/i)?.includes('mix ')
  //   const isRedmi = _ua.match(/redmi/i)?.includes('redmi')
  //   const isSamsung = _ua.match(/sm-/i)?.includes('sm-')

  //   if (isIphone) {
  //     return 'iphone'
  //   } else if (isHuawei) {
  //     return 'huawei'
  //   } else if (isHonor) {
  //     return 'honor'
  //   } else if (isOppo || isOppoR15) {
  //     return 'oppo'
  //   } else if (isVivo) {
  //     return 'vivo'
  //   } else if (isXiaomi || isRedmi || isXiaomi2s) {
  //     return 'xiaomi'
  //   } else if (isSamsung) {
  //     return 'samsung'
  //   } else {
  //     return ''
  //   }
  // }

  /**
   * 获取操作系统
   * @returns 操作系统 + 版本号
   */
  getOs () {
    return this.uap ? `${this.uap.os.name} ${this.uap.os.version}` : '未知'
  }

  /**
   * 获取操作系统
   * @returns 操作系统名称
   */
  getOsName () {
    return this.uap ? `${this.uap.os.name}` : '未知'
  }

  /**
   * 获取设备品牌&型号
   * @returns 设备品牌&型号
   */
  getBrandModel () {
    console.log('======设备信息======\n', this.uap)
    if (this.uap.os.name === 'iOS') {
      return this._iphoneModel()
    }

    return this.uap ? this.uap.device.model : '未知'
  }

  /**
   * 获取系统厂商
   * @returns 系统厂商
   */
  getVendor () {
    return this.uap ? this.uap.device.vendor : '未知'
  }

  /**
   * 获取浏览器信息
   * @returns 浏览器信息
   */
  getBrowser () {
    return this.uap ? `${this.uap.browser.name} ${this.uap.browser.version}` : '未知'
  }
}

export const DC = new Device()
