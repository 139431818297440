import { setDocumentTitle, getQuery } from '@/utils'
import Store from '@/store'

export default router => {
  router.beforeEach(async (to, from, next) => {
    // APP内部调用
    if (getQuery('platform') === 'app') {
      // H5获取App的设备id
      await new Promise(resolve => {
        const timer = setTimeout(() => {
          resolve()
        }, 3000)
        window.WeiMiaoJSBridge.emit('get_tourist_id')
        window.WeiMiaoJSBridge.on('get_tourist_id', res => {
          window.tourist_id = res
          console.log('tourist_id', window.tourist_id)
          resolve()
          clearTimeout(timer)
        })
      })
    }
    const { origin, search } = location
    const path = `${origin}/${search}#${from.path}`
    Store.$log.update({ pre_page: path })
    // 防止设置标题重复请求
    if (window.location.href.indexOf('replace_title') !== -1) {
      return
    }
    // 设置标题
    const title = to.meta.title || '打开中...'
    setDocumentTitle(title)
    // 页面默认滚动到顶部
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0
    }
    // mid在微信外的埋点没有，跟大数据同学对的，给一个默认0
    Store.$log.update({ mid: 0 })
    next()
  })
}
