
import { getSearch } from '@/utils/index'
export const blur = {
  methods: {
    // 丢失焦点
    blurPhone (e) {
      if (!this.form.phone) {
        this.$log.click({
          current_page: location.href,
          module_ori: '手机号、验证码表单',
          module_part: `106_tf_xinzeng_0002_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      } else if (!this.validatePhone) {
        this.$log.click({
          current_page: location.href,
          module_ori: '手机号、验证码表单',
          module_part: `106_tf_xinzeng_0003_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      }
      this.$log.click({
        current_page: location.href,
        module_ori: '手机号、验证码表单',
        module_part: `106_tf_xinzeng_0001_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
    },
    blurTestCode (e) {
      if (!this.form.testCode) {
        this.$log.click({
          current_page: location.href,
          module_ori: '手机号、验证码表单',
          module_part: `106_tf_xinzeng_0005_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      } else if (this.form.testCode.length !== 4) {
        this.$log.click({
          current_page: location.href,
          module_ori: '手机号、验证码表单',
          module_part: `106_tf_xinzeng_0006_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      }
      this.$log.click({
        current_page: location.href,
        module_ori: '手机号、验证码表单',
        module_part: `106_tf_xinzeng_0004_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
    },
    checkCompact (e) {
      if (e) {
        this.$log.click({
          current_page: location.href,
          module_ori: '协议勾选查看',
          module_part: `106_tf_xinzeng_0007_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      }
    }
  }
}
