var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VanDialog',{staticClass:"detentionDialog",style:({
      backgroundImage:'url(' + _vm.comDetentionBg + ')',
      backgroundSize: 'contain',
      height: _vm.detent_bg.height / 75 + 'rem',
      width: _vm.detent_bg.width / 75 + 'rem',
      backgroundColor: _vm.detent_bg.bg_color,
      borderTopLeftRadius: _vm.detent_bg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: _vm.detent_bg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: _vm.detent_bg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: _vm.detent_bg.radius_visibility.bottom_right / 75 + 'rem'
    }),attrs:{"show-confirm-button":false,"close-on-click-overlay":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('img',{staticClass:"close-btn",attrs:{"src":require("@/assets/icons/close-btn-circle.png")},on:{"click":_vm.close}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }