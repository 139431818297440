var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.jumptoMiniappShow)?_c('div',{staticClass:"jumpto-box",style:({
    top: _vm.jumptoMiniapp.company.top + '%',
    right:_vm.jumptoMiniapp.company.right / 75 + 'rem',
    marginTop: -(_vm.jumptoBtn.height / 2) / 75 + 'rem',
    textAlign: _vm.jumptoMiniapp.company.text_align,
    width: _vm.jumptoBtn.width / 75 + 'rem',
    height: _vm.jumptoBtn.height / 75 + 'rem',
    backgroundColor: _vm.jumptoBtn.bg_color,
    borderTopLeftRadius: _vm.jumptoBtn.radius_visibility.top_left / 75 + 'rem',
    borderTopRightRadius: _vm.jumptoBtn.radius_visibility.top_right / 75 + 'rem',
    borderBottomLeftRadius: _vm.jumptoBtn.radius_visibility.bottom_left / 75 + 'rem',
    borderBottomRightRadius: _vm.jumptoBtn.radius_visibility.bottom_right / 75 + 'rem'
  }),on:{"click":_vm.jumptoPage}},[_c('img',{attrs:{"src":_vm.jumptoBtn.jumpto_btn_img[0].url,"alt":""}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }