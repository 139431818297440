var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value.ICP_record.is_show)?_c('div',{staticClass:"ICPRecord",style:({
    backgroundImage:'url(' + _vm.comICPBgUrl + ')',
    height: _vm.value.ICP_record.ICP_bg.height / 75 + 'rem',
    width: _vm.value.ICP_record.ICP_bg.width / 75 + 'rem',
    backgroundColor: _vm.value.ICP_record.ICP_bg.bg_color,
    borderTopLeftRadius: _vm.value.ICP_record.ICP_bg.radius_visibility.top_left / 75 + 'rem',
    borderTopRightRadius: _vm.value.ICP_record.ICP_bg.radius_visibility.top_right / 75 + 'rem',
    borderBottomLeftRadius: _vm.value.ICP_record.ICP_bg.radius_visibility.bottom_left / 75 + 'rem',
    borderBottomRightRadius: _vm.value.ICP_record.ICP_bg.radius_visibility.bottom_right / 75 + 'rem'
  })},[_c('div',{staticClass:"ICPRecord-company cont",style:({
      ..._vm.comComponyPosi,
      ..._vm.comComponyStyle,
      lineHeight: _vm.value.ICP_record.company.input_text.font_size / 75 + 'rem',
      marginTop: _vm.value.ICP_record.company.top / 75 + 'rem',
      color: _vm.value.ICP_record.company.input_text.color,
      fontSize: _vm.value.ICP_record.company.input_text.font_size / 75 + 'rem'
    })},[_vm._v(_vm._s(_vm.value.ICP_record.company.input_text.text))]),_c('div',{staticClass:"ICPRecord-ICPNumber cont",style:({
      ..._vm.comICPNumberPosi,
      ..._vm.comICPNumberStyle,
      lineHeight: _vm.value.ICP_record.ICP_number.input_text.font_size / 75 + 'rem',
      marginTop: _vm.value.ICP_record.ICP_number.top / 75 + 'rem',
      color: _vm.value.ICP_record.ICP_number.input_text.color,
      fontSize: _vm.value.ICP_record.ICP_number.input_text.font_size / 75 + 'rem'
    })},[_vm._v(_vm._s(_vm.value.ICP_record.ICP_number.input_text.text))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }