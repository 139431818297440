<template>
  <div>
    <!--  -->
    <VanDialog
      class="detentionDialog"
      v-model="value.showDialog"
      :show-confirm-button="false"
      :style="{
        backgroundImage:'url(' + comDetentionBg + ')',
        backgroundSize: 'contain',
        height: detent_bg.height / 75 + 'rem',
        width: detent_bg.width / 75 + 'rem',
        backgroundColor: detent_bg.bg_color,
        borderTopLeftRadius: detent_bg.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: detent_bg.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: detent_bg.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: detent_bg.radius_visibility.bottom_right / 75 + 'rem'
      }">
      <div
        class="detentionDialog-title"
        :style="{
          height: title.height / 75 + 'rem',
          textAlign: title.text_align,
          top: title.top / 75 + 'rem',
          color: title.color,
          fontSize: title.font_size / 75 + 'rem',
          fontWeight: comTitleBold,
          fontStyle: comTitleItalic,
          textDecoration: comTitleDecoration,
        }">{{ detention_copy }}</div>
      <div class="price" v-if="centerCouponInfo && centerCouponInfo.rule">
        {{ centerCouponInfo.rule.discount }}
        <div class="yuan"></div>
      </div>
      <div
        class="detentionDialog-btns"
        :style="{
          top: abandon.top / 75 + 'rem',
          height: confirm.height / 75 + 'rem'
        }">
        <div
          class="detentionDialog-btns-cancel"
          @click="cancelDialog"
          :style="{
            height: abandon.height / 75 + 'rem',
            width: abandon.width / 75 + 'rem',
            lineHeight: abandon.height / 75 + 'rem',
            backgroundColor: abandon.bg_color,
            color: abandon.input_text.color,
            fontSize: abandon.input_text.font_size / 75 + 'rem',
            borderTopLeftRadius: abandon.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: abandon.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: abandon.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: abandon.radius_visibility.bottom_right / 75 + 'rem',
            backgroundImage:'url(' + comAbandonUrl + ')',
            left: comAnandonLeft + 'rem',
            fontWeight: comAbandonBold,
            fontStyle: comAbandonItalic,
            textDecoration: comAbandonDecoration,
          }">{{ abandon_btn_copy }}</div>
        <div
          class="detentionDialog-btns-confirm"
          @click="detentionPay"
          :style="{
            height: confirm.height / 75 + 'rem',
            width: confirm.width / 75 + 'rem',
            lineHeight: abandon.height / 75 + 'rem',
            backgroundColor: confirm.bg_color,
            color: confirm.input_text.color,
            fontSize: confirm.input_text.font_size / 75 + 'rem',
            borderTopLeftRadius: confirm.radius_visibility.top_left / 75 + 'rem',
            borderTopRightRadius: confirm.radius_visibility.top_right / 75 + 'rem',
            borderBottomLeftRadius: confirm.radius_visibility.bottom_left / 75 + 'rem',
            borderBottomRightRadius: confirm.radius_visibility.bottom_right / 75 + 'rem',
            backgroundImage:'url(' + comConfirmUrl + ')',
            fontWeight: comConfirmBold,
            fontStyle: comConfirmItalic,
            textDecoration: comConfirmDecoration,
            left: comConfirmLeft + 'rem'
          }">{{ pay_btn_copy }}</div>
      </div>
    </VanDialog>
  </div>
</template>
<script>
import { getSearch } from '@/utils/index'
/* eslint-disable camelcase */
export default {
  name: 'DetentionDialog',
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    centerCouponInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      ...this.value.detention_dialog
    }
  },
  computed: {
    // 计算放弃按钮的斜体
    comAbandonItalic () {
      return this.abandon.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算抛弃按钮的背景图
    comAbandonUrl () {
      return this.abandon.url.length ? this.abandon.url[0].url : ''
    },
    // 计算放弃按钮加粗
    comAbandonBold () {
      return this.abandon.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算放弃按钮下划线与删除线
    comAbandonDecoration () {
      if (this.abandon.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.abandon.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算放弃按钮距左侧距离
    comAnandonLeft () {
      switch (this.abandon.text_align) {
        case 'left':
          return 0
        case 'center':
          return (this.detent_bg.width - this.abandon.width) / 2 / 75
        case 'right':
          return (this.detent_bg.width - this.abandon.width) / 75
        default:
          return this.abandon.left / 75
      }
    },
    // 计算标题倾斜
    comTitleItalic () {
      return this.title.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算标题下划线
    comTitleDecoration () {
      if (this.title.style.includes('underline')) {
        return 'underline'
      } else if (this.title.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算标题加粗
    comTitleBold () {
      return this.title.style.includes('bold') ? 700 : 400
    },
    // 计算挽留弹窗背景
    comDetentionBg () {
      return this.detention_bg.length ? this.detention_bg[0].url : ''
    },
    // 计算确认按钮加粗
    comConfirmBold () {
      return this.confirm.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算确认倾斜
    comConfirmItalic () {
      return this.confirm.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算确认按钮背景
    comConfirmUrl () {
      return this.confirm.url.length ? this.confirm.url[0].url : ''
    },
    // 计算支付按钮距左侧距离
    comConfirmLeft () {
      switch (this.confirm.text_align) {
        case 'left':
          return 0
        case 'center':
          return (this.detent_bg.width - this.confirm.width) / 2 / 75
        case 'right':
          return (this.detent_bg.width - this.confirm.width) / 75
        default:
          return this.confirm.left / 75
      }
    },
    // 计算确认按钮删除与下划线
    comConfirmDecoration () {
      if (this.confirm.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.confirm.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    }
  },
  watch: {
    'value.showDialog': {
      handler (newVal) {
        if (newVal) {
          const { orderNum } = this.value
          console.log('orderNum', orderNum)
          this.$log.show({
            current_page: window.location.href,
            module_ori: '挽留弹窗',
            module_part: `106_tf_xinzeng_0014_${getSearch('id')}`,
            alternate: JSON.stringify({
              ...JSON.parse(getSearch('alternate')),
              order_num: orderNum
            })
          })
        }
      }
    }
  },
  methods: {
    detentionPay () {
      const { orderNum } = this.value
      this.$log.click({
        current_page: window.location.href,
        module_ori: '支付挽留弹窗-【继续支付】',
        module_part: `106_luodiyehoutai_lcl_0009_${getSearch('id')}`,
        alternate: JSON.stringify({
          ...JSON.parse(getSearch('alternate')),
          order_num: orderNum
        })
      })
      this.value.showDialog = false
      this.$emit('detentionpay')
    },
    cancelDialog () {
      const { orderNum } = this.value
      // 取消支付，支付类型
      this.$log.click({
        current_page: window.location.href,
        module_ori: '支付挽留弹窗 【考虑考虑】',
        module_part: `106_luodiyehoutai_lcl_0008_${getSearch('id')}`,
        alternate: JSON.stringify({
          ...JSON.parse(getSearch('alternate')),
          order_num: orderNum
        })
      })
      this.value.showDialog = false
      this.$emit('cancelpay')
    }
  }
}
</script>
<style lang="scss" scoped>
.detentionDialog {
  /deep/ {
    background-color: rgba(0,0,0,0);
  }
  &-title {
    position: absolute;
    color: #333333;
    text-align: center;
    width: 100%;
  }
  &-btns {
    position: absolute;
    width: 100%;
    &-cancel,
    &-confirm {
      position: absolute;
      text-align: center;
      font-size: 30px;
      font-weight: 500;
      background-size: contain;
    }
    &-cancel {
      color: #9D9D9D;
    }
    &-confirm {
      color: #fff;
    }
  }
}
.price {
    position: absolute;
    z-index: 4;
    // top: 250px;
    left: 0;
    right: 0;
    font-size: 120px;
    line-height: 120px;
    font-family: SanFrancisco;
    text-align: center;
    margin: auto;
    margin-top: 36%;
    display: flex;
    font-weight: 700;
    align-items: flex-end;
    justify-content: center;
    color: #ea3e43;
  }
  .yuan {
    z-index: 3;
    width: 50px;
    height: 47px;
    margin-bottom: 10px;
    background-image: url("https://wm-front-common.oss-cn-beijing.aliyuncs.com/wm-front-landing-page-mobile/yuan.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .detentionDialog-btns-confirm,
  .detentionDialog-btns-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
