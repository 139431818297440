<template>
  <div
    class="sharing fadeUp"
    :style="{
      top: (150 - y) / 75 + 'rem',
      right: (20 - x) / 75 + 'rem'
    }"
    v-if="is_show">
    <img loading="lazy" :src="buying.portrait" />
    <span>{{ buying.nickname }}</span>
  </div>
</template>

<script>
let Timer = null
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      ...this.value.recent_buy,
      buying: {},
      buyList: [],
      time: ['1分钟前', '5秒前', '2秒前', '刚刚', '20秒前', '30秒前', '12秒前', '3分钟前', '2分钟前', '8秒前', '7秒前', '15秒前', '13秒前', '11秒前', '1秒前', '5分钟前']
    }
  },
  async created () {
    if (!this.is_show) return
    await this.getRecentBuy()
    this.randomSharing()
    Timer = setInterval(this.randomSharing, 4500)
  },
  beforeDestroy () {
    clearInterval(Timer)
  },
  methods: {
    random (min, max) {
      const range = max - min
      const rand = Math.random()
      const num = min + Math.round(rand * range)
      return num
    },
    /**
     * @description: 随机挑取一个分享人显示
     */
    randomSharing () {
      const index = this.random(0, this.buyList.length - 1)
      const resList = {}
      Object.assign(resList, this.buyList[index])
      const tIndex = this.random(0, this.time.length - 1)
      resList.nickname = `${resList.nickname}${this.time[tIndex]}购买了课程`
      this.buying = resList
    },
    async getRecentBuy () {
      const res = await this.$apis.landPage.getLatestUser()
      this.buyList = res
    }
  }
}
</script>
<style lang="scss" scoped>
.sharing {
  @include verCenter;
  position: absolute;
  z-index: 99;
  max-width: 500px;
  padding: 0 50px 0 10px;
  height: 60px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 25px;
  font-size: 22px;
  color: #fff;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.fadeUp {
  animation: fadeUp 4.5s infinite;
}

@keyframes fadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  50% {
    transform: translateY(25px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
</style>
