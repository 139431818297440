var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value.group_buy && _vm.value.group_buy.is_show)?_c('div',{staticClass:"buying"},_vm._l((_vm.value.group_buy.groups),function(item,i){return _c('div',{key:i,staticClass:"groups",style:({
      borderTopLeftRadius: item.bg_style.radius_visibility.top_left / 2 + 'px',
      borderTopRightRadius: item.bg_style.radius_visibility.top_right / 2 + 'px',
      borderBottomLeftRadius: item.bg_style.radius_visibility.bottom_left / 2 + 'px',
      borderBottomRightRadius: item.bg_style.radius_visibility.bottom_right / 2 + 'px',
      backgroundColor: item.bg_style.bg_color,
      backgroundImage:`url(${(item.bg_img && item.bg_img.length) ? item.bg_img[0].url : '' })`
    })},[(item.title.is_show)?_c('div',{staticClass:"tit van-hairline--bottom van-ellipsis",style:({
        fontSize: item.title.font_size / 75 + 'rem',
        color: item.title.font_color,
        textAlign: item.title.text_align
      })},[_c('span',[_vm._v(_vm._s(item.title.content1))]),_c('span',{style:({color: 'red'})},[_vm._v(_vm._s(item.title.content2))]),_c('span',[_vm._v(_vm._s(item.title.content3))])]):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"portrait",style:({
          backgroundImage:'url(' + item.portrait + ')'
        })}),_c('div',{staticClass:"uname",style:({
          color: item.name_color
        })},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"rest"},[_vm._m(0,true),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.count_down.is_show),expression:"item.count_down.is_show"}],staticClass:"rest-time"},[_c('span',{style:({
              color: item.count_down.title_color
            })},[_vm._v(_vm._s(item.count_down.title))]),_c('GroupCountDown',{attrs:{"count-down":item.count_down}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.group_btn.is_show),expression:"item.group_btn.is_show"}],staticClass:"goBug flexCenter",style:({
          borderTopLeftRadius: item.group_btn.radius_visibility.top_left / 2 + 'px',
          borderTopRightRadius: item.group_btn.radius_visibility.top_right / 2 + 'px',
          borderBottomLeftRadius: item.group_btn.radius_visibility.bottom_left / 2 + 'px',
          borderBottomRightRadius: item.group_btn.radius_visibility.bottom_right / 2 + 'px',
          backgroundImage:`url(${(item.group_btn.img.length) ? item.group_btn.img[0].url : '' })`,
          top: item.group_btn.top + 'px',
          backgroundColor: item.group_btn.bg_color,
          height: item.group_btn.height / 2 + 'px',
          lineHeight: item.group_btn.height / 2 + 'px',
          width: item.group_btn.width / 2 + 'px',
          fontSize: item.group_btn.input_text.font_size / 2 + 'px',
          color: item.group_btn.input_text.color,
          fontStyle: item.group_btn.input_text.style.includes('italic') ? 'italic' : 'normal',
          fontWeight: item.group_btn.input_text.style.includes('bold') ? 700 : 400,
          textDecoration: item.group_btn.input_text.style.includes('underline') ? 'underline': item.group_btn.input_text.style.includes('lineThrough') ? 'line-through': ''
        }),on:{"click":_vm.handleGroupBug}},[(!item.group_btn.img[0])?_c('span',[_vm._v(_vm._s(item.group_btn.input_text.text))]):_vm._e()])])])}),0):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"rest-chance"},[_vm._v("还差"),_c('span',{staticClass:"heightLight1"},[_vm._v("1人")]),_vm._v("拼成")])
}]

export { render, staticRenderFns }