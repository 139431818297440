<template>
  <!-- 弹窗 -->
  <VanPopup v-model="show" class="agreement-wrap">
    <div class="agreement-wrap__content">
      <!-- <img
        class="agreement-wrap__close"
        src="@/assets/close.png"
        alt=""
        @click="show=false"> -->
      <div class="agreement-wrap__title">请先阅读</div>
      <div class="agreement-wrap__message" @click="checkAgreement">《个人信息和隐私保护协议》</div>
      <div class="agreement-wrap__border"></div>
      <div class="agreement-wrap__btn" @click="confirm">已阅读并同意</div>
    </div>
  </VanPopup>
</template>
<script>
import {
  getSearch
} from '@/utils/index'
export default {
  name: 'AgreementWrap',
  data () {
    return {
      show: false
    }
  },
  methods: {
    toggleShow () {
      this.show = !this.show
      if (this.show) {
        this.$log.show({
          module_ori: '落地页首页-隐私协议-提示弹窗',
          module_part: `106_tf_ldyyinsi_00003_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      }
    },
    confirm () {
      this.$log.click({
        module_ori: '落地页首页-隐私协议-提示弹窗-已经阅读并同意',
        module_part: `106_tf_ldyyinsi_00004_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.toggleShow()
      this.$emit('confirm')
    },
    checkAgreement () {
      this.toggleShow()
      this.$emit('checkAgreement')
    }
  }
}
</script>
<style lang="scss">
.agreement-wrap {
  width: 580px;
  background: #ffffff;
  border-radius: 20px;
}
.agreement-wrap__content {
  position: relative;
  width: 580px;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  .agreement-wrap__title {
    color: #0C0C0C;
  }
  .agreement-wrap__message {
    text-align: center;
    margin: 40px 0 40px;
    color: #079BEE;
    font-size: 28px;
  }
  .agreement-wrap__border{
    width: 100%;
    height: 1px;
    background-color:#0C0C0C;
    opacity: 0.2;
  }
  .agreement-wrap__btn{
    color: #079BEE;
    margin-top:40px;
  }
  .agreement-wrap__close{
    position: absolute;
    right:40px;
    top:30px;
    width:32px;
    height: 32px;
  }
}
</style>
