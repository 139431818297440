import processHook from './process-hook'
import authHook from './auth-hook'
import reportHook from './report-hook'
// import statisticsHook from './statistics-hook'

export default router => {
  processHook(router)
  reportHook(router)
  authHook(router)
  // statisticsHook(router)
}
