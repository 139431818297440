/*
 * @Author: MiaoshunLee
 * @Date: 2023-12-26 14:50:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-07-06 16:08:40
 * @Description:
 */
/* 长轻问卷 */ const Questionnaire = () => import(/* webpackChunkName: "Questionnaire" */ '@/views/changqing/Questionnaire')
/* 老师二维码 */ const ChangqingCode = () => import(/* webpackChunkName: "ChangqingCode" */ '@/views/changqing/TeacherQrCode')
/* 手机号授权 */ const CqCheckPhone = () => import(/* webpackChunkName: "CqCheckPhone" */ '@/views/changqing/CheckPhone')
/** 实物订单 */
const BookOrder = () => import(/* webpackChunkName: "EvaluationResults" */ '@/views/changqing/BookOrder')
/** 物流查询 */
const Logistics = () => import(/* webpackChunkName: "Logistics" */ '@/views/changqing/BookOrder/logistics')
/* 用户进班 */
const CqEnterClass = () => import(/* webpackChunkName: "CqEnterClass" */ '@/views/changqing/EnterClass')

export default [
  // 长轻问卷
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: Questionnaire,
    meta: {
      title: '健康诊断与评估问卷'
    }
  },
  {
    path: '/ChangqingCode',
    name: 'ChangqingCode',
    component: ChangqingCode,
    meta: {
      title: '添加健康指导师微信'
    }
  },
  {
    path: '/cqCheckPhone',
    name: 'CqCheckPhone',
    component: CqCheckPhone,
    meta: {
      title: '添加健康指导师微信'
    }
  },
  {
    path: '/cqCheckPhone',
    name: 'CqCheckPhone',
    component: CqCheckPhone,
    meta: {
      title: '添加健康指导师微信'
    }
  },
  {
    path: '/book-order',
    name: 'BookOrder',
    component: BookOrder,
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/logistics',
    name: 'Logistics',
    component: Logistics,
    meta: {
      title: '物流查询'
    }
  },
  {
    path: '/cq-enter-class',
    name: 'CqEnterClass',
    component: CqEnterClass,
    meta: {
      title: '长轻营养食疗'
    }
  }
]
