// 处理埋点相关
// import { getSearch } from '@/utils'
import Store from '@/store'
export default (router) => {
  router.beforeEach(async (to, from, next) => {
    let clickId = Store.state.user.wm_click_id
    if (!clickId) {
      clickId = await Store.dispatch('user/getWmClikId')
    }
    if (clickId !== to.query.wm_click_id) {
      return next({
        name: to.name,
        query: {
          ...to.query,
          wm_click_id: clickId
        },
        replace: true
      })
    }
    if (!to.query.buy_id) {
      let buyId = Store.state.user.buy_id
      if (!buyId) {
        buyId = new Date().getTime()
        Store.commit('user/SET_BUY_ID', buyId)
      }
      return next({
        name: to.name,
        query: {
          ...to.query,
          buy_id: buyId
        },
        replace: true
      })
    }
    next()
  })
  router.afterEach((to) => {
    // 不适用
    // const { protocol, host, link, ...rest } = getSearch()
    // const parmas = {
    //   current_page: location.href,
    //   mid: Store.state.user?.userInfo?.id,
    //   alternate: getSearch('alternate')
    // }
    // if (to.meta.module_part) {
    //   parmas.module_ori = to.meta.module_ori || '基于整体页面'
    //   parmas.module_part = `${to.meta.module_part}_${getSearch('id')}`
    // }
    // Store.$log.update(parmas)
    // Store.$log.show(parmas)
    // console.log(to)
    Store.$log.update({
      current_page: location.href
    })
  })
}
