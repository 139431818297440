import { getSearch } from '@/utils/index'
import promiseWx from '@/libs/promiseWx'
import Store from '@/store'

export default {
  namespaced: true,
  state: {
    wxConfig: null,
    userInfo: null,
    wm_click_id: sessionStorage.getItem('wm_click_id'),
    buy_id: localStorage.getItem('buy_id') // 限制购买
  },
  mutations: {
    SET_WX_CONFIG (state, payload) {
      state.wxConfig = payload
    },
    SET_USER (state, payload) {
      state.userInfo = { ...payload, mid: payload.id }
    },
    SET_CLICKID (state, payload) {
      state.wm_click_id = payload
    },
    // 限制购买
    SET_BUY_ID (state, payload) {
      state.buy_id = payload
      localStorage.setItem('buy_id', payload)
    }
  },
  actions: {
    /**
     * @description 获取微信config
     */
    async getWxConfig ({ commit }) {
      try {
        const data = await this.$apis.user.getWxConfig({
          url: location.href.split('#')[0]
        })
        await promiseWx.mount({
          appId: data.appId,
          nonceStr: data.nonceStr,
          signature: data.signature,
          timestamp: data.timestamp
        })
        commit('SET_WX_CONFIG', data)
      } catch (error) {
        return error
      }
    },
    // 换取token 用户登录
    async getLogin ({ commit }) {
      const { token, userInfo, user } = await this.$apis.user.getToken({ ...getSearch() })
      localStorage.removeItem('authing')
      localStorage.setItem('Authorization', 'Bearer ' + token)
      commit('SET_USER', userInfo || user)
    },
    // 获取用户信息
    async getUserInfo ({ commit }) {
      const userInfo = await this.$apis.user.getUserInfo()
      commit('SET_USER', userInfo)
      const { id, wx_body: { openid, unionid } } = userInfo
      Store.$log.update({ mid: id, openid, unionid })
    },
    // 获取wmclickId
    async getWmClikId ({ commit }) {
      const data = await this.$apis.landPage.newClickId()
      sessionStorage.setItem('wm_click_id', data.wm_click_id)
      commit('SET_CLICKID', data.wm_click_id)
      return data.wm_click_id
    }
  }
}
