import Vue from 'vue'
import VueRouter from 'vue-router'
import Hooks from './hooks'
import { isWeiXin } from '@/utils'
import WxLandPage from '@/views/WxLandPage'
import LandPage from '@/views/LandPage'
import cqRoute from './changqing'
/* 长轻课分班引导页面（小程序） */const CQPaySuccess = () => import(/* webpackChunkName: "CQPaySuccess" */ '@/views/PaySuccess/changqing.vue')
/* 长轻课分班引导页面（h5） */const CQPaySuccessH5 = () => import(/* webpackChunkName: "CQPaySuccess" */ '@/views/PaySuccess/changqingH5.vue')
/* 财商课二维码 */const WxPaySuccess = () => import(/* webpackChunkName: "wxpay-success" */ '@/views/WxPaySuccess')
/* 财商课加群二维码 */const WxPaySuccessGroup = () => import(/* webpackChunkName: "wxpay-success-group" */ '@/views/WxPaySuccess/group.vue')
/* 管理课分班引导页面小程序 */ const ManagePaySuccess = () => import(/* webpackChunkName: "ManagePaySuccess" */ '@/views/PaySuccess/PaySuccessManage')
/* 管理课分班引导页面h5 */ const ManagePaySuccessH5 = () => import(/* webpackChunkName: "ManagePaySuccessH5" */ '@/views/PaySuccess/PaySuccessManage/ManagePaySuccessH5')

/* 育儿课分班引导页面 */ const YuerPaySuccess = () => import(/* webpackChunkName: "ManagePaySuccess" */ '@/views/PaySuccess/yuer.vue')
/* 每职分班引导页面 */ const MeiZhiPaySuccess = () => import(/* webpackChunkName: "PaySuccessMeiZhi" */ '@/views/PaySuccess/PaySuccessMeiZhi')
/* 管理课二维码 */ const ManageWxSuccess = () => import(/* webpackChunkName: "ManageWxSuccess" */ '@/views/WxPaySuccess/WxPaySuccessManage')
/* 善育儿二维码 */ const YuerWxSuccess = () => import(/* webpackChunkName: "YuerWxSuccess" */ '@/views/WxPaySuccess/yuer.vue')
/* 每职二维码 */ const MeiZhiWxPaySuccess = () => import(/* webpackChunkName: "WxPaySuccessMeiZhi" */ '@/views/WxPaySuccess/WxPaySuccessMeiZhi')
/* 短信召回链接 */ const SmsPage = () => import(/* webpackChunkName: "SmsPage" */ '@/views/SmsPage')

/* 短信召回链接 */ const FissionPage = () => import(/* webpackChunkName: "FissionPage" */ '@/views/FissionPage')
/* H5跳小程序 */ const TargetPage = () => import(/* webpackChunkName: "TargetPage" */ '@/views/targetPage')
/* H5跳小程序 */ const BindPhone = () => import(/* webpackChunkName: "BindPhone" */ '@/views/targetPage/BindPhone')
/* 卡券核销 */ const WriteOffPage = () => import(/* webpackChunkName: "WriteOffPage" */ '@/views/WriteOffPage')
/* 核销成功 */ const WriteOffSuccess = () => import(/* webpackChunkName: "WriteOffSuccess" */ '@/views/WriteOffPage/WriteOffSuccess')
Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'index',
    component: async () => {
      if (isWeiXin()) return WxLandPage
      return LandPage
    },
    meta: {
      module_part: '106_luodiyehoutai_lcl_0013'
    }
  },

  // H5跳小程序
  {
    path: '/targetPage',
    name: 'TargetPage',
    component: TargetPage,
    meta: {
      title: '跳转'
    }
  },
  // H5跳小程序绑定手机号
  {
    path: '/targetPage/BindPhone',
    name: 'BindPhone',
    component: BindPhone,
    meta: {
      title: '跳转'
    }
  },

  // 微信外落地页
  {
    path: '/landPage',
    name: 'LandPage',
    component: LandPage,
    meta: {
      module_part: '106_luodiyehoutai_lcl_0013'
    }
  },
  // 长轻课分班引导（小程序）
  {
    path: '/landPage/CQPaySuccess',
    name: 'CQPaySuccess',
    component: CQPaySuccess,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  // 长轻课分班引导（H5）
  {
    path: '/landPage/CQPaySuccessH5',
    name: 'CQPaySuccessH5',
    component: CQPaySuccessH5,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  // 管理课分班引导
  {
    path: '/landPage/ManagePaySuccess',
    name: 'ManagePaySuccess',
    component: ManagePaySuccess,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  {
    path: '/landPage/ManagePaySuccessH5',
    name: 'ManagePaySuccessH5',
    component: ManagePaySuccessH5,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  // 育儿课分班引导
  {
    path: '/landPage/YuerPaySuccess',
    name: 'YuerPaySuccess',
    component: YuerPaySuccess,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  // 每职分班引导
  {
    path: '/landPage/MeiZhiPaySuccess',
    name: 'MeiZhiPaySuccess',
    component: MeiZhiPaySuccess,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  {
    path: '/wxLandPage',
    name: 'WxLandPage',
    component: WxLandPage,
    meta: {
      module_part: '106_luodiyehoutai_lcl_0013'
    }
  },
  {
    path: '/wxLandPage/managePaySuccess',
    name: 'ManageWxSuccess',
    component: ManageWxSuccess,
    meta: {
      title: '查看二维码',
      module_part: '106_luodiyehoutai_lcl_0024'
    }
  },
  // 善育儿二维码
  {
    path: '/wxLandPage/YuerWxSuccess',
    name: 'YuerWxSuccess',
    component: YuerWxSuccess,
    meta: {
      title: '查看二维码',
      module_part: '106_luodiyehoutai_lcl_0024'
    }
  },
  // 每职二维码
  {
    path: '/wxLandPage/MeiZhiWxPaySuccess',
    name: 'MeiZhiWxPaySuccess',
    component: MeiZhiWxPaySuccess,
    meta: {
      title: '查看二维码',
      module_part: '106_luodiyehoutai_lcl_0024'
    }
  },
  {
    path: '/wxLandPage/paySuccess',
    name: 'WxPaySuccess',
    component: WxPaySuccess,
    meta: {
      title: '查看二维码',
      module_part: '106_luodiyehoutai_lcl_0024'
    }
  },
  {
    path: '/wxLandPage/group',
    name: 'WxPaySuccessGroup',
    component: WxPaySuccessGroup,
    meta: {
      title: '查看二维码',
      module_part: '106_luodiyehoutai_lcl_0024'
    }
  },
  {
    path: '/sms',
    name: 'Sms',
    component: SmsPage,
    meta: {
      title: '报名成功',
      module_part: '106_luodiyehoutai_lcl_0023'
    }
  },
  // 裂变落地页
  {
    path: '/fission-page',
    name: 'FissionPage',
    component: FissionPage,
    meta: {
      title: '长轻营养食疗体验营'
    }
  },
  {
    path: '/write-off',
    name: 'WriteOffPage',
    component: WriteOffPage,
    meta: {
      title: '长轻营养食疗'
    }
  },
  {
    path: '/write-off-success',
    name: 'WriteOffSuccess',
    component: WriteOffSuccess,
    meta: {
      title: '长轻营养食疗'
    }
  },
  ...cqRoute
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
Hooks(router)

export default router
