var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.value.form_pay.is_hidden_phone)?_c('div',[_c('VanPopup',{staticClass:"form-pop",style:({
      width: _vm.value.form_pay.form_bg.width / 75 + 'rem',
      backgroundImage: 'url(' + _vm.comFormBgUrl + ')',
      height: _vm.value.form_pay.form_bg.height / 75 + 'rem',
      backgroundColor: _vm.value.form_pay.form_bg.bg_color,
      borderTopLeftRadius: _vm.value.form_pay.form_bg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: _vm.value.form_pay.form_bg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: _vm.value.form_pay.form_bg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: _vm.value.form_pay.form_bg.radius_visibility.bottom_right / 75 + 'rem',
      backgroundRepeat: 'repeat',
      backgroundSize: 'contain',
      backgroundColor: '#FFFFFF',
      overflow: 'hidden'
    }),attrs:{"position":"bottom"},model:{value:(_vm.value.showPop),callback:function ($$v) {_vm.$set(_vm.value, "showPop", $$v)},expression:"value.showPop"}},[_c('div',{staticClass:"landpage-formpay"},[_c('VanField',{staticClass:"phone",class:{'fail':_vm.isClick&&!_vm.validatePhone},style:({
          width: _vm.value.form_pay.phone.width / 75 + 'rem',
          height: _vm.value.form_pay.phone.height / 75 + 'rem',
          backgroundImage: 'url(' + _vm.comFormPhoneBg + ')',
          backgroundColor: _vm.value.form_pay.phone.bg_color,
          left: _vm.comPhoneLeft + 'rem',
          top: _vm.value.form_pay.phone.top / 75 + 'rem',
          fontWeight: _vm.comPhoneInputBold,
          borderTopLeftRadius: _vm.value.form_pay.phone.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.value.form_pay.phone.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.value.form_pay.phone.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.value.form_pay.phone.radius_visibility.bottom_right / 75 + 'rem'
        }),attrs:{"center":"","type":"tel","maxlength":"11","placeholder":_vm.value.form_pay.phone.placeholders.text},on:{"input":_vm.changePhone,"blur":_vm.blurPhone},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"left city-code",on:{"click":_vm.selCityCode}},[_c('span',[_vm._v("+"+_vm._s(_vm.cityCode))]),(_vm.value.form_pay.is_city_code)?_c('VanIcon',{attrs:{"name":"arrow-down"}}):_vm._e()],1)]},proxy:true}],null,false,3345659794),model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('VanField',{ref:"testCode",staticClass:"testCode",class:{'fail':_vm.isClick&&!_vm.validateCode},style:({
          left: _vm.comCodeLeft + 'rem',
          top: _vm.value.form_pay.test_code.top / 75 + 'rem',
          fontWeight: _vm.comTestCodeInputBold,
          borderTopLeftRadius: _vm.value.form_pay.test_code.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.value.form_pay.test_code.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.value.form_pay.test_code.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.value.form_pay.test_code.radius_visibility.bottom_right / 75 + 'rem',
          backgroundImage: 'url(' + _vm.comFormTestCodeUrl + ')',
          backgroundColor: _vm.value.form_pay.test_code.bg_color,
          width: (_vm.value.form_pay.test_code.width + _vm.value.form_pay.get_code.width) / 75 + 'rem'
        }),attrs:{"center":"","clearable":"","type":"digit","maxlength":"4","placeholder":_vm.value.form_pay.test_code.placeholders.text},on:{"input":_vm.changeTestCode,"blur":_vm.blurTestCode},scopedSlots:_vm._u([{key:"button",fn:function(){return [(_vm.showGetCodeBtn)?_c('div',{staticClass:"landpage-formpay-getcode",style:({
              backgroundColor: _vm.value.form_pay.get_code.bg_color,
              color: _vm.value.form_pay.get_code.placeholders.color,
              fontSize: _vm.value.form_pay.get_code.placeholders.font_size / 75 + 'rem',
              width: _vm.value.form_pay.get_code.width / 75 + 'rem',
              height: _vm.value.form_pay.get_code.height / 75 + 'rem',
              lineHeight: _vm.value.form_pay.get_code.height / 75 + 'rem',
              borderTopLeftRadius: _vm.value.form_pay.get_code.radius_visibility.top_left / 75 + 'rem',
              borderTopRightRadius: _vm.value.form_pay.get_code.radius_visibility.top_right / 75 + 'rem',
              borderBottomLeftRadius: _vm.value.form_pay.get_code.radius_visibility.bottom_left / 75 + 'rem',
              borderBottomRightRadius: _vm.value.form_pay.get_code.radius_visibility.bottom_right / 75 + 'rem',
              fontWeight: _vm.comGetCodePlaceholderBold,
              fontStyle: _vm.comGetCodePlaceholderItalic,
              textDecoration: _vm.comGetCodePlaceholderDecoration,
              textAlign: 'center'
            }),on:{"click":_vm.getCode}},[_vm._v(_vm._s(_vm.getCodeBtnTxt))]):_vm._e(),(!_vm.showGetCodeBtn)?_c('VanCountDown',{ref:"countDownGetCode",style:({
              backgroundColor: _vm.value.form_pay.get_code.bg_color,
              color: _vm.value.form_pay.get_code.placeholders.color,
              fontSize: _vm.value.form_pay.get_code.placeholders.font_size / 75 + 'rem',
              width: _vm.value.form_pay.get_code.width / 75 + 'rem',
              height: _vm.value.form_pay.get_code.height / 75 + 'rem',
              lineHeight: _vm.value.form_pay.get_code.height / 75 + 'rem',
              borderTopLeftRadius: _vm.value.form_pay.get_code.radius_visibility.top_left / 75 + 'rem',
              borderTopRightRadius: _vm.value.form_pay.get_code.radius_visibility.top_right / 75 + 'rem',
              borderBottomLeftRadius: _vm.value.form_pay.get_code.radius_visibility.bottom_left / 75 + 'rem',
              borderBottomRightRadius: _vm.value.form_pay.get_code.radius_visibility.bottom_right / 75 + 'rem',
              fontWeight: _vm.comGetCodePlaceholderBold,
              fontStyle: _vm.comGetCodePlaceholderItalic,
              textDecoration: _vm.comGetCodePlaceholderDecoration,
              textAlign: 'center'
            }),attrs:{"auto-start":false,"time":_vm.codeTime,"format":"sss后获取"},on:{"finish":function($event){_vm.showGetCodeBtn = true}}}):_vm._e()]},proxy:true}],null,false,3590903634),model:{value:(_vm.form.testCode),callback:function ($$v) {_vm.$set(_vm.form, "testCode", $$v)},expression:"form.testCode"}}),((_vm.value.form_pay.conceal_guard || {}).is_show)?_c('div',{staticClass:"landpage-formpay-conceal-guard",style:({
          ..._vm.comConcealGuardStyle,
          ..._vm.comConcealGuardPosi,
          top: _vm.value.form_pay.conceal_guard.top / 75 + 'rem',
          lineHeight: (_vm.value.form_pay.conceal_guard.input_text.font_size + 4) / 75 + 'rem',
          color: _vm.value.form_pay.conceal_guard.input_text.color,
          fontSize: _vm.value.form_pay.conceal_guard.input_text.font_size / 75 + 'rem'
        })},[((_vm.value.form_pay.conceal_guard || {}).needSelect)?_c('VanCheckbox',{attrs:{"icon-size":"16px"},on:{"change":_vm.checkCompact},model:{value:(_vm.form.checked),callback:function ($$v) {_vm.$set(_vm.form, "checked", $$v)},expression:"form.checked"}},[_vm._v("已阅读并同意")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.$emit('clickConcealGuard')}}},[_vm._v(_vm._s(_vm.value.form_pay.conceal_guard.input_text.text))])],1):_vm._e(),_c('div',{directives:[{name:"debounce",rawName:"v-debounce",value:(1500),expression:"1500"}],staticClass:"landpage-formpay-submit",style:({
          backgroundImage: 'url(' + _vm.comFormBtnUrl + ')',
          left: _vm.comSubmitLeft + 'rem',
          top: _vm.value.form_pay.submit.top / 75 + 'rem',
          width: _vm.value.form_pay.submit.width / 75 + 'rem',
          height: _vm.value.form_pay.submit.height / 75 + 'rem',
          backgroundColor: _vm.value.form_pay.submit.bg_color,
          lineHeight: _vm.value.form_pay.submit.height / 75 + 'rem',
          color: _vm.value.form_pay.submit.input_text.color,
          fontSize: _vm.value.form_pay.submit.input_text.font_size / 75 + 'rem',
          borderTopLeftRadius: _vm.value.form_pay.submit.radius_visibility.top_left / 75 + 'rem',
          borderTopRightRadius: _vm.value.form_pay.submit.radius_visibility.top_right / 75 + 'rem',
          borderBottomLeftRadius: _vm.value.form_pay.submit.radius_visibility.bottom_left / 75 + 'rem',
          borderBottomRightRadius: _vm.value.form_pay.submit.radius_visibility.bottom_right / 75 + 'rem',
          fontWeight: _vm.comSubmitBold,
          fontStyle: _vm.comSubmitItalic,
          textDecoration: _vm.comSubmitDecoration
        }),on:{"click":_vm.submitClick}},[_vm._v(_vm._s(_vm.value.form_pay.submit.input_text.text))])],1)]),(_vm.value.form_pay.is_city_code)?_c('CityCode',{ref:"refCityCode",on:{"getCityCode":_vm.getCityCode}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }