var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.is_show)?_c('div',{class:`landpage-countdown ${_vm.direction === _vm.COUNT_DOWN_STYLE.V ? 'vertical' : 'horizontal'}`,style:({
    top: (460 - _vm.y) / 75 + 'rem',
    left: (540 + _vm.x) / 75 + 'rem',
    color: _vm.font_color
  })},[(_vm.is_show_title)?_c('span',{staticClass:"title",style:({
      fontSize: _vm.titleStyle.font_size/75 + 'rem'
    })},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('VanCountDown',{style:({
      fontSize: _vm.font_size / 75 + 'rem'
    }),attrs:{"time":_vm.comTime,"millisecond":_vm.hasMs,"format":_vm.type}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }