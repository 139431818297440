<template>
  <div
    class="landpage-bottom"
    v-if="is_show"
    :style="{
      backgroundImage:'url(' + comBottomBgUrl + ')',
      borderTopLeftRadius: bottom_bg.radius_visibility.top_left / 75 + 'rem',
      borderTopRightRadius: bottom_bg.radius_visibility.top_right / 75 + 'rem',
      borderBottomLeftRadius: bottom_bg.radius_visibility.bottom_left / 75 + 'rem',
      borderBottomRightRadius: bottom_bg.radius_visibility.bottom_right / 75 + 'rem',
      height: bottom_bg.height / 75 + 'rem',
      width: bottom_bg.width / 75 + 'rem',
      backgroundColor: bottom_bg.bg_color
    }">
    <div
      class="left"
      :style="{
        fontSize: bottom_bg.input_text.font_size / 75 + 'rem',
        lineHeight: bottom_bg.height / 75 + 'rem',
        color: bottom_bg.input_text.color,
        fontWeight: comBottomBgBold,
        fontStyle: comBottomBgItalic,
        textDecoration: comBottomBgDecoration
      }">{{ bottom_bg.input_text.text }}</div>
    <div
      class="landpage-bottom-paybtn"
      :style="{
        borderTopLeftRadius: pay_img_btn.radius_visibility.top_left / 75 + 'rem',
        borderTopRightRadius: pay_img_btn.radius_visibility.top_right / 75 + 'rem',
        borderBottomLeftRadius: pay_img_btn.radius_visibility.bottom_left / 75 + 'rem',
        borderBottomRightRadius: pay_img_btn.radius_visibility.bottom_right / 75 + 'rem',
        backgroundImage:'url(' + comBottomBtnUrl + ')',
        left: comSubmitLeft + 'rem',
        top: pay_img_btn.top / 75 + 'rem',
        right: 0 - pay_img_btn.x / 75 + 'rem',
        backgroundColor: pay_img_btn.bg_color,
        height: pay_img_btn.height / 75 + 'rem',
        lineHeight: pay_img_btn.height / 75+ 'rem',
        width: pay_img_btn.width / 75 + 'rem',
        fontSize: pay_img_btn.input_text.font_size / 75 + 'rem',
        color: pay_img_btn.input_text.color,
        fontWeight: comBottomSubmitBold,
        fontStyle: comBottomSubmitItalic,
        textDecoration: comBottomSubmitDecoration,
      }"
      v-debounce="1500"
      @click="handleSubmit">{{ pay_img_btn.input_text.text }}</div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getSearch } from '@/utils/index'
export default {
  name: 'BottomFixed',
  props: {
    // bottom区域的配置信息
    // bottomFixed.is_show: [true|false] 是否渲染
    // bottomFixed.img_bg: [{url}] 底部悬浮栏背景图
    // bottomFixed.pay_img_btn.img_list: [{url}] 限量抢购按钮的背景
    // bottomFixed.pay_img_btn.x:  限量抢购按钮水平方向上的距离
    bottomFixed: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      ...this.bottomFixed
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    // 计算抢购按钮距左侧距离
    comSubmitLeft () {
      switch (this.pay_img_btn.text_align) {
        case 'left':
          return 0
        case 'center':
          return (750 - this.pay_img_btn.width) / 2 / 75
        case 'right':
          return (750 - this.pay_img_btn.width) / 75
        default:
          return this.pay_img_btn.x / 75
      }
    },
    // 计算提交按钮下划线
    comBottomSubmitDecoration () {
      if (this.pay_img_btn.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.pay_img_btn.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算底部提交按钮斜体
    comBottomSubmitItalic () {
      return this.pay_img_btn.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算底部提交按钮加粗
    comBottomSubmitBold () {
      return this.pay_img_btn.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算底部背景文字下划线
    comBottomBgDecoration () {
      if (this.bottom_bg.input_text.style.includes('underline')) {
        return 'underline'
      } else if (this.bottom_bg.input_text.style.includes('lineThrough')) {
        return 'line-through'
      } else {
        return ''
      }
    },
    // 计算底部左侧文字斜体
    comBottomBgItalic () {
      return this.bottom_bg.input_text.style.includes('italic') ? 'italic' : 'normal'
    },
    // 计算底部左侧文字加粗
    comBottomBgBold () {
      return this.bottom_bg.input_text.style.includes('bold') ? 700 : 400
    },
    // 计算底部固定区域btn-url
    comBottomBtnUrl () {
      const imgList = this.pay_img_btn.img_list
      return imgList.length ? imgList[0].url : ''
    },
    // 计算底部固定区域url
    comBottomBgUrl () {
      return this.img_bg.length ? this.img_bg[0].url : ''
    }
  },
  methods: {
    handleSubmit () {
      this.$log.update({ mid: this.userInfo?.mid || 0 })
      this.$log.click({
        current_page: location.href,
        module_ori: '底部悬浮栏',
        module_part: `106_luodiyehoutai_lcl_0003_${getSearch('id')}`,
        alternate: {
          ...JSON.parse(getSearch('alternate', 4)),
          tourist_id: window.tourist_id ? window.tourist_id : ''
        }
      })
      this.$emit('formsubmit')
    }
  }
}
</script>
<style lang="scss" scoped>
.landpage {
  position: relative;
  min-height: 100vh;
  // 底部固定栏
  &-bottom {
    position: fixed;
    bottom: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: top;
    z-index: 99;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    box-sizing: content-box;
    .left {
      color: #2B2D3B;
      padding-left: 32px;
      box-sizing: content-box;
    }
    &-paybtn {
      position: absolute;
      top: 0;
      width: 435px;
      height: 166px;
      text-align: center;
      background-repeat: no-repeat;
      background-size: contain;
      padding-bottom: env(safe-area-inset-bottom);
      box-sizing: content-box;
    }
  }
}
</style>
