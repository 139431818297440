<template>
  <VanDialog
    v-if="value.showConcealGuardDialog"
    v-model="value.showConcealGuardDialog"
    class="ConcealGuardDialog"
    :close-on-click-overlay="true"
    confirm-button-text="已阅读并同意"
    :title="message.title"
    @confirm="confirm">
    <div class="message-box">
      <div class="summary" v-html="(message.summary || '').replace(/\n/g, '<br/>')"></div>
      <div
        v-for="(item, index) in message.content"
        :key="index"
        class="content">
        <div class="title" v-html="(item.title || '').replace(/\n/g, '<br/>')"></div>
        <div class="content" v-html="(item.content || '').replace(/\n/g, '<br/>')"></div>
      </div>
    </div>
  </VanDialog>
</template>

<script>
import { getSearch } from '@/utils'
export default {
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      message: {}
    }
  },
  computed: {},
  watch: {
    'value.showConcealGuardDialog': {
      handler (newVal) {
        if (newVal) {
          this.$log.click({
            current_page: location.href,
            module_ori: '协议勾选查看',
            module_part: `106_tf_xinzeng_0009_${getSearch('id')}`,
            alternate: getSearch('alternate')
          })
        }
      }
    }
  },
  created () {
    this.getConcealGuardData()
  },
  mounted () {
  },
  methods: {
    async getConcealGuardData () {
      const { id } = getSearch()
      this.message = await this.$apis.landPage.getConcealGuardCont({ id }).then(res => res || {})
    },
    confirm () {
      this.$log.click({
        module_ori: '落地页首页-隐私协议-【已阅读并同意】',
        module_part: `106_tf_ldyyinsi_00002_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.$emit('concealGuardConfirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.ConcealGuardDialog{
  width: 8rem;
  height: 11.9333rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.32rem;
  /deep/ .van-dialog__header{
    font-size: 0.54rem;
    margin-bottom: 0.18rem;
    font-weight: 900;
  }
  /deep/ .van-dialog__content{
    color: #333333;
    flex: 1;
    overflow: hidden;
    .message-box{
      height: 100%;
      padding: 0.1rem 0.45rem;
      font-size: 0.42rem;
      line-height: 0.6rem;
      letter-spacing: 0.015rem;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .title{
        font-weight: 700;
        font-size: 0.44rem;
        margin: 0.2rem 0;
      }
    }
  }
}

</style>
