import {
  ActionSheet,
  Swipe,
  Cell,
  CellGroup,
  SwipeItem,
  Button,
  Toast,
  CountDown,
  Field,
  Dialog,
  RadioGroup,
  Radio,
  Popup,
  Checkbox,
  Loading
} from 'vant'

export default Vue => {
  Vue
    .use(ActionSheet)
    .use(Swipe)
    .use(Cell)
    .use(CellGroup)
    .use(SwipeItem)
    .use(Button)
    .use(Toast)
    .use(CountDown)
    .use(Field)
    .use(Dialog)
    .use(RadioGroup)
    .use(Radio)
    .use(Popup)
    .use(Loading)
    .use(Checkbox)
}
