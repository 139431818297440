<template>
  <div
    class="wxLandpage"
    :style="{
      paddingBottom: calPaddingBottom + 'rem',
    }">
    <Component
      v-for="(item, k) in components"
      :key="k"
      :is="item.component_name"
      :ref="item.component_name"
      :mark="item.component_mark"
      :value="configData"
      :form="form"
      :bottom-fixed="configData.bottom_fixed"
      :count-down="configData.count_down"
      :header-img="configData.header_img"
      :center-coupon-info="centerCouponInfo"
      :is-cq="isCq"
      @detentionpay="submit"
      @clickConcealGuard="clickConcealGuard"
      @formsubmit="handleSubmit"
      @concealGuardConfirm="concealGuardConfirm"
      @saveAddressInfo="handleAddressInfo"></Component>
    <VanPopup
      v-if="center_coupon_id"
      v-model="showRedPocket"
      class="redPocket animateRedPocket"
      :close-on-click-overlay="false">
      <div class="red-content animateRedContent">
        <div class="red-content__title">您的折扣红包已到账</div>
        <img
          src="//wm-front-common.weimiaocaishang.com/wm-front-landing-page-mobile/redPocketBg1.png"
          alt="" />
        <div class="red-content__price">
          {{ centerCouponInfo.rule.discount }}
          <div class="red-content__yuan"></div>
        </div>
      </div>
    </VanPopup>
    <LoginWrap :show="showAuth" @login="login" />
    <FeedbackEtrance v-if="!isCq" :scene-id="4" />
    <AgreementWrap
      ref="agreementWrap"
      @confirm="confirmAgreement"
      @checkAgreement="clickConcealGuard" />

    <!-- 蒙层，长轻落地页获取到用户信息之前不让点击页面 -->
    <div v-if="isCq && !userInfo?.id" class="prohibit-mask"></div>
  </div>
</template>

<script>
import Store from '@/store'
import RecentBuy from '../components/RecentBuy'
import HeaderImg from '../components/HeaderImg'
import CountDown from '../components/CountDown'
import FormPay from '../components/FormPay'
import DetailImg from '../components/DetailImg'
import BottomFixed from '../components/BottomFixed'
import GroupBuy from '../components/GroupBuy'
import DetentionDialog from '../components/DetentionDialog'
import FormPop from '../components/FormPop'
import ICPRecord from '../components/ICPRecord'
import ConcealGuardDialog from '../components/ConcealGuardDialog'
import { uploadImagePercent } from '@/utils/imgInViewUploadPoint'
import LoginWrap from '../components/LoginWrap.vue'
import { path } from '../config/path'
import FeedbackEtrance from '../components/Feedback/FeedbackEtrance.vue'
import AgreementWrap from '../components/AgreementWrap.vue'
import RedEnvelope from '../components/RedEnvelope.vue'
import FormPopNew from '../components/FormPopNew.vue'
import DetentionDialogAgain from '../components/DetentionDialogAgain.vue'
import GroupBuyNew from '../components/GroupBuyNew.vue'
import JumptoMiniapp from '../components/JumptoMiniapp.vue'
import EasyIm from '../components/EasyIm.vue'
import Address from '../components/Address.vue'

import {
  getSearch,
  vertifyPhoneCode,
  loadJs,
  evil,
  setDocumentTitle,
  auth,
  combineUrl,
  isWeiXin,
  obj2str,
  scrollView
} from '@/utils/index'
import { codeMap } from '@/views/config/WxErrorMsg'
import {
  manageQuestionUrl,
  SP_CONFIG,
  defaultPageId,
  CAISHANG_1YUAN
} from '@/config'
import { mapState } from 'vuex'
import { FENBAN_PRODUCT_SN, PATH_ID, NO_HEAD_PAGE_ID } from '@/constant'
import { DC } from '@/utils/device'

/* eslint-disable camelcase */
export default {
  components: {
    RecentBuy,
    HeaderImg,
    CountDown,
    FormPay,
    DetailImg,
    BottomFixed,
    GroupBuy,
    DetentionDialog,
    FormPop,
    ICPRecord,
    ConcealGuardDialog,
    LoginWrap,
    FeedbackEtrance,
    AgreementWrap,
    RedEnvelope,
    DetentionDialogAgain,
    FormPopNew,
    GroupBuyNew,
    JumptoMiniapp,
    EasyIm,
    Address
  },
  data () {
    return {
      pathId: null, // 路径id
      product_sn_key: null, // 课程映射id
      components: [], // 默认配置
      configData: {
        jsApi: [],
        component: [],
        bottom_fixed: {
          is_show: false
        },
        showConcealGuardDialog: false, // 隐私协议弹窗
        showDialog: false, // 挽留弹窗
        orderNum: '', // 订单号
        showPop: false // 表单pop
      }, // 当前落地页
      form: {
        phone: '', // 用户输入手机号
        testCode: '', // 验证码
        checked: false,
        area_code: ''
      },
      click: true,
      brandId: 9,
      coupon_id: '',
      center_coupon_id: '', // 商品中心优惠券
      centerCouponInfo: {}, // 商品中心优惠券详情
      showRedPocket: false, // 优惠券红包弹窗
      abResult: {},
      heartbeat: null, // 心跳上报
      product_price: null,
      showAuth: false, // 是否授权
      showAgreement: false,
      mediaId: 0, // 投放渠道id，二级
      addressInfo: null, // 用于保存图书投放的地址
      device_info: null, // 设备信息
      isSkipQuestion: false // 是否跳过问卷
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    calPaddingBottom () {
      return this.configData.bottom_fixed.is_show
        ? this.configData.bottom_fixed.bottom_bg.height / 75
        : 0
    },
    isCq () { // 是否是长轻的商品
      return [11, 19].includes(this.brandId)
    },
    // 是否走微信获客
    isWxHk () {
      const JW_PATH = [
        PATH_ID.JW_OUT_WX_1,
        PATH_ID.JW_IN_WX_1,
        PATH_ID.JW_OUT_WX_2
      ]
      return JW_PATH.includes(this.configData.path_id)
    }
  },
  async beforeRouteEnter (from, to, next) {
    if (localStorage.getItem('authing')) {
      await auth()
    }
    if (!localStorage.getItem('Authorization')) {
      next((v) => {
        v.showAuth = true
        Store.$log.show({
          current_page: location.href,
          module_ori: '弹窗展示',
          module_part: `106_tf_H5_GJ_001_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      })
    }
    next()
  },
  async created () {
    if (localStorage.getItem('Authorization')) { // 如果已经授权了
      if (!Store.state.user.wxConfig) { // 不存在微信授权信息，获取授权信息
        await Store.dispatch('user/getWxConfig')
      }
      if (!Store.state.user.userInfo) { // 不存用户信息，重新获取用户信息
        await Store.dispatch('user/getUserInfo')
      }
      await this.getData()
      this.initPage()
    }
    this.poinitPage() // 埋点

    const id = getSearch('id')
    if (NO_HEAD_PAGE_ID.includes(id)) {
      this.visible = false
    }

    setTimeout(() => {
      console.log('product_price', this.product_price)
      console.log('center_coupon_id', this.center_coupon_id)
    }, 1000)
    // 设备信息
    this.device_info = {
      model: DC.getBrandModel(),
      platform: DC.getOsName()
    }
  },
  beforeDestroy () {
    this.heartbeat.close()
  },
  methods: {
    // 授权
    async login () {
      await auth()
      this.showAuth = false
      this.initPage() // 初始化
    },
    async initPage () {
      await this.isPaySuccess({ init: true })
      this.initBaseCode()
      this.setShare()
      // 路径三清空本地手机号验证码
      this.clearLocal()
      // 获取投放渠道
      // this.$apis.landPage.getChannelId({ site_id: getSearch('site_id') }).then(res => {
      //   this.mediaId = res.media_id
      // })
      // 是否跳过问卷
      this.getIsSkip()
    },
    buryPointUrl (url, obj) {
      url = new URL(url)
      const params = JSON.parse(getSearch('alternate')) || {}
      obj = { ...params, ...obj }
      for (const key in obj) {
        if (key && obj[key]) url.searchParams.set(key, obj[key])
      }
      return url.toString()
    },
    // 跳转成功页面
    goSuccess (order_num) {
      try {
        if (!order_num) {
          this.$apis.landPage
            .getCheckBought({
              phone: this.pathId === 3 ? null : this.form.phone,
              src_site_id: 0,
              ...getSearch(),
              product_sn: this.product_sn_key
            })
            .then((res) => {
              this.goSuccessUrl()
            })
            .catch((e) => {
              if ([30009].includes(e.data.code)) {
                this.goSuccessUrl(e.data.data.order_num)
              } else {
                this.goSuccessUrl()
              }
            })
        } else {
          this.goSuccessUrl(order_num)
        }
      } catch (e) {
        this.goSuccessUrl()
      }
    },
    async changqingFenban (order_num) {
      try {
        if (this.product_sn_key) {
          // const fenbanParams = {
          //   product_sn: this.product_sn_key,
          //   phone: this.form.phone
          // }
          // await this.$apis.baiduPcPayOrder.getClassQr(fenbanParams)
        } else {
          const alterData = JSON.parse(localStorage.getItem('ab_result')) || {}
          const { host } = location
          const defaultId = defaultPageId[host] || 406

          const res = await this.$apis.landPage.getPageConfig({
            id: getSearch('id') || defaultId,
            ab_group: alterData.ab_group
          })
          this.product_sn_key = res.product_sn_key || FENBAN_PRODUCT_SN
          // this.$apis.baiduPcPayOrder.getClassQr({ product_sn: res.product_sn_key, phone: this.form.phone })
        }
      } catch (error) {
        console.log('分班失败', error)
      }

      // 直接加微，跳转逻辑处理，如无跳转路径则走正常逻辑
      if (this.isWxHk) {
        try {
          const { link_url: linkUrl = '', whats_app_code: whatsAppCode = '' } = await this.$apis.landPage.getHkLink({
            product_sn: this.product_sn_key,
            mid: this.userInfo.id,
            phone: this.form.phone,
            area_code: this.form.area_code
          })
          console.log(linkUrl, '=====获客链接=====')
          console.log(whatsAppCode, '=====whatsApp链接=====')
          let jumpLink = ''
          if (this.configData.path_id === PATH_ID.JW_OUT_WX_2) {
            jumpLink = whatsAppCode
          } else {
            jumpLink = linkUrl
          }
          if (jumpLink) {
            const alternate = {
              ...(JSON.parse(getSearch('alternate')) || {}),
              hklink: jumpLink
            }
            this.$log.show({
              current_page: location.href,
              module_ori: '跳转老师企微信息页面',
              module_part: `106_luodiyehoutai_lcl_0032_${getSearch('id')}`,
              alternate
            })
            window.location.href = jumpLink
            return
          }
        } catch (error) {
          console.log(error)
        }
      }

      // 需要根据渠道判断是否需要跳过问卷
      if (this.isSkipQuestion) {
        // location.href = targetUrl
        // 去老师二维码页面
        this.$router.push({
          name: 'ChangqingCode',
          query: {
            ...this.$route.query,
            appid: 'changqing-shoushen',
            phone: this.form.phone,
            area_code: this.form.area_code,
            order_num,
            product_sn: this.abResult.product_sn,
            productSn: this.product_sn_key
          }
        })
      } else {
        // 去长轻问卷页面
        this.$router.push({
          name: 'Questionnaire',
          query: {
            ...this.$route.query,
            appid: 'changqing-shoushen',
            phone: this.form.phone,
            area_code: this.form.area_code,
            order_num,
            product_sn: this.abResult.product_sn,
            productSn: this.product_sn_key
          }
        })
      }
    },
    // 跳转成功页面
    async goSuccessUrl (order_num) {
      const data = await this.$apis.landPage.getOneByOrderNumWx({ order_num })
      const isGroup = [20, 22].includes(Number(data.resource_type)) // 是否是加群
      // 长轻分班
      if ([11, 19].includes(this.brandId)) {
        // 长轻 分班
        await this.changqingFenban(order_num)
        return
      }
      // 管理课
      if ([16, 21, 27, 28, 31].includes(this.brandId)) {
        let targetUrl = new URL(
          location.origin +
            location.pathname +
            location.search +
            `#/wxLandPage/managePaySuccess?product_sn=${this.product_sn_key}`
        )
        targetUrl = this.buryPointUrl(targetUrl, { order_num })
        const url = this.buryPointUrl(
          combineUrl(manageQuestionUrl, targetUrl),
          { order_num }
        )
        location.href = url
        return
      }
      // 善育儿
      if ([18, 20, 25].includes(this.brandId)) {
        this.$router.push({
          name: 'YuerWxSuccess',
          query: {
            product_sn: this.product_sn_key,
            ...this.$route.query,
            order_num
          }
        })
        return
      }
      // 每职
      if ([23, 24].includes(this.brandId)) {
        let targetUrl = new URL(
          location.origin +
            location.pathname +
            `#/wxLandPage/MeiZhiWxPaySuccess?product_sn=${this.product_sn_key}`
        )
        targetUrl = this.buryPointUrl(targetUrl, { order_num })
        const url = this.buryPointUrl(
          combineUrl(manageQuestionUrl, targetUrl),
          { order_num }
        )
        location.href = url
        return
      }
      // 财商课
      if ([9, 12].includes(this.brandId)) {
        // 财商0元ab测试【加微or加群】

        const { price } = await this.$apis.landPage.getLatestPaidOrder()
        if (price === 0 || price === 1) {
          const nameSuccess = isGroup ? 'WxPaySuccessGroup' : 'WxPaySuccess'
          this.$router.push({
            name: nameSuccess,
            query: {
              product_sn: this.product_sn_key,
              ...this.$route.query,
              order_num
            }
          })
          return
        }
        const pathSuccess = 'Question'
        // let targetUrl = new URL(
        //   location.origin +
        //     location.pathname +
        //     `#/wxLandPage/${pathSuccess}?product_sn=${this.product_sn_key}`
        // )
        let targetUrl = new URL(
          location.origin +
            location.pathname +
            'customer/?pageid=48&phone=' + this.form.phone +
            `#/${pathSuccess}?product_sn=${this.product_sn_key}`
        )
        targetUrl = this.buryPointUrl(targetUrl, { order_num })
        // const url = this.buryPointUrl(
        //   combineUrl(caishangQuestionUrl, targetUrl),
        //   { order_num }
        // )
        console.log(targetUrl, '==url')
        location.href = targetUrl
        return
      }
      let nameSuccess = 'WxPaySuccess'
      if (this.product_price === 0) {
        nameSuccess = isGroup ? 'WxPaySuccessGroup' : 'WxPaySuccess'
      }
      // 其他走默
      this.$router.push({
        name: nameSuccess,
        query: {
          product_sn: this.product_sn_key,
          ...this.$route.query,
          order_num
        }
      })
    },
    // 加载jsapi基础代码
    initBaseCode () {
      const apiList = this.configData.jsApi
      apiList.forEach((item) => {
        loadJs(item.baseCode)
      })
    },
    // 执行转换代码
    runConvertCode () {
      const apiList = this.configData.jsApi
      apiList.forEach((item) => {
        evil(item.convertCode)
      })
    },
    // 路径三清空本地手机号验证码
    clearLocal () {
      if (this.pathId === 3) {
        localStorage.removeItem('phone')
        localStorage.removeItem('testCode')
      }
    },
    handleSubmit () {
      if (!this.click) return
      // 组件内有FormPop 并且 showPop当前为false  并且没有设置有手机号自动隐藏
      if (
        this.components.filter(
          (item) => item.component_name === 'FormPop'
        )[0] &&
        !this.configData.showPop &&
        !this.configData.form_pay.is_hidden_phone &&
        this.configData.form_pay.is_show
      ) {
        // 路径4的表单是pop出表单的形式
        this.configData.showPop = true
      } else {
        const NEED_ADDREE_PATH = [
          PATH_ID.OUT_WX_4,
          PATH_ID.IN_WX_3
        ]
        // 新增逻辑 如果是实物订单打开收货地址
        if (NEED_ADDREE_PATH.includes(this.configData.path_id)) {
          this.addressPathSubmit()
        } else {
          this.submit()
        }
      }
    },
    // 之前是否支付过
    isPaySuccess ({ init }) {
      // 判断是否报名成功,没有报名弹出挽留弹窗,3直接购买，4手机号购买
      this.$apis.landPage
        .getCheckBought({
          phone: this.pathId === 3 ? null : this.form.phone,
          src_site_id: 0,
          ...getSearch(),
          product_sn: this.product_sn_key
        })
        .then(async () => {
          // 没有报名弹出挽留弹窗(首次)
          if (!init) {
            const params = this.pathId === 3 ? {
              mid: this.userInfo.id
            } : {
              phone: this.form.phone,
              area_code: this.form.area_code || localStorage.getItem('area_code')
            }
            const { order_num: orderNum } = await this.$apis.landPage.getPreNoPayOrder(params)
            console.log('订单号', orderNum)
            this.configData.orderNum = orderNum
            this.configData.showDialog = true
          }
          if (this.center_coupon_id && init) {
            this.showRedPocket = true
            setTimeout(() => {
              this.showRedPocket = false
            }, 2.2 * 1000)
            // setTimeout(() => {
            //   this.configData.bottom_fixed.bottom_bg.input_text.text = `现价${this.centerCouponInfo.product_price - this.centerCouponInfo.rule.discount}元`
            // }, 3 * 1000)
          }
          if (init) {
            // 未购买，微信内路径四（直接加微）& 无需填写表单 & 商品价位为0元的 直接加微
            this.proactivelySubmit()
          }
        })
        .catch((e) => {
          this.handleError(e.data)
        })
    },
    proactivelySubmit () {
      // 微信内路径四（直接加微信）& 无需表单 & 商品价位为0元的 直接加微
      if (this.pathId === PATH_ID.JW_IN_WX_1 && this.product_price === 0 && this.configData.form_pay.is_show === false) {
        this.$toast.loading({
          duration: 3000,
          forbidClick: true,
          message: '加载中...'
        })
        this.handleSubmit()
      }
    },
    addressPathSubmit () {
      // 先校验验证码
      const dom = this.$refs?.FormPay?.[0].$refs
      if (!vertifyPhoneCode({ form: this.form, dom, that: this })) return

      console.log('this.$refs.Address', this.$refs.Address)
      this.$refs.Address[0].visible = true
      this.$refs.Address[0].address_info.mobile = this.form.phone
      this.$log.show({
        current_page: location.href,
        module_ori: '落地页-收货地址页展示',
        module_part: `106_luodiyehoutai_lcl_0030_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
    },
    async submit () {
      const fromName = this.$refs.FormPay || this.$refs.FormPop
      let cityCodePreg = null
      if (fromName) {
        fromName[0].validateForm()
        fromName[0].noValidateCode(true)
        cityCodePreg = fromName[0].cityCodePreg
        this.form.area_code = fromName[0].cityCode
        localStorage.setItem('area_code', this.form.area_code)
      }
      if (
        !this.configData.form_pay.is_hidden_phone &&
        this.configData.form_pay.is_show
      ) {
        const dom = this.$refs?.FormPay?.[0].$refs
        if (
          this.configData.form_pay.conceal_guard?.is_show &&
          this.configData.form_pay.conceal_guard?.needSelect &&
          !this.form.checked
        ) {
          this.$refs.agreementWrap.toggleShow()
          return
        }
        // 设置本地存储
        if (this.pathId === 4 || this.configData.form_pay.is_show) {
          if (!vertifyPhoneCode({ form: this.form, dom, that: this, cityCodePreg })) return
        }
      }
      this.$log.click({
        current_page: location.href,
        module_ori: '表单提交模块',
        module_part: `106_tf_h5_00037_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      await this.pay()
    },
    async pay () {
      this.click = false
      const purchaseLimit = this.configData.purchase_limit
      const limitBuy = purchaseLimit ? purchaseLimit.limit_buy : ''

      const payParams = {
        pay_mode: 3,
        phone: this.form.phone,
        area_code: this.form.area_code,
        code: this.form.testCode ? this.form.testCode : null,
        path_id: this.pathId,
        product_sn: this.product_sn_key,
        coupon_id: this.coupon_id,
        center_coupon_id: this.center_coupon_id,
        limit_buy: limitBuy || '', // 购买限制
        ...this.device_info,
        ...getSearch(),
        ext_param: [
          {
            domain: 'landing_page',
            abKey: this.abResult.ab_key,
            group: this.abResult.ab_group,
            uniqFlag: this.abResult.uniq_user
          }
        ]
      }

      wx.miniProgram.getEnv((res) => {
        // 小程序环境，去小程序支付
        if (res.miniprogram) {
          this.click = true
          wx.miniProgram.postMessage({ data: payParams })
          wx.miniProgram.redirectTo({
            url: '/pages/pay/index'
          })
        } else {
          // wx支付
          this.$apis.landPage
            .postPay({
              pay_mode: 3,
              phone: this.form.phone,
              area_code: this.form.area_code,
              code: this.form.testCode ? this.form.testCode : null,
              path_id: this.pathId,
              product_sn: this.product_sn_key,
              coupon_id: this.coupon_id,
              center_coupon_id: this.center_coupon_id,
              limit_buy: limitBuy || '', // 购买限制
              src_site_id: 0,
              ...this.device_info,
              ...getSearch(),
              ext_param: [
                {
                  domain: 'landing_page',
                  abKey: this.abResult.ab_key,
                  group: this.abResult.ab_group,
                  uniqFlag: this.abResult.uniq_user
                }
              ],
              address_info: {
                ...this.addressInfo
              }

            })
            .then((data) => {
              this.click = true
              // 创业家不跳转成功页面
              if ((this.brandId === 30 || this.brandId === 32) && data.order_num) {
                this.$toast({
                  message: '支付成功'
                })
                return false
              }
              if (data.order_num) return this.goSuccess(data.order_num)
              if (typeof WeixinJSBridge === 'undefined') {
                if (document.addEventListener) {
                  document.addEventListener(
                    'WeixinJSBridgeReady',
                    this.onBridgeReady,
                    false
                  )
                } else if (document.attachEvent) {
                  document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
                  document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
                }
              } else {
                // pop弹窗隐藏
                this.configData.showPop = false
                this.onBridgeReady(data)
              }
            })
            .catch((e) => {
              this.click = true
              this.handleError(e.data)
            })
        }
      })
    },
    // 获取数据
    async getData () {
      const alterData = JSON.parse(localStorage.getItem('ab_result')) || {}
      // 如果没有id则根据域名显示默认落地页
      const { host } = location
      const defaultId = defaultPageId[host] || 406
      if (!getSearch('id') && defaultId === 758) {
        setDocumentTitle('课程已被抢光了～')
        this.$toast({
          message: '课程已被抢光了～'
        })
        return false
      }
      const {
        data = {},
        path_id,
        product_sn,
        product_sn_key,
        ab_result = {},
        brandTitle,
        brandId,
        coupon_id,
        center_coupon_id,
        center_coupon_info,
        product_price,
        goods_price,
        goods_original_price
      } = await this.$apis.landPage.getPageConfig({
        id: getSearch('id') || defaultId,
        ab_group: alterData.ab_group
      })
      this.pathId = path_id
      // 商品编码
      this.product_sn_key = product_sn_key
      // 品牌id
      this.brandId = brandId
      // 优惠券编号
      this.coupon_id = coupon_id
      // 商品中心优惠券
      this.center_coupon_id = center_coupon_id
      // 优惠券详情
      this.centerCouponInfo = center_coupon_info
      this.centerCouponInfo.product_price = product_price
      this.product_price = product_price
      setDocumentTitle(brandTitle)
      Object.assign(this.configData, data, { product_sn_key, goods_price, goods_original_price })
      // 手机号隐藏（再次判断是否有手机号，没有：需要打开，有：不处理）
      if (this.configData.form_pay.is_hidden_phone && !this.userInfo.mobile) {
        this.configData.form_pay.is_hidden_phone = false
      }
      const searchData = JSON.parse(getSearch('alternate'))
      this.$log.update({
        alternate: JSON.stringify({
          ...searchData,
          is_hidden_phone: this.configData.form_pay.is_hidden_phone
        })
      })
      ab_result.product_sn = product_sn
      localStorage.setItem('ab_result', JSON.stringify(ab_result))
      this.abResult = ab_result
      this.components = path[this.pathId - 1].components.concat(data.component)
      this.$nextTick(() => {
        uploadImagePercent.call(this, '.wxLandpage', '.landpage-bottom')
      })
    },
    // 已经阅读协议
    confirmAgreement () {
      scrollView(this, '.landpage-formpay')
      this.form.checked = true
      this.handleSubmit()
    },
    // 查看协议
    clickConcealGuard () {
      this.$log.show({
        module_ori: '落地页首页-隐私协议弹窗',
        module_part: `106_tf_ldyyinsi_00001_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.configData.showConcealGuardDialog = true
    },
    // 协议内容已阅读
    concealGuardConfirm () {
      this.configData.showConcealGuardDialog = false
      this.confirmAgreement()
    },
    onBridgeReady (data) {
      WeixinJSBridge.invoke('getBrandWCPayRequest', data, (res) => {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          // 创业家不跳转成功页面
          if (this.brandId === 30 || this.brandId === 32) {
            this.$toast({
              message: '支付成功'
            })
            return false
          }
          this.goSuccess()
          // 执行jsapi的转换代码
          this.runConvertCode()
        }
        if (res.err_msg === 'get_brand_wcpay_request:cancel') {
          // 支付过程中用户取消----判断是否报名成功
          this.form.checked = true
          this.isPaySuccess({ init: false })
        }
      })
    },
    // 设置分享
    setShare () {
      // 财商品牌且优惠后价格为1或者0元,设置专有分享信息
      if (
        this.centerCouponInfo.rule &&
        this.centerCouponInfo.rule.discount >= 0 &&
         [9, 12, 13, 14].includes(this.brandId)
      ) {
        const discountPrice = this.centerCouponInfo.product_price -
          this.centerCouponInfo.rule.discount
        if ([0, 1].includes(discountPrice)) {
          this.$wx.setShareData({ ...CAISHANG_1YUAN.share, link: location.href })
          return
        }
      }
      // if (
      //   this.centerCouponInfo.rule &&
      //   this.centerCouponInfo.rule.discount >= 0 &&
      //   this.centerCouponInfo.product_price -
      //     this.centerCouponInfo.rule.discount ===
      //     1 &&
      //   [9, 12, 13, 14].includes(this.brandId)
      // ) {
      //   this.$wx.setShareData({ ...CAISHANG_1YUAN.share, link: location.href })
      //   return
      // }
      this.$wx.setShareData({
        ...SP_CONFIG?.[this.brandId]?.share,
        link: location.href
      })
    },
    handleError ({ code, data, msg }) {
      let confirm = {
        message: msg,
        confirmButtonText: '确定'
      }
      // 路径三不校验验证码错误
      if (!codeMap[code]) return
      if ([30009].includes(code)) {
        confirm.confirmButtonText = '查看老师'
      }
      let timeout = null
      // 微信内，满足复训条件用户和复训班级结算要求，但不在复训活动期内的用户
      if (isWeiXin() && [30023].includes(code)) {
        const { id } = getSearch()
        this.$log.show({
          current_page: location.href,
          module_ori: '落地页-重复购买弹窗',
          module_part: `106_tf_H5_00238_${id}`
        })
        confirm = {
          message: '您已经报名过啦！无需再次支付！\n现在为您直接跳过付费流程...',
          confirmButtonText: '【免费学习】'
        }
        timeout = setTimeout(() => {
          this.goSecondCallSuccess()
        }, 3000)
      }
      this.$dialog(confirm).then((res) => {
        codeMap[code].report &&
          this.$log.click({
            ...codeMap[code].report,
            alternate: getSearch('alternate'),
            module_part: `${codeMap[code].report.module_part}_${getSearch(
              'id'
            )}`
          })
        if ([30009].includes(code)) {
          this.goSuccess(data.order_num)
        }
        if (isWeiXin() && [30023].includes(code)) {
          timeout && clearTimeout(timeout)
          this.goSecondCallSuccess()
        }
        if (code === 33002) {
          this.$log.click({
            current_page: location.href,
            module_ori: '手机号、验证码表单',
            module_part: `106_tf_xinzeng_0006_${getSearch('id')}`,
            alternate: getSearch('alternate')
          })
        }
        if (code === 33002 && this.configData.form_pay.is_show) {
          this.$refs.FormPay[0].$refs.testCode.focus()
        }
      })
    },
    // 去复训报名页
    async goSecondCallSuccess () {
      const { id, wm_click_id: wmClickId } = getSearch()
      this.$log.click({
        current_page: location.href,
        module_ori: '落地页-重复购买弹窗',
        module_part: `106_tf_H5_00239_${id}`
      })
      const evn = process.env.VUE_ENV === 'dev' ? 'test-' : ''
      const PARAMS_ENV = {
        dev: {
          message_id: 1000726,
          activity_id: 1000725,
          site_id: 2179086,
          wm_click_id: wmClickId,
          id
        },
        prod: {
          message_id: 1003933,
          activity_id: 1003932,
          site_id: 5425426,
          wm_click_id: wmClickId,
          id
        }
      }
      const params = PARAMS_ENV[process.env.VUE_ENV]
      let routeName = 'second-result-toufang'
      try {
        await this.$apis.landPage.postPay({
          ...params,
          src_site_id: getSearch().site_id || 0,
          product_sn: 'PRODUCT_SN_XIAOBAI_0_LIVE'
        })
      } catch (error) {
        const { msg, code } = error.data
        if (![39100, 39102].includes(code)) {
          this.$toast({
            message: msg
          })
          return
        }
        // 去用服报名成功页
        routeName = 'second-result-yongfu'
      }
      window.location.href = `${location.protocol}//${evn}active.weimiaoxuetang.cn/#/${routeName}${obj2str(params)}`
    },
    // 埋点
    poinitPage () {
      const alternate = {
        ...(JSON.parse(getSearch('alternate')) || {}),
        ua: navigator.userAgent
      }
      this.$log.show({
        current_page: location.href,
        module_ori: '基于页面',
        module_part: `${this.$route.meta.module_part}_${getSearch('id')}`,
        alternate: alternate
      })
      this.heartbeat = this.$log.heartbeat(
        {
          current_page: location.href,
          module_ori: '基于页面',
          module_part: `106_tf_h5_00035_${getSearch('id')}`,
          alternate: alternate
        },
        5000,
        0.05
      )
      this.heartbeat.start()

      this.$log.scroll({
        current_page: location.href,
        module_ori: '基于页面',
        module_part: `106_tf_h5_00036_${getSearch('id')}`,
        wrapper: window,
        delay: 300,
        alternate: alternate
      })
    },
    // 保存弹窗返回的地址信息。用于图书投放支付
    handleAddressInfo (e) {
      this.$log.click({
        current_page: location.href,
        module_ori: '落地页-收货地址页-【立即支付】按钮',
        module_part: `106_luodiyehoutai_lcl_0031_${getSearch('id')}`,
        alternate: {
          ...(JSON.parse(getSearch('alternate')) || {}),
          address_info: e
        }
      })
      // 保存地址
      this.addressInfo = e
      // 关闭组建弹窗
      this.$refs.Address[0].visible = false
      this.submit()
    },
    async getIsSkip () {
      try {
        const res = await this.$apis.landPage.getIsSkip({ site_id: getSearch('site_id') })
        this.isSkipQuestion = res.is_skip_question
      } catch (error) {
        this.isSkipQuestion = false
        console.log(error)
      }
      console.log('isSkipQuestion', this.isSkipQuestion)
    }
  }
}
</script>

<style lang="scss" scoped>
.wxLandpage {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.prohibit-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: transparent;
}

.red-content {
  position: relative;
  transform: scale(1);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__title {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 20%;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #9c551d;
  }
  &__price {
    position: absolute;
    z-index: 4;
    // top: 250px;
    left: 0;
    right: 0;
    font-size: 120px;
    line-height: 120px;
    font-family: SanFrancisco;
    text-align: center;
    margin: auto;
    margin-top: 36%;
    display: flex;
    font-weight: 700;
    align-items: flex-end;
    justify-content: center;
    color: #ea3e43;
  }
  &__yuan {
    z-index: 3;
    width: 50px;
    height: 47px;
    margin-bottom: 10px;
    background-image: url("https://wm-front-common.oss-cn-beijing.aliyuncs.com/wm-front-landing-page-mobile/yuan.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.redPocket {
  background: transparent;
  overflow: hidden;
  width: 750px;
  height: 742px;
  top: 50%;
  left: 50%;
}
.animateRedPocket {
  animation: redPocket 1.2s ease-in 1s 1 forwards;
}
.animateRedContent {
  animation: redContent 1.2s ease-in 1s 1 forwards;
}
@keyframes redPocket {
  0% {
    top: 50%;
    left: 50%;
  }
  100% {
    top: 120%;
    left: 50%;
  }
}
@keyframes redContent {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
